import { Component, OnInit } from '@angular/core';
import { DataService } from '../../api/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-business-statement',
  templateUrl: './business-statement.component.html',
  styleUrls: ['./business-statement.component.css'],
  providers: [DatePipe]
})
export class BusinessStatementComponent implements OnInit {
  
  token: any;
  submitted = false;
  id: any;
  dataArray: any;
  users: any
  credentialsForm: FormGroup;
  myDate: any;
  fileName= 'statement.xlsx';  

  constructor(private formBuilder: FormBuilder, private _data: DataService, private _router: Router, private datePipe: DatePipe) { 
    _data.authToken$.subscribe(data => {
      this.token = data;
      if(this.token == null) {
        this._router.navigate(['/login']);
      }      
    });
    
  }

  ngOnInit(): void {
    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.credentialsForm = this.formBuilder.group({
      date_from: [this.myDate, Validators.required],
      date_to: [this.myDate, Validators.required]
    })
    this.getData()
  }
  get f() { return this.credentialsForm.controls; }
  
  getData() {
    if (this.credentialsForm.invalid) {
      this._data.showSuccess('error', 'Date fields required');
      return;
    }
    this.submitted = true;
    var param = '?date_from='+this.credentialsForm.value.date_from+'&date_to='+this.credentialsForm.value.date_to;
    this._data.fetchPaymentStatement(this.token, this.credentialsForm.value)
    .subscribe(  
        result => {
          //console.log(result); 
          this.submitted = false; 

          if(result) {
            this.dataArray = result;
            // console.log(this.dataArray)
          }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }


  onResetSearch() {
    this.credentialsForm.get('date_from').setValue(this.myDate);
    this.credentialsForm.get('date_to').setValue(this.myDate);
    this.credentialsForm.reset();
    this.getData()
  }
  
  exportexcel(): void 
  {
      /* table id is passed over here */   
      let element = document.getElementById('excel-table'); 
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */
      XLSX.writeFile(wb, this.fileName);
    
  }

}

