import { Component, OnInit } from '@angular/core';
import { DataService } from '../../api/data.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DatePipe } from '@angular/common';
declare var $ : any;
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-perticular-invoice-print',
  templateUrl: './perticular-invoice-print.component.html',
  styleUrls: ['./perticular-invoice-print.component.css'],
  providers: [DatePipe]
})
export class PerticularInvoicePrintComponent implements OnInit {

  token: any;
  submitted = false;
  myDate: any;
  id: any;
  customers = [];
  suggestionCustomers = [];
  printedCustromerName: string;
  buttonShow: boolean = true
  _reteEditVar:boolean = false;
  showSuggestionDiv: boolean = false;
  fetchedInvoice: any;
  lastDue: any
  _grossTotal: any;
  previousUrl: string = null;
  currentUrl: string = null;

  constructor( private _data: DataService, private _router: Router, private _actrouter: ActivatedRoute, private datePipe: DatePipe) { 
    _data.authToken$.subscribe(data => {
      this.token = data;
      if(this.token == null) {
        this._router.navigate(['/login']);
      }      
    });
    
    this._actrouter.paramMap.subscribe(params => {
      this.id = params.get('id');
      // console.log(this.slug);
      if( this.id ) {                
        this.getInvoice(this.id);
      }
      else {
        this._router.navigate(['/all-invoice']);
      } 
    });

    this._router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
    });
    
  }

  ngOnInit(): void {
    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    
  }

  getInvoice(id) {
    this.submitted = true;
    this._data.fetchSingleInvoice(this.token, id)
    .subscribe(  
        result => {
          console.log(result); 
          this.submitted = false;

          if(result) {
              var data: any = result
              //console.log(data);
              // if(data['edit'] == 0) {
              //   this.buttonShow = false
              // }
              this.fetchedInvoice = data;
              this.printedCustromerName = data.customer.customer_name;
              this.calculateTotal()
           }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }

  
  calculateTotal() {
    
    var grosstotal = 0;
    if(this.fetchedInvoice.field_normal_amount > 0) {
      grosstotal +=  (this.fetchedInvoice.field_normal_amount * 1)
    }
    if(this.fetchedInvoice.field_wax_amount > 0) {
      grosstotal +=  (this.fetchedInvoice.field_wax_amount * 1)
    }
    if(this.fetchedInvoice.field_dc_amount > 0) {
      grosstotal +=  (this.fetchedInvoice.field_dc_amount * 1)
    }
    if(this.fetchedInvoice.field_yellow_amount > 0) {
      grosstotal +=  (this.fetchedInvoice.field_yellow_amount * 1)
    }
    
    // var total = (this.fetchedInvoice.current_balance * 1) - grosstotal; 
    var total = grosstotal + (this.fetchedInvoice.current_balance * 1);   
        
    if(this.fetchedInvoice.shipping_charge > 0) {
      // total -= this.fetchedInvoice.shipping_charge * 1
      total += this.fetchedInvoice.shipping_charge * 1
    }
    this._grossTotal = Math.round(total);
    
    if(this.fetchedInvoice.discount > 0) {
      total -= (this.fetchedInvoice.discount * 1)
    }
    

    var a = total
    if(this.fetchedInvoice.paid_amount > 0 || this.fetchedInvoice.paid_amount < 0 || this.fetchedInvoice.paid_amount == 0) {       
      a = (total * 1) - (this.fetchedInvoice.paid_amount * 1);      
    } 
    a = Math.round(a);   
    this.lastDue = a;

    // var _pa = this.fetchedInvoice.payable_amount > 0 ? this.fetchedInvoice.payable_amount : 0;
    // var _da = this.fetchedInvoice.discount > 0 ? this.fetchedInvoice.discount : 0;
    // var payable_amount_without_discount =  _pa - _da;
    // this._grossTotal = Math.round(payable_amount_without_discount)
  
  }

  ngAfterViewInit() {
    // var printContents = document.getElementById("prnt_area").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = originalContents;
    window.print();
    this._router.navigate(['/create-invoice']);
  }

}
