import { Component, OnInit } from '@angular/core';
import { DataService } from '../../api/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  token: any;
  credentialsForm: FormGroup;
  submitted = false;
  customers = [];
  showDateRange = false
  showSuggestionDiv: boolean = false;
  suggestionCustomers = [];

  constructor(private formBuilder: FormBuilder, private _data: DataService, private _router: Router, private _actrouter: ActivatedRoute ) { 
    _data.authToken$.subscribe(data => {
      this.token = data;
      if(this.token == null) {
        this._router.navigate(['/login']);
      }   
      else {
        this.fetchCustomer();
      }    
    });
  }

  ngOnInit(): void {
    this.credentialsForm = this.formBuilder.group({
      customer_id: ['', Validators.required],
      date_from: [''],
      date_to: [''],
      statement_type: ['', Validators.required],
      mobile: [''],
      address: [''],
      current_balance: [''],
      email: [''],
      c_name: ['']
    });
  }

  get f() { return this.credentialsForm.controls; }
  
  fetchCustomer() {
    this.submitted = true;
    
    this._data.fetchCustomerWithInvoice(this.token)
    .subscribe(  
        result => {
          this.submitted = false;
          //console.log(result); 
          if(result) {
            if('status' in result && result['status']){ 
              if(result['status'] == "success") {
                this.customers = result['result'];
                //console.log(this.customers)
                // this._data.showSuccess('success', result['msg']);
              }
              if(result['status'] == "fail") {
                this._data.showSuccess('warning', result['msg']);
              }
            }
          }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }

  onChange(deviceValue) {
    this.showSuggestionDiv = false; 
    var _e = '';
    var _m = '';
    var _a = '';
    var c_invoiceNo = "";
    var _due = 0
    if(deviceValue) {
      var d = this.customers.find(x => x.id == deviceValue);
      if(d) {
        // console.log(deviceValue);
        _e = d['email']
        _m = d['mobile']
        _a = d['address']
        _due = d['current_balance']
        // this._data.printableData.customer = d
        this._data.setPrintDataCustomer(d)
        this.credentialsForm.get('c_name').setValue(d['customer_name']);
        this.credentialsForm.get('customer_id').setValue(deviceValue);
      }
      this.credentialsForm.get('email').setValue(_e);
      this.credentialsForm.get('mobile').setValue(_m);
      this.credentialsForm.get('address').setValue(_a);
      this.credentialsForm.get('current_balance').setValue(_due);
    }    
    
  }

  checkDt(deviceValue) {
    this.showDateRange = false
    if(deviceValue == 'date-wise') {
      this.showDateRange = true
    }
  }

  onSubmit() {
    this.submitted = true;
    // console.log(this.credentialsForm.value);
    // return;
    // stop here if form is invalid
    if (this.credentialsForm.invalid) {
        return;
    }

    if(this.credentialsForm.value.statement_type == 'all') {
      this._data.setPrintDataHeading('All Invoices')
    }
    if(this.credentialsForm.value.statement_type == 'date-wise') {
      this._data.setPrintDataHeading('All Invoices from '+this.credentialsForm.value.date_from+' to '+this.credentialsForm.value.date_to)
    }

    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.loginForm.value, null, 4));
    this._data.searchStatement(this.credentialsForm.value, this.token)
    .subscribe(  
        result => {
          console.log(result); 
          this.submitted = false;
          if(result) {
            if('status' in result && result['status']){ 
              if(result['status'] == "success") {
                var a: any = result['data']
                if(a.length) {
                  // this._data.printableData.invoice = a
                  this._data.setPrintData(a)

                  //window.open('https://al3dprinterbill.in/print-report', '_blank');


                  window.open('https://rr3dprinterbill.co.in/print-report', '_blank');

                  // this._router.navigate(['/print-report']);
                }
                else {
                  this._data.showSuccess('warning', 'No invoice found.');
                }
              }
              if(result['status'] == "fail") {
                this._data.showSuccess('warning', result['msg']);
              }
            }
          }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }
  
  nameSuggestion(x) {
    this.suggestionCustomers = [];
    this.credentialsForm.get('customer_id').setValue('');
    this.showSuggestionDiv = true;
    if(x && x.length > 0) {
      //console.log(this.customers)
      var searchString = x.toLowerCase();
      this.suggestionCustomers = this.customers.filter(function(itm){
        return itm.customer_name.toLowerCase().indexOf(searchString) !== -1 || itm.mobile.indexOf(searchString) !== -1 || itm.id == searchString
      });
    }
  }
  

  onMailSend() {
    this.submitted = true;
    // console.log(this.credentialsForm.value);
    // return;
    // stop here if form is invalid
    if (this.credentialsForm.invalid) {
        return;
    }

    if(this.credentialsForm.value.statement_type == 'all') {
      this._data.setPrintDataHeading('All Invoices')
    }
    if(this.credentialsForm.value.statement_type == 'date-wise') {
      this._data.setPrintDataHeading('All Invoices from '+this.credentialsForm.value.date_from+' to '+this.credentialsForm.value.date_to)
    }

    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.loginForm.value, null, 4));
    this._data.sendMail(this.credentialsForm.value, this.token)
    .subscribe(  
        result => {
          console.log(result); 
          this.submitted = false;
          // if(result) {
          //   if('status' in result && result['status']){ 
          //     if(result['status'] == "success") {
          //       var a: any = result['data']
          //       if(a.length) {
          //         // this._data.printableData.invoice = a
          //         this._data.setPrintData(a)
          //         window.open('https://www.eresource.in/ai3d/print-report', '_blank');
          //         // this._router.navigate(['/print-report']);
          //       }
          //       else {
          //         this._data.showSuccess('warning', 'No invoice found.');
          //       }
          //     }
          //     if(result['status'] == "fail") {
          //       this._data.showSuccess('warning', result['msg']);
          //     }
          //   }
          // }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }

  onReset() {
    this.credentialsForm.get('customer_id').setValue('');
    this.credentialsForm.get('date_from').setValue('');
    this.credentialsForm.get('date_to').setValue('');
    this.credentialsForm.get('statement_type').setValue('');
    this.credentialsForm.get('mobile').setValue('');
    this.credentialsForm.get('address').setValue('');
    this.credentialsForm.get('current_balance').setValue('');
    this.credentialsForm.get('email').setValue('');
    this.credentialsForm.get('c_name').setValue('');
    this.showDateRange = false
    this.credentialsForm.reset();
  }

}
