
<div class="wrapper">
    <div class="container-fluid">

        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12">
                <div class="btn-group pull-right m-t-20">
                    <button class="btn btn-success waves-effect waves-light pull-right" data-toggle="modal" data-target="#exampleModal">
                        Import to excel
                    </button>
                    <a class="btn btn-custom" [routerLink]="['/create-customer']">Add Customer </a>              
                </div>
                <h4 class="page-title">Customer List ({{visibleCustomers ? visibleCustomers.length : 0}})</h4>
            </div>
        </div>
        <!-- end page title end breadcrumb -->

        <div class="row">
            <div class="col-sm-12">
                <div class="card-box"> 
                    <form [formGroup]="credentialsForm" *ngIf="!showSearch">
                        <div class="row">                        
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label class="control-label">Search By Customer*</label>
                                    <div class="input-group bootstrap-touchspin">
                                        <input class="vertical-spin form-control" type="text" value="" formControlName="customer_name" style="display: block;" >
                                        <span class="input-group-btn-vertical">
                                            <button class="btn btn-primary bootstrap-touchspin-up" type="button" (click)="onSearch()">Search</button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <form [formGroup]="credentialsForm" *ngIf="showSearch">
                        <div class="row">
                        
                            <div class="col-xl-3">
                                <div class="form-group">
                                    <label >Search By Customer*</label>

                                    <div class="input-group">
                                        <input type="text" placeholder="Customer Name / customer id / customer mobile" class="form-control" formControlName="customer_name" [ngClass]="{ 'is-invalid': submitted && f.customer_name.errors }" (keyup)="checkCustomer()">
                                        <!-- <div class="input-group-append">
                                            <button class="btn btn-primary waves-effect waves-light" (click)="onSearch()">
                                                Submit
                                            </button>
                                            <button class="btn btn-warning waves-effect waves-light" (click)="onResetSearch()">
                                                Reset
                                            </button>
                                        </div>
                                        <div *ngIf="submitted && f.customer_name.errors" class="invalid-feedback">
                                            <div *ngIf="f.customer_name.errors.required">Customer Name is required</div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-xl-3">
                                <div class="form-group">
                                    <label >Search By Due Amount</label>
                                    <input type="text" placeholder="Amount" class="form-control" formControlName="due_amount" (keyup)="checkCustomerDue()">
                                </div>
                                
                            </div> -->
                            <!-- <div class="col-xl-3">
                                <div class="form-group">
                                    <label >Search By Advance Amount (Upto)</label>
                                    <input type="text" placeholder="Amount" class="form-control" formControlName="advance_amount" (keyup)="checkCustomerAdvance()">
                                </div>
                                
                            </div> -->
                            <div class="col-xl-3">
                                <label >Search By last paid date with due</label>
                                <div class="input-group">
                                    <input type="text" placeholder="Due" class="form-control" formControlName="amount_due" (keyup)="checkCustomerDateDue()">
                                    <div class="input-group-append">
                                        <input type="date" placeholder="Amount" class="form-control" formControlName="last_paid_date" (change)="checkCustomerDateDue()">
                                    </div>
                                </div>
                                
                            </div>
                            <div class="col-xl-3">
                                <div class="form-group">
                                    <button class="btn btn-warning waves-effect waves-light" (click)="onResetSearch()" style="margin-top: 30px;">
                                        Reset
                                    </button>
                                </div>
                            </div>
                            <div class="col-xl-3">
                                <div class="form-group">
                                    <button class="btn btn-success waves-effect waves-light pull-right"  style="margin-top: 30px;" (click)="exportexcel()">
                                        Export to excel
                                    </button>
                                </div>
                                
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="card-box">
                    <!-- <h4 class="m-t-0 header-title">Basic example</h4> -->
                    <table class="table">
                        <thead>
                        <tr>
                            <th>
                                # 
                                <a style="cursor: pointer;" (click)="idSort()">
                                    <span *ngIf="_id_sort_desc"><i class="ti-arrow-circle-down"></i></span>
                                    <span *ngIf="!_id_sort_desc"><i class="ti-arrow-circle-up"></i></span>
                                </a>
                            </th>
                            <th>Customer</th>
                            <th>Add/Edit By</th>
                            <!-- <th>Address</th> -->
                            <!-- <th>Opening Balance</th> -->
                            <th>Current Balance 
                                <a style="cursor: pointer;" (click)="balanceSort()">
                                    <span *ngIf="_balance_sort_desc"><i class="ti-arrow-circle-down"></i></span>
                                    <span *ngIf="!_balance_sort_desc"><i class="ti-arrow-circle-up"></i></span>
                                </a>
                            </th>
                            <th>Joining Date</th>
                            <th>Last Invoice
                                <a style="cursor: pointer;" (click)="dateSort()">
                                    <span *ngIf="_date_sort_desc"><i class="ti-arrow-circle-down"></i></span>
                                    <span *ngIf="!_date_sort_desc"><i class="ti-arrow-circle-up"></i></span>
                                </a>
                            </th>
                            <!-- <th>Last Invoice Date</th> -->
                            <th>Last Payment</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of visibleCustomers; let i = index">
                                <th scope="row">#{{item.id}}</th>
                                <td>
                                    {{item.customer_name}}
                                    <br *ngIf="item.email"><span *ngIf="item.email"><i class="fa fa-envelope-open"></i> {{item.email}}</span>
                                    <br><span><i class="fa fa-volume-control-phone"></i> {{item.mobile}}</span>
                                </td>
                                <td>
                                    <p [innerHTML]="item.activities | byuserfilter"></p>
                                </td>
                                <!-- <td>{{item.address}}</td> -->
                                <!-- <td>
                                    <i class="fa fa-rupee"></i> {{item.opening_balance}}
                                    
                                    <div class="badge badge-primary" *ngIf="item.balance_confirmed">
                                        <i class="fa  fa-check"></i>
                                    </div>
                                    <div class="badge badge-warning" *ngIf="!item.balance_confirmed" (click)="confirmB(item.id)">
                                        <i class="fa fa-times"></i>
                                    </div>
                                </td> -->
                                <td>
                                    <span [ngClass]="{'g-c': item.current_balance < 0, 'r-c' : item.current_balance > 0 }"><i class="fa fa-rupee"></i> {{item.current_balance}}</span>
                                </td>
                                <td>
                                    <!-- {{item.date_added}} -->
                                    <!-- {{item.created_at | date:'EEEE, MMMM d, y, h:mm:ss a'}} -->
                                    {{item.created_at | date:'dd/MM/yyyy h:mm a'}}
                                </td>
                                <td>
                                    <span *ngIf="item.latest_invoice">
                                        <i class="fa fa-rupee"></i>{{item.latest_invoice.gross_total}}
                                        <br><i class="fa fa-clock-o"></i>{{item.latest_invoice.created_at | date:'dd/MM/yyyy h:mm a'}}
                                        <br>
                                    </span>
                                    <span *ngIf="!item.latest_invoice && item.last_invoice_date && item.last_invoice_date != '0000-00-00'">
                                        <i class="fa fa-clock-o"></i>{{item.last_invoice_date | date:'dd/MM/yyyy'}}
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="item.paid_invoice">
                                        <i class="fa fa-rupee"></i>{{item.paid_invoice.paid_amount}}
                                        <br><i class="fa fa-clock-o"></i>{{item.paid_invoice.created_at | date:'dd/MM/yyyy h:mm a'}}
                                    </span>
                                </td>
                                <td>
                                    <button type="button" class="btn btn-primary m-b-5 btn-xs" [routerLink]="['/view-customer',item.id]"><i class="fa fa-eye"></i></button>
                                    <button type="button" class="btn btn-success m-b-5 btn-xs" [routerLink]="['/edit-customer',item.id]"><i class="fa fa-pencil"></i></button>
                                    <!-- <button type="button" class="btn btn-danger m-b-5 btn-xs"><i class="fa fa-trash-o"></i></button> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

        <div class="row" style="display: none;">
            <div class="col-lg-12">
                <div class="card-box">
                    <!-- <h4 class="m-t-0 header-title">Basic example</h4> -->
                    <table class="table table-responsive" id="excel-table">
                        <thead>
                        <tr>
                            <th>id</th>
                            <th>name</th>
                            <th>address</th>
                            <th>email</th>
                            <th>opening_balance</th>
                            <th>mobile</th>
                            <th>normal</th>
                            <th>normal_extra</th>
                            <th>normal_minimum</th>                            
                            <th>dc</th>
                            <th>dc_extra</th>
                            <th>dc_minimum</th>
                            <th>wax</th>
                            <th>wax_extra</th>
                            <th>wax_minimum</th>
                            <th>yellow</th>
                            <th>yellow_extra</th>
                            <th>yellow_minimum</th>
                            <th>last_invoice_date</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of visibleCustomers; let i = index">
                                <th scope="row">#{{item.id}}</th>
                                <th scope="row">{{item.customer_name}}</th>
                                <th scope="row">{{item.address}}</th>
                                <th scope="row">{{item.email}}</th>
                                <th scope="row">{{item.current_balance}}</th>
                                <th scope="row">{{item.mobile}}</th>
                                <th scope="row">{{item.field_normal}}</th>
                                <th scope="row">{{item.field_normal_extra_charge}}</th>
                                <th scope="row">{{item.field_normal_minimum_charge}}</th>
                                <th scope="row">{{item.field_dc}}</th>
                                <th scope="row">{{item.field_dc_extra_charge}}</th>
                                <th scope="row">{{item.field_dc_minimum_charge}}</th>
                                <th scope="row">{{item.field_wax}}</th>
                                <th scope="row">{{item.field_wax_extra_charge}}</th>
                                <th scope="row">{{item.field_wax_minimum_charge}}</th>
                                <th scope="row">{{item.field_yellow}}</th>
                                <th scope="row">{{item.field_yellow_extra_charge}}</th>
                                <th scope="row">{{item.field_yellow_minimum_charge}}</th>
                                <th scope="row">
                                    <span *ngIf="!item.latest_invoice && item.last_invoice_date && item.last_invoice_date != '0000-00-00'">
                                        {{item.last_invoice_date | date:'dd-MM-yyyy'}}
                                    </span>
                                    <span *ngIf="item.latest_invoice">
                                        {{item.latest_invoice.created_at | date:'dd-MM-yyyy'}}
                                    </span>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

        
        <div class="modal" tabindex="-1" role="dialog" id="exampleModal">
            <form [formGroup]="importForm">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Import file</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">   
                            <div class="form-group" style="text-align: right;">
                                <a href="assets/customer-import-format.xlsx" target="_blank[]"> Format Downoad</a>
                            </div>                             
                            
                            <div class="form-group">            
                                <label for="file">File</label>                
                                <input formControlName="file" id="file" type="file" class="form-control" (change)="onFileChange($event)">                
                                <div *ngIf="g.file.touched && g.file.invalid" class="alert alert-danger">                
                                    <div *ngIf="g.file.errors.required">File is required.</div>                
                                </div>                
                            </div>              
                            <p>{{msg}}</p>
                        </div>
                    </div>
                </div>
            </form>
        </div>


    </div>
</div>