import { Component, OnInit } from '@angular/core';
import { DataService } from '../../api/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  credentialsForm: FormGroup;
  _authUser: any;
  authToken: string = null;
  submitted = false;

  constructor(private formBuilder: FormBuilder, private _router:Router, private _data: DataService) { 
    _data.authToken$.subscribe(data => {
      this.authToken = data;

      // this._router.navigate(['/dashboard']);
      if(this.authToken) {
        this._router.navigate(['/dashboard']);
      }
      
    })
  }

  ngOnInit(): void {
    this.credentialsForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  get f() { return this.credentialsForm.controls; }

  onSubmit() {
    this.submitted = true;
    // console.log('test');
    // stop here if form is invalid
    if (this.credentialsForm.invalid) {
        return;
    }

    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.loginForm.value, null, 4));
    this._data.postLogin(this.credentialsForm.value)
    .subscribe(  
        result => {
          // console.log(result); 
          if(result) {
            if('status' in result) {
              if(result['status'] == 'fail') {
                alert(result['msg'])
              }
              else if(result['status'] == 'success') {

                this._data.setApiToken(result['api_key']);
                this._data.setUserData(result['user']);

              }
            }
          }
        },
        error => {
          this._data.showSuccess('error', error);
        }
    );
  }

}
