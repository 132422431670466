
	<!-- <div style="margin: 5px;" id="pdfTable" #pdfTable> -->
  <table id="excel-table">
    <tr>
      <td>

        <table style="border:0px;">
          <tr style="border:0px;">
              <td col="2" style="text-align:center; border:0px;">
                <a (click)="onPrint()" style="float: right;">
                  <i _ngcontent-lbg-c66="" class="ti-printer "></i>
                </a>
                <h3 style="font-size: 32px; color: red;">RR 3D PRINTER</h3>
                <!-- <a (click)="downloadAsPDF()" style="float: right;">
                  <i _ngcontent-lbg-c66="" class="ti-save "></i>
                </a> -->
                <p>
                  ZAVERI BAZAR,MUMBAI-400003
                </p>
                <a (click)="exportexcel()" style="float: right;">
                  <i _ngcontent-lbg-c66="" class="ti-save-alt "></i>
                </a>
                <p>
                  Email For Caming--rr.3dprinter@gmail.com
                </p>
                <p>MOB-8879911569/022-62378524</p>
              </td>
          </tr>
        </table>
        
        <table style="font-size: 12px; border: 0px; " width="100%">
          <tr style="border:0px;">
              <td style="text-align:left; border:0px;">
                <p>{{_customer ? _customer.customer_name : null}}</p>
              </td>
              <td style="text-align:center; border:0px;">
                <p><b>Mobile No :</b> {{_customer ? _customer.mobile : null}}</p>
              </td>
              <td style="text-align:right; border:0px;">
                <p><b>Email :</b> {{_customer ? _customer.email : null}}</p>
              </td>
          </tr>
          <tr style="border:0px;">
            <td style=" border:0px;"></td>
            <td style="text-align:center; border:0px;">
              <p><b>{{heading}}</b></p>
            </td>
            <td style="text-align:right; border:0px;">
              <p><b>Date: </b>{{myDate}}</p>
            </td>
          </tr>
        </table>

        <table class="small-table" width="100%" >
          
          <tbody>
            <tr>
              <td colspan="5" style="text-align: center; font-size: 13px; border: 1px solid;"><b>CALCULATION OF THE STATEMENT :  {{totalPaid}}</b></td>
              <td colspan="9" style="text-align: center; font-size: 13px; border: 1px solid;"><b>BILL SUMMERY</b></td>
              <td colspan="5" style="text-align: center; font-size: 13px; border: 1px solid;"><b>CURRENT DUE : {{_currentBalance}}</b></td>
            </tr> 
            <tr>
              <td class="s-td" colspan="3" style="text-align: center; font-size: 10px; "><b>TOTAL : </b></td>
              <td class="s-td" style="font-size: 10px;"><b>TOTAL NORMAL CAM</b></td><td class="s-td" style="font-size: 10px;">{{totalN}}</td>
              <td class="s-td" style="font-size: 10px;"><b>TOTAL DC CAM</b></td><td class="s-td" style="font-size: 10px;">{{totalD}}</td>
              <td class="s-td" style="font-size: 10px;"><b>TOTAL WAX CAM</b></td><td class="s-td" style="font-size: 10px;">{{totalW}}</td>
              <td class="s-td" style="font-size: 10px;"><b>TOTAL YELLOW CAM</b></td><td class="s-td" style="font-size: 10px;">{{totalY}}</td>
              <td class="s-td" style="font-size: 10px;" style="text-align: center;"><b>{{totalAmount}}</b></td>
              <td class="s-td" style="font-size: 10px;" style="text-align: center;"><b>{{totalShipping}}</b></td>
              <td class="s-td" style="font-size: 10px;"></td>
              <td class="s-td" style="font-size: 10px;"></td>
              <td class="s-td" style="font-size: 10px;" style="text-align: center;"><b>{{totalDiscount}}</b></td>
              <td class="s-td" style="font-size: 10px;" style="text-align: center;"><b>{{totalPayment}}</b></td>
              <td class="s-td" style="font-size: 10px;" style="text-align: center;"><b></b></td>
              <td class="s-td" style="font-size: 10px;"></td>
            </tr>
            <tr>  
              <th style="text-align: center; font-size: 10px;">NO.</th>     
              <th style="text-align: center; font-size: 10px;">DATE</th> 
              <th style="text-align: center; font-size: 10px;">CHALAN NO.</th>
              <th colspan="8" style="text-align: center; font-size: 10px;">CAM TYPE AND WEIGHT</th>
              <th style="text-align: center; font-size: 10px;">AMOUNT</th>
              <th style="text-align: center; font-size: 10px;">PAKING &amp; SHIPPING</th>
              <th style="text-align: center; font-size: 10px;">OLD DUE/ RETURN</th>
              <th style="text-align: center; font-size: 10px;">GROSS TOTAL</th>
              <th style="text-align: center; font-size: 10px;">DISCOUNT</th>
              <th style="text-align: center; font-size: 10px;">PAYMENT</th>
              <th style="text-align: center; font-size: 10px;">CURRENT DUE/ RETURN</th>
              <th style="text-align: center; font-size: 10px;">PAYMENT METHOD</th>
            </tr>
            <tr *ngFor="let item of _invoices; let i = index">     
              <td class="s-td" style="font-size: 11px;">{{item.customer_invoice_number}}</td>     
              <td class="s-td" style="font-size: 11px;">{{item.created_at | date:'dd/MM/yyyy h:mm a'}}</td> 
              <td class="s-td" style="font-size: 11px;">{{item.invoice_number}}</td> 
              <td class="s-td" style="font-size: 11px;">{{item.NormalType && item.NormalType != 0 ? item.NormalType : null}}</td>
              <td class="s-td" style="font-size: 11px;">{{item.field_normal_weight > 0 ? item.field_normal_weight : null}}</td>
              <td class="s-td" style="font-size: 11px;">{{item.DcType && item.DcType != 0 ? item.DcType : null}}</td>
              <td class="s-td" style="font-size: 11px;">{{item.field_dc_weight > 0 ? item.field_dc_weight : null}}</td>
              <td class="s-td" style="font-size: 11px;">{{item.WaxType && item.WaxType != 0 ? item.WaxType : null}}</td>
              <td class="s-td" style="font-size: 11px;">{{item.field_wax_weight > 0 ? item.field_wax_weight : null}}</td>
              <td class="s-td" style="font-size: 11px;">{{item.YellowType && item.YellowType != 0 ? item.YellowType : null}}</td>
              <td class="s-td" style="font-size: 11px;">{{item.field_yellow_weight > 0 ? item.field_yellow_weight : null}}</td>
              <td class="s-td" style="font-size: 11px;">{{item.gross_total| number: '1.0-0'}}</td>    
              <td class="s-td" style="font-size: 11px;">{{item.shipping_charge| number: '1.0-0'}}</td> 
              <td class="s-td" style="font-size: 11px;">{{item.current_balance| number: '1.0-0'}}</td> 
              <!-- <td class="s-td" style="font-size: 11px;">{{item.payable_amount| number: '1.0-0'}}</td>       -->
              <td class="s-td" style="font-size: 11px;">{{(item.payable_amount * 1) + (item.discount * 1)}}</td>  
              <td class="s-td" style="font-size: 11px;">{{item.discount| number: '1.0-0'}}</td>       
              <td class="s-td" style="font-size: 11px;">{{item.paid_amount| number: '1.0-0'}}</td>   
              <td class="s-td" style="font-size: 11px;">{{item.current_amount| number: '1.0-0'}}</td>    
              <td class="s-td" style="font-size: 11px;">
                <span *ngIf="item.paid_amount > 0">{{item.payment_method}}</span>          
              </td> 
            </tr>
          </tbody>
        </table>

      </td>
    </tr>
  </table>
           
        
  
       
	<!-- </div> -->

    