import { Component, OnInit } from '@angular/core';
import { DataService } from '../../api/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var $ : any;

@Component({
  selector: 'app-edit-invoice',
  templateUrl: './edit-invoice.component.html',
  styleUrls: ['./edit-invoice.component.css'],
  providers: [DatePipe]
})
export class EditInvoiceComponent implements OnInit {

  token: any;
  credentialsForm: FormGroup;
  submitted = false;
  myDate: any;
  id: any;
  customers = [];
  suggestionCustomers = [];
  printedCustromerName: string;
  buttonShow: boolean = true
  _reteEditVar:boolean = false;
  showSuggestionDiv: boolean = false;

  constructor(private formBuilder: FormBuilder, private _data: DataService, private _router: Router, private _actrouter: ActivatedRoute, private datePipe: DatePipe) { 
    _data.authToken$.subscribe(data => {
      this.token = data;
      if(this.token == null) {
        this._router.navigate(['/login']);
      }      
    });
    
    this._actrouter.paramMap.subscribe(params => {
      this.id = params.get('id');
      // console.log(this.slug);
      if( this.id ) {                
        this.getInvoice(this.id);
      }
      else {
        this._router.navigate(['/all-invoice']);
      } 
    });
    
  }

  ngOnInit(): void {
    // this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    var curYear = this.datePipe.transform(new Date(), 'yyyy');
    this.credentialsForm = this.formBuilder.group({
      c_name: [''],
      customer_id: ['', Validators.required],
      address: [''],
      mobile: ['', Validators.required],
      email: [''],
      date_added: [this.myDate, Validators.required],
      field_normal: [''],
      field_normal_minimum_charge: [''],
      field_normal_extra_charge: [''],
      field_dc: [''],
      field_dc_minimum_charge: [''],
      field_dc_extra_charge: [''],
      field_wax: [''],
      field_wax_minimum_charge: [''],
      field_wax_extra_charge: [''],
      field_yellow: [''],
      field_yellow_minimum_charge: [''],
      field_yellow_extra_charge: [''],
      field_normal_weight: [''],
      field_normal_amount: [''],
      field_dc_weight: [''],
      field_dc_amount: [''],
      field_wax_weight: [''],
      field_wax_amount: [''],
      field_yellow_weight: [''],
      field_yellow_amount: [''],
      NormalType: [''],
      DcType: [''],
      WaxType: [''],
      YellowType: [''],
      shipping_charge: [''],
      payable_amount: [''],
      payable_amount_without_discount: [''],
      discount: [''],
      c_invoiceNo: ['#'],
      current_balance: [''],
      invoiceNo:  ['AI-'+curYear],
      rateEdit: [0],
      balance_after_payment: ['', Validators.required],
      paid_amount: [''],
      payment_method: [''],
      gross_total: ['']
    });   
  }

  get f() { return this.credentialsForm.controls; }

  getInvoice(id) {
    this.submitted = true;
    this._data.fetchSingleInvoice(this.token, id)
    .subscribe(  
        result => {
          // console.log(result); 
          this.submitted = false;

          if(result) {
              var data: any = result
              //console.log(data);
              // if(data['edit'] == 0) {
              //   this.buttonShow = false
              // }
              this.printedCustromerName = data.customer.customer_name;
              this.credentialsForm.get('customer_id').setValue(data['customer_id']);
              this.credentialsForm.get('c_name').setValue(data.customer.customer_name);
              this.credentialsForm.get('address').setValue(data.customer.address);
              this.credentialsForm.get('mobile').setValue(data.customer.mobile);
              this.credentialsForm.get('email').setValue(data.customer.email);

              this.credentialsForm.get('invoiceNo').setValue(data['invoice_number']);
              this.credentialsForm.get('c_invoiceNo').setValue(data['customer_invoice_number']);
              this.credentialsForm.get('field_normal').setValue(data['field_normal']);
              this.credentialsForm.get('field_normal_extra_charge').setValue(data['field_normal_extra_charge']);
              this.credentialsForm.get('field_normal_minimum_charge').setValue(data['field_normal_minimum_charge']);
              this.credentialsForm.get('field_dc').setValue(data['field_dc']);
              this.credentialsForm.get('field_dc_extra_charge').setValue(data['field_dc_extra_charge']);
              this.credentialsForm.get('field_dc_minimum_charge').setValue(data['field_dc_minimum_charge']);
              this.credentialsForm.get('field_wax').setValue(data['field_wax']);
              this.credentialsForm.get('field_wax_extra_charge').setValue(data['field_wax_extra_charge']);
              this.credentialsForm.get('field_wax_minimum_charge').setValue(data['field_wax_minimum_charge']);
              this.credentialsForm.get('field_yellow').setValue(data['field_yellow']);
              this.credentialsForm.get('field_yellow_extra_charge').setValue(data['field_yellow_extra_charge']);
              this.credentialsForm.get('field_yellow_minimum_charge').setValue(data['field_yellow_minimum_charge']);              
              this.credentialsForm.get('field_normal_weight').setValue(data['field_normal_weight'] > 0 ? data['field_normal_weight'] : '');
              this.credentialsForm.get('field_normal_amount').setValue(data['field_normal_amount'] > 0 ? data['field_normal_amount'] : '');
              this.credentialsForm.get('field_dc_weight').setValue(data['field_dc_weight'] > 0 ? data['field_dc_weight'] : '');
              this.credentialsForm.get('field_dc_amount').setValue(data['field_dc_amount'] > 0 ? data['field_dc_amount'] : '');
              this.credentialsForm.get('field_wax_weight').setValue(data['field_wax_weight'] > 0 ? data['field_wax_weight'] : '');
              this.credentialsForm.get('field_wax_amount').setValue(data['field_wax_amount'] > 0 ? data['field_wax_amount'] : '');
              this.credentialsForm.get('field_yellow_weight').setValue(data['field_yellow_weight'] > 0 ? data['field_yellow_weight'] : '');
              this.credentialsForm.get('field_yellow_amount').setValue(data['field_yellow_amount'] > 0 ? data['field_yellow_amount'] : '');

              this.credentialsForm.get('shipping_charge').setValue(data['shipping_charge']);
              this.credentialsForm.get('payable_amount').setValue(data['payable_amount']);
              this.credentialsForm.get('NormalType').setValue(data['NormalType']);
              this.credentialsForm.get('DcType').setValue(data['DcType']);
              this.credentialsForm.get('WaxType').setValue(data['WaxType']);
              this.credentialsForm.get('YellowType').setValue(data['YellowType']);
              this.credentialsForm.get('discount').setValue(data['discount']);
              this.credentialsForm.get('current_balance').setValue(data['current_balance']); 
              this.credentialsForm.get('balance_after_payment').setValue(data['current_amount']);
              this.credentialsForm.get('payment_method').setValue(data['payment_method']);
              this.credentialsForm.get('date_added').setValue(data['date_added']);
              this.credentialsForm.get('paid_amount').setValue(data['paid_amount']);
              this.credentialsForm.get('gross_total').setValue(Math.round(data['gross_total']));

              var _pa = data['payable_amount'] > 0 ? data['payable_amount'] : 0;
              var _da = data['discount'] > 0 ? data['discount'] : 0;
              var payable_amount_without_discount =  _pa - _da;
              this.credentialsForm.get('payable_amount_without_discount').setValue(Math.round(payable_amount_without_discount));
              this.calculateTotal()
          }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }

  onSubmit(p) {
    this.submitted = true;
    // console.log(this.credentialsForm.value);
    // return;
    // stop here if form is invalid
    if (this.credentialsForm.invalid) {
        return;
    }
    // console.log(this.credentialsForm.value)
    // return;

    if(confirm("Are you sure you want to update this bill? ")) { 
      this._data.AddEditInvoice(this.credentialsForm.value, this.token, this.id)
      .subscribe(  
          result => {
            //console.log(result); 
            this.submitted = false;
            if(result) {
              if('status' in result && result['status']){ 
                if(result['status'] == "success") {
                  this._data.showSuccess('success', result['msg']);
                  if(this.id) {}
                  else
                    this.credentialsForm.reset();

                  if(p) {
                    // alert(1)
                    // this._router.navigate(['/print-specific-invoice/'+this.id]);
                    
                    //window.open('https://al3dprinterbill.in/admin/api/pdf/'+this.id, '_blank');


                    window.open('https://rr3dprinterbill.co.in/admin/index.php/api/pdf/'+this.id, '_blank');
                  }
                }
                if(result['status'] == "fail") {
                  this._data.showSuccess('warning', result['msg']);
                }
              }
            }
            
          },
          error => {
            this.submitted = false;
            this._data.showSuccess('error', error);
          }
      );
    }

    
  }

  camChange(deviceValue, type) {
    if(type == "Normal") {
      this.credentialsForm.get('field_normal_weight').setValue('');
      this.credentialsForm.get('field_normal_amount').setValue('');  
      if(deviceValue == "Normal-Minimum") {      
        //console.log(this.credentialsForm.value.field_normal )  
        //console.log(this.credentialsForm.value.field_normal_minimum_charge )  
        if(this.credentialsForm.value.field_normal > 0 && this.credentialsForm.value.field_normal_minimum_charge > 0) {
          this.credentialsForm.get('field_normal_amount').setValue(this.credentialsForm.value.field_normal_minimum_charge);
          var w: any = this.credentialsForm.value.field_normal_minimum_charge / this.credentialsForm.value.field_normal;
          w = w.toFixed(2);
          console
          this.credentialsForm.get('field_normal_weight').setValue(w);
        }
      }      
    }
    else if(type == "Dc") {
      this.credentialsForm.get('field_dc_weight').setValue('');
      this.credentialsForm.get('field_dc_amount').setValue('');  
      if(deviceValue == "Dc-Minimum") {  
        // console.log(this.credentialsForm.value.field_dc)     
        // console.log(this.credentialsForm.value.field_dc_minimum_charge)      
        if(this.credentialsForm.value.field_dc > 0 && this.credentialsForm.value.field_dc_minimum_charge > 0) {
          this.credentialsForm.get('field_dc_amount').setValue(this.credentialsForm.value.field_dc_minimum_charge);
          var w: any = this.credentialsForm.value.field_dc_minimum_charge / this.credentialsForm.value.field_dc;
          w = w.toFixed(2);
          this.credentialsForm.get('field_dc_weight').setValue(w);
        }
      }      
    }
    else if(type == "Wax") {
      this.credentialsForm.get('field_wax_weight').setValue('');
      this.credentialsForm.get('field_wax_amount').setValue('');  
      if(deviceValue == "Wax-Minimum") {        
        if(this.credentialsForm.value.field_wax > 0 && this.credentialsForm.value.field_wax_minimum_charge > 0) {
          this.credentialsForm.get('field_wax_amount').setValue(this.credentialsForm.value.field_wax_minimum_charge);
          var w: any = this.credentialsForm.value.field_wax_minimum_charge / this.credentialsForm.value.field_wax;
          w = w.toFixed(2);
          this.credentialsForm.get('field_wax_weight').setValue(w);
        }
      }      
    }
    else if(type == "Yellow") {
      this.credentialsForm.get('field_yellow_weight').setValue('');
      this.credentialsForm.get('field_yellow_amount').setValue('');  
      if(deviceValue == "Yellow-Minimum") {        
        if(this.credentialsForm.value.field_yellow > 0 && this.credentialsForm.value.field_yellow_minimum_charge > 0) {
          this.credentialsForm.get('field_yellow_amount').setValue(this.credentialsForm.value.field_yellow_minimum_charge);
          var w: any = this.credentialsForm.value.field_yellow_minimum_charge / this.credentialsForm.value.field_yellow;
          w = w.toFixed(2);
          this.credentialsForm.get('field_yellow_weight').setValue(w);
        }
      }      
    }
    this.calculateTotal()
  }

  weightType(x, type) {
    // console.log(x)
    var deviceValue  = null;
    if(type == 'Normal') {
      deviceValue = this.credentialsForm.value.NormalType
      var amt = 0
      if(deviceValue == "Normal") {
        amt = this.credentialsForm.value.field_normal * x;        
      }
      else if(deviceValue == "Normal-Extra") {
        amt = this.credentialsForm.value.field_normal_extra_charge * x;
      }
      else if(deviceValue == "Normal-Minimum") {
        amt = this.credentialsForm.value.field_normal * x;
      }
      this.credentialsForm.get('field_normal_amount').setValue(Math.round(amt));
    }
    if(type == 'Dc') {
      deviceValue = this.credentialsForm.value.DcType
      var amt = 0
      if(deviceValue == "Dc") {
        amt = this.credentialsForm.value.field_dc * x;        
      }
      else if(deviceValue == "Dc-Extra") {
        amt = this.credentialsForm.value.field_dc_extra_charge * x;
      }
      else if(deviceValue == "Dc-Minimum") {
        amt = this.credentialsForm.value.field_dc * x;
      }
      this.credentialsForm.get('field_dc_amount').setValue(Math.round(amt));
    }
    if(type == 'Wax') {
      deviceValue = this.credentialsForm.value.WaxType
      var amt = 0
      if(deviceValue == "Wax") {
        amt = this.credentialsForm.value.field_wax * x;        
      }
      else if(deviceValue == "Wax-Extra") {
        amt = this.credentialsForm.value.field_wax_extra_charge * x;
      }
      else if(deviceValue == "Wax-Minimum") {
        amt = this.credentialsForm.value.field_wax * x;
      }
      this.credentialsForm.get('field_wax_amount').setValue(Math.round(amt));
    }
    if(type == 'Yellow') {
      deviceValue = this.credentialsForm.value.YellowType
      var amt = 0
      if(deviceValue == "Yellow") {
        amt = this.credentialsForm.value.field_yellow * x;        
      }
      else if(deviceValue == "Yellow-Extra") {
        amt = this.credentialsForm.value.field_yellow_extra_charge * x;
      }
      else if(deviceValue == "Yellow-Minimum") {
        amt = this.credentialsForm.value.field_yellow * x;
      }
      this.credentialsForm.get('field_yellow_amount').setValue(Math.round(amt));
    }
    this.calculateTotal()
  }

  calculateTotal() {
    
    var grosstotal = 0;
    if(this.credentialsForm.value.field_normal_amount > 0) {
      grosstotal +=  (this.credentialsForm.value.field_normal_amount * 1)
    }
    if(this.credentialsForm.value.field_wax_amount > 0) {
      grosstotal +=  (this.credentialsForm.value.field_wax_amount * 1)
    }
    if(this.credentialsForm.value.field_dc_amount > 0) {
      grosstotal +=  (this.credentialsForm.value.field_dc_amount * 1)
    }
    if(this.credentialsForm.value.field_yellow_amount > 0) {
      grosstotal +=  (this.credentialsForm.value.field_yellow_amount * 1)
    }
    
    // var total = (this.credentialsForm.value.current_balance * 1) - grosstotal; 
    var total = grosstotal + (this.credentialsForm.value.current_balance * 1);   
        
    if(this.credentialsForm.value.shipping_charge > 0) {
      // total -= this.credentialsForm.value.shipping_charge * 1
      total += this.credentialsForm.value.shipping_charge * 1
    }
    this.credentialsForm.get('payable_amount_without_discount').setValue(total);
    if(this.credentialsForm.value.discount > 0) {
      // total += (this.credentialsForm.value.discount * 1)
      total -= (this.credentialsForm.value.discount * 1)
    }
    // this.credentialsForm.get('payable_amount').setValue(-(total));
    this.credentialsForm.get('payable_amount').setValue(total);
    this.credentialsForm.get('gross_total').setValue(Math.round(grosstotal));

    var a = total
    if(this.credentialsForm.value.paid_amount > 0 || this.credentialsForm.value.paid_amount < 0 || this.credentialsForm.value.paid_amount == 0) {       
      // a = (total * 1) + (this.credentialsForm.value.paid_amount * 1); 
      a = (total * 1) - (this.credentialsForm.value.paid_amount * 1);      
    }    
    a = Math.round(a);
    this.credentialsForm.get('balance_after_payment').setValue(a);
  }

  editRate() {
    console.log(this.credentialsForm.value.rateEdit)
    this._reteEditVar = false
    if(this.credentialsForm.value.rateEdit) {
      this._reteEditVar = true
    }
  }

  rateEditFun() {
    // this.credentialsForm.get('NormalType').setValue('');
    // this.credentialsForm.get('DcType').setValue('');
    // this.credentialsForm.get('WaxType').setValue('');
    // this.credentialsForm.get('YellowType').setValue('');
    // this.credentialsForm.get('shipping_charge').setValue('');
    // this.credentialsForm.get('payable_amount').setValue('');
    // this.credentialsForm.get('discount').setValue('');
    // this.credentialsForm.get('balance_after_payment').setValue('');
    // this.credentialsForm.get('paid_amount').setValue('');
    // this.credentialsForm.get('payment_method').setValue('Cash');
    // this.credentialsForm.get('gross_total').setValue('');
    // this.credentialsForm.get('field_yellow_amount').setValue('');
    // this.credentialsForm.get('field_yellow_weight').setValue('');
    // this.credentialsForm.get('field_wax_amount').setValue('');
    // this.credentialsForm.get('field_wax_weight').setValue('');
    // this.credentialsForm.get('field_dc_amount').setValue('');
    // this.credentialsForm.get('field_dc_weight').setValue('');
    // this.credentialsForm.get('field_normal_amount').setValue('');
    // this.credentialsForm.get('field_normal_weight').setValue('');
    this.calculateTotal()
  }

  onReset() {
    this.submitted = false;
    this.credentialsForm.reset();
  }


}
