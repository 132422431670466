
<div class="wrapper">
  <div class="container-fluid">

      <!-- Page-Title -->
      <div class="row">
          <div class="col-sm-12">
            <div class="btn-group pull-right m-t-20">
                <a class="btn btn-custom" [routerLink]="['/all-customer']">All Customer </a>              
            </div>
              <h4 class="page-title">Edit Customer</h4>
          </div>
      </div>
      <!-- end page title end breadcrumb -->

      <div class="row" *ngIf="customer">
        <div class="col-sm-12">
            <div class="card-box"> 
              <form [formGroup]="credentialsForm">
                <div class="row">
                  
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label >Customer Name*</label>
                        <input type="text" placeholder="Enter Customer Name" class="form-control" formControlName="customer_name" [ngClass]="{ 'is-invalid': submitted && f.customer_name.errors }">
                        <div *ngIf="submitted && f.customer_name.errors" class="invalid-feedback">
                          <div *ngIf="f.customer_name.errors.required">Customer Name is required</div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label >Email</label>
                        <input type="email" placeholder="Enter Email" class="form-control" formControlName="email" >
                        
                      </div>
                      <div class="form-group">
                        <label >Mobile*</label>
                        <input type="number" placeholder="Enter Mobile" class="form-control" formControlName="mobile" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }">
                        <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                          <div *ngIf="f.mobile.errors.required">mobile is required</div>
                        </div>
                      </div>
                    </div><!-- end col -->
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label >Address</label>
                        <input type="text" placeholder="Address" class="form-control" formControlName="address" >
                        <!-- <textarea class="form-control" style="min-height: 122px !important;" formControlName="address" [ngClass]="{ 'is-invalid': submitted && f.address.errors }"></textarea> -->
                        
                      </div>
                      <div class="form-group">
                        <label >Opening Balance*</label>
                        <input type="text" placeholder="Enter Opening Balance" class="form-control" formControlName="opening_balance" [ngClass]="{ 'is-invalid': submitted && f.opening_balance.errors }">
                        <div *ngIf="submitted && f.opening_balance.errors" class="invalid-feedback">
                          <div *ngIf="f.opening_balance.errors.required">opening_balance is required</div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label >Last Invoice Date</label>
                        <input type="date" placeholder="Last Invoice Date" class="form-control" formControlName="last_invoice_date">
                      </div>
                    </div><!-- end col -->
                    
                </div><!-- end row -->
                <h4 class="header-title m-t-0 m-b-30">CAM Type with	Rate/GM</h4>
                
                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label >Normal*</label>
                      <input type="text" placeholder="Enter Normal" class="form-control" formControlName="field_normal" [ngClass]="{ 'is-invalid': submitted && f.field_normal.errors }"  style="background-color: #c0ffc0;">
                      <div *ngIf="submitted && f.field_normal.errors" class="invalid-feedback">
                          <div *ngIf="f.field_normal.errors.required">Normal is required</div>
                        </div>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label >Normal Extra Charge*</label>
                      <input type="text" placeholder="Enter Normal Extra Charge" class="form-control" formControlName="field_normal_extra_charge" [ngClass]="{ 'is-invalid': submitted && f.field_normal_extra_charge.errors }"  style="background-color: #c0ffc0;">
                      <div *ngIf="submitted && f.field_normal_extra_charge.errors" class="invalid-feedback">
                          <div *ngIf="f.field_normal_extra_charge.errors.required">Normal extra charge is required</div>
                        </div>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label >Normal Minimum Charge*</label>
                      <input type="text" placeholder="Enter Normal Minimum Charge" class="form-control" formControlName="field_normal_minimum_charge" [ngClass]="{ 'is-invalid': submitted && f.field_normal_minimum_charge.errors }"  style="background-color: #c0ffc0;">
                      <div *ngIf="submitted && f.field_normal_minimum_charge.errors" class="invalid-feedback">
                          <div *ngIf="f.field_normal_minimum_charge.errors.required">Normal minimum charge is required</div>
                        </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label >DC*</label>
                      <input type="text" placeholder="Enter DC" class="form-control" formControlName="field_dc" [ngClass]="{ 'is-invalid': submitted && f.field_dc.errors }"  style="background-color: #008000;">
                      <div *ngIf="submitted && f.field_dc.errors" class="invalid-feedback">
                          <div *ngIf="f.field_dc.errors.required">DC is required</div>
                        </div>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label >DC Extra Charge*</label>
                      <input type="text" placeholder="Enter DC Extra Charge" class="form-control" formControlName="field_dc_extra_charge" [ngClass]="{ 'is-invalid': submitted && f.field_dc_extra_charge.errors }"  style="background-color: #008000;">
                      <div *ngIf="submitted && f.field_dc_extra_charge.errors" class="invalid-feedback">
                          <div *ngIf="f.field_dc_extra_charge.errors.required">DC Eextra Charge is required</div>
                        </div>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label >Dc Minimum Charge*</label>
                      <input type="text" placeholder="Enter Dc Minimum Charge" class="form-control" formControlName="field_dc_minimum_charge" [ngClass]="{ 'is-invalid': submitted && f.field_dc_minimum_charge.errors }"  style="background-color: #008000;">
                      <div *ngIf="submitted && f.field_dc_minimum_charge.errors" class="invalid-feedback">
                          <div *ngIf="f.field_dc_minimum_charge.errors.required">Dc minimum charge is required</div>
                        </div>
                    </div>
                  </div>
                </div>                

                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label >WAX*</label>
                      <input type="text" placeholder="Enter WAX" class="form-control" formControlName="field_wax" [ngClass]="{ 'is-invalid': submitted && f.field_wax.errors }" style="background-color: #ff80ff;">
                      <div *ngIf="submitted && f.field_wax.errors" class="invalid-feedback">
                          <div *ngIf="f.field_wax.errors.required">WAX is required</div>
                        </div>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label >WAX Extra Charge*</label>
                      <input type="text" placeholder="Enter WAX Extra Charge" class="form-control" formControlName="field_wax_extra_charge" [ngClass]="{ 'is-invalid': submitted && f.field_wax_extra_charge.errors }" style="background-color: #ff80ff;">
                      <div *ngIf="submitted && f.field_wax_extra_charge.errors" class="invalid-feedback">
                          <div *ngIf="f.field_wax_extra_charge.errors.required">Wax Extra Charge is required</div>
                        </div>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label >WAX minimum Charge*</label>
                      <input type="text" placeholder="Enter WAX minimum Charge" class="form-control" formControlName="field_wax_minimum_charge" [ngClass]="{ 'is-invalid': submitted && f.field_wax_minimum_charge.errors }" style="background-color: #ff80ff;">
                      <div *ngIf="submitted && f.field_wax_minimum_charge.errors" class="invalid-feedback">
                          <div *ngIf="f.field_wax_minimum_charge.errors.required">Wax minimum Charge is required</div>
                        </div>
                    </div>
                  </div>
                </div>

                
                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label >Yellow*</label>
                      <input type="text" placeholder="Enter Yellow" class="form-control" formControlName="field_yellow" [ngClass]="{ 'is-invalid': submitted && f.field_yellow.errors }"  style="background-color: #ffffc0;">
                      <div *ngIf="submitted && f.field_yellow.errors" class="invalid-feedback">
                          <div *ngIf="f.field_yellow.errors.required">Yellow is required</div>
                        </div>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label >Yellow Extra Charge*</label>
                      <input type="text" placeholder="Enter Yellow Extra Charge" class="form-control" formControlName="field_yellow_extra_charge" [ngClass]="{ 'is-invalid': submitted && f.field_yellow_extra_charge.errors }"  style="background-color: #ffffc0;">
                      <div *ngIf="submitted && f.field_yellow_extra_charge.errors" class="invalid-feedback">
                          <div *ngIf="f.field_yellow_extra_charge.errors.required">Yellow Extra Charge is required</div>
                        </div>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label >Yellow Minimum Charge*</label>
                      <input type="text" placeholder="Enter Yellow Minimum Charge" class="form-control" formControlName="field_yellow_minimum_charge" [ngClass]="{ 'is-invalid': submitted && f.field_yellow_minimum_charge.errors }"  style="background-color: #ffffc0;">
                      <div *ngIf="submitted && f.field_yellow_minimum_charge.errors" class="invalid-feedback">
                          <div *ngIf="f.field_yellow_minimum_charge.errors.required">Yellow Minimum Charge is required</div>
                        </div>
                    </div>
                  </div>
                </div>
                
                
                
                
                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group text-right m-b-0">
                      <button class="btn btn-primary waves-effect waves-light" (click)="onSubmit()">
                          Submit
                      </button>
                      <!-- <button type="reset" class="btn btn-secondary waves-effect waves-light m-l-5" (click)="onReset()">
                          Cancel
                      </button> -->
                    </div>   
                  </div>               
                </div><!-- end row -->
              </form>
            </div>
          </div><!-- end col -->
      </div>
      <!-- end row -->
  </div>
</div>