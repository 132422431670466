
<div class="wrapper">
  <div class="container-fluid">

      <!-- Page-Title -->
      <div class="row">
          <div class="col-sm-12">
            <div class="btn-group pull-right m-t-20">
                <a class="btn btn-custom" [routerLink]="['/all-customer']">All Customer </a>              
            </div>
              <h4 class="page-title">View Customer</h4>
          </div>
      </div>
      <!-- end page title end breadcrumb -->

      <div class="row" *ngIf="customer">
        <div class="col-sm-12">
            <div class="card-box"> 
              <form [formGroup]="credentialsForm">
                <div class="row">
                  
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label >Customer Name</label>
                        <input readonly="" type="text" placeholder="Enter Customer Name" class="form-control" formControlName="customer_name" >
                      </div>
                      <div class="form-group">
                        <label >Email</label>
                        <input readonly="" type="email" placeholder="Enter Email" class="form-control" formControlName="email">
                      </div>
                      <div class="form-group">
                        <label >Mobile</label>
                        <input readonly="" type="number" placeholder="Enter Mobile" class="form-control" formControlName="mobile">
                      </div>
                    </div><!-- end col -->
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label >Address</label>
                        <textarea  readonly="" class="form-control" style="min-height: 122px !important;" formControlName="address"></textarea>
                      </div>
                      <div class="form-group">
                        <label >Opening Balance</label>
                        <input readonly="" type="text" placeholder="Enter Opening Balance" class="form-control" formControlName="opening_balance">
                      </div>
                    </div><!-- end col -->
                    
                </div><!-- end row -->
                <h4 class="header-title m-t-0 m-b-30">CAM Type with	Rate/GM</h4>
                
                <div class="row">
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >Normal</label>
                      <input readonly="" type="text" placeholder="Enter Normal" class="form-control" formControlName="field_normal" style="background-color: #c0ffc0;">
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >Normal Extra Charge</label>
                      <input readonly="" type="text" placeholder="Enter Normal Extra Charge" class="form-control" formControlName="field_normal_extra_charge" style="background-color: #c0ffc0;">
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >Normal Minimum Charge</label>
                      <input readonly="" type="text" placeholder="Enter Normal Minimum Charge" class="form-control" formControlName="field_normal_minimum_charge"  style="background-color: #c0ffc0;">
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >Total Normal CAM</label>
                      <input readonly="" type="text" class="form-control" formControlName="total_normal_cam">
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >Total Packing Charge</label>
                      <input readonly="" type="text" class="form-control" formControlName="total_packing_charge">
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >Total Invoice</label>
                      <input readonly="" type="text" class="form-control" formControlName="total_invoice">
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >DC</label>
                      <input readonly="" type="text" placeholder="Enter DC" class="form-control" formControlName="field_dc"  style="background-color: #008000;">
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >DC Extra Charge</label>
                      <input readonly="" type="text" placeholder="Enter DC Extra Charge" class="form-control" formControlName="field_dc_extra_charge"  style="background-color: #008000;">
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >Dc Minimum Charge</label>
                      <input readonly="" type="text" placeholder="Enter Dc Minimum Charge" class="form-control" formControlName="field_dc_minimum_charge"  style="background-color: #008000;">
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >Total DC CAM</label>
                      <input readonly="" type="text" class="form-control" formControlName="total_dc_cam">
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >Total Discount</label>
                      <input readonly="" type="text" class="form-control" formControlName="total_discount">
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >Total Invoice Value</label>
                      <input readonly="" type="text" class="form-control" formControlName="total_invoice_value">
                    </div>
                  </div>
                </div>                

                <div class="row">
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >WAX</label>
                      <input readonly="" type="text" placeholder="Enter WAX" class="form-control" formControlName="field_wax" style="background-color: #ff80ff;">
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >WAX Extra Charge</label>
                      <input readonly="" type="text" placeholder="Enter WAX Extra Charge" class="form-control" formControlName="field_wax_extra_charge" style="background-color: #ff80ff;">
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >WAX minimum Charge</label>
                      <input readonly="" type="text" placeholder="Enter WAX minimum Charge" class="form-control" formControlName="field_wax_minimum_charge" style="background-color: #ff80ff;">
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >Total Wax CAM</label>
                      <input readonly="" type="text" class="form-control" formControlName="total_wax_cam">
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >Total Payment</label>
                      <input readonly="" type="text" class="form-control" formControlName="total_payment">
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >Last Bill Date</label>
                      <input readonly="" type="text" class="form-control" formControlName="last_bill_date">
                    </div>
                  </div>
                </div>

                
                <div class="row">
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >Yellow</label>
                      <input readonly="" type="text" placeholder="Enter Yellow" class="form-control" formControlName="field_yellow" style="background-color: #ffffc0;">
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >Yellow Extra Charge</label>
                      <input readonly="" type="text" placeholder="Enter Yellow Extra Charge" class="form-control" formControlName="field_yellow_extra_charge"  style="background-color: #ffffc0;">
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >Yellow Minimum Charge</label>
                      <input readonly="" type="text" placeholder="Enter Yellow Minimum Charge" class="form-control" formControlName="field_yellow_minimum_charge" style="background-color: #ffffc0;">
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >Total Yellow CAM</label>
                      <input readonly="" type="text" class="form-control" formControlName="total_yellow_cam">
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >Current Due</label>
                      <input readonly="" type="text" class="form-control" formControlName="total_current_due">
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group">
                      <label >Last Bill No</label>
                      <input readonly="" type="text" class="form-control" formControlName="last_bill_no">
                    </div>
                  </div>
                </div>
                
                
              </form>
            </div>
          </div><!-- end col -->
      </div>
      <!-- end row -->
  </div>
</div>