import { Component, OnInit } from '@angular/core';
import { DataService } from '../../api/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-reminder',
  templateUrl: './reminder.component.html',
  styleUrls: ['./reminder.component.css'],
  providers: [DatePipe]
})
export class ReminderComponent implements OnInit {

  token: any;
  credentialsForm: FormGroup;
  submitted = false;
  myDate: any;
  id: any;
  customers = [];
  invoices = [];
  showSuggestionDiv: boolean = false;
  suggestionCustomers = [];

  constructor(private formBuilder: FormBuilder, private _data: DataService, private _router: Router, private _actrouter: ActivatedRoute, private datePipe: DatePipe) { 
    _data.authToken$.subscribe(data => {
      this.token = data;
      if(this.token == null) {
        this._router.navigate(['/login']);
      } 
      else {
        this.fetchCustomer();
      }     
    });
            
  }

  ngOnInit(): void {
    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.credentialsForm = this.formBuilder.group({
      customer_id: ['', Validators.required],
      address: [''],
      mobile: [''],
      email: [''],
      current_balance: ['0', Validators.required],
      c_name: ['']
    });    
  }

  get f() { return this.credentialsForm.controls; }
  
  fetchCustomer() {
    this.submitted = true;
    
    this._data.fetchCustomerWithInvoice(this.token)
    .subscribe(  
        result => {
          this.submitted = false;
          // console.log(result); 
          if(result) {
            if('status' in result && result['status']){ 
              if(result['status'] == "success") {
                this.customers = result['result'];
                //console.log(this.customers)
                // this._data.showSuccess('success', result['msg']);
              }
              if(result['status'] == "fail") {
                this._data.showSuccess('warning', result['msg']);
              }
            }
          }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }

  onChange(deviceValue) {
    this.showSuggestionDiv = false; 
    var _e = '';
    var _m = '';
    var _a = '';
    var c_invoiceNo = "";
    var _due = 0
    if(deviceValue) {
      var d = this.customers.find(x => x.id == deviceValue);
      if(d) {
        //console.log(d);
        _e = d['email']
        _m = d['mobile']
        _a = d['address']
        _due = d['current_balance']
        this.invoices = d['due_invoices']
        this.credentialsForm.get('c_name').setValue(d['customer_name']);
        this.credentialsForm.get('customer_id').setValue(deviceValue);
        //console.log(this.invoices )
      }
      this.credentialsForm.get('email').setValue(_e);
      this.credentialsForm.get('mobile').setValue(_m);
      this.credentialsForm.get('address').setValue(_a);
      this.credentialsForm.get('current_balance').setValue(_due);
    }    
    
  }

  onSubmit() {
    this.submitted = true;
    // console.log('test');
    // stop here if form is invalid
    if (this.credentialsForm.invalid) {
        return;
    }
    
    this._data.sendReminder(this.token, this.credentialsForm.value)
    .subscribe(  
        result => {
          this.submitted = false;
          console.log(result); 
          if(result) {
            if('status' in result && result['status']){ 
              if(result['status'] == "success") {
                this._data.showSuccess('success', result['msg']);

                this.credentialsForm.get('customer_id').setValue('');
                this.credentialsForm.get('address').setValue('');
                this.credentialsForm.get('mobile').setValue('');
                this.credentialsForm.get('email').setValue('');
                this.credentialsForm.get('current_balance').setValue('');
                this.credentialsForm.get('c_name').setValue('');

              }
              if(result['status'] == "fail") {
                this._data.showSuccess('warning', result['msg']);
              }
            }
          }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );

  }

  nameSuggestion(x) {
    this.suggestionCustomers = [];
    this.credentialsForm.get('customer_id').setValue('');
    this.showSuggestionDiv = true;
    if(x && x.length > 1) {
      //console.log(this.customers)
      var searchString = x.toLowerCase();
      this.suggestionCustomers = this.customers.filter(function(itm){
        return itm.customer_name.toLowerCase().indexOf(searchString) !== -1
      });
    }
  }

}
