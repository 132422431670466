
<div class="wrapper">
  <div class="container-fluid">

      <!-- Page-Title -->
      <div class="row">
          <div class="col-sm-12">                
              <h4 class="page-title">Statistics</h4>
          </div>
      </div>
      <!-- end page title end breadcrumb -->


      <div class="row">
          <!--<div class="col-xl-4">
              <div class="card-box">
                  <h4 class="header-title mt-0">Daily Sales</h4>

                  <div class="widget-chart text-center">
                      
                      <canvas baseChart 
                          [datasets]="lineChartData" 
                          [labels]="lineChartLabels" 
                          [options]="lineChartOptions"
                          [colors]="lineChartColors" 
                          [legend]="lineChartLegend" 
                          [chartType]="lineChartType" 
                          [plugins]="lineChartPlugins">
                      </canvas>


                      <ul class="list-inline chart-detail-list mb-0">
                          <li class="list-inline-item">
                              <h5 style="color: #ff8acc;"><i class="fa fa-circle m-r-5"></i>Series A</h5>
                          </li>
                          <li class="list-inline-item">
                              <h5 style="color: #5b69bc;"><i class="fa fa-circle m-r-5"></i>Series B</h5>
                          </li>
                      </ul>
                  </div>
              </div>
          </div> --><!-- end col -->

          <div class="col-xl-4">
              <div class="card-box">
                  <h4 class="header-title mt-0">Received Payment</h4>
                  <div class="widget-chart text-center">
                      <canvas baseChart 
                          [data]="_doughnutChartData ? _doughnutChartData : doughnutChartData"
                          [labels]="doughnutChartLabels"
                          [chartType]="doughnutChartType">
                      </canvas>
                  </div>
              </div>
          </div> <!-- end col -->

          <div class="col-xl-4">
              <div class="card-box">
                  <h4 class="header-title mt-0">Total WEIGHT</h4>
                  <div class="widget-chart text-center">
                      <canvas baseChart 
                          [datasets]="_barChartData ? _barChartData : barChartData"
                          [labels]="barChartLabels"
                          [options]="barChartOptions"
                          [plugins]="barChartPlugins"
                          [legend]="barChartLegend"
                          [chartType]="barChartType">
                      </canvas>
                  </div>
              </div>
          </div><!-- end col -->

      </div>
      <!-- end row -->

  </div>
</div>