
<div class="wrapper">
  <div class="container-fluid">

      <!-- Page-Title -->
      <div class="row">
          <div class="col-sm-12">
            <!-- <div class="btn-group pull-right m-t-20">
              <a class="btn btn-custom" [routerLink]="['/all-invoice']">All Invoices </a>              
            </div> -->
            <h4 class="page-title">Make Payment</h4>
          </div>
      </div>
      <!-- end page title end breadcrumb -->

      <div class="row">
        <div class="col-sm-3"></div>
        <div class="col-sm-6">
          <div class="card-box"> 
            <form [formGroup]="credentialsForm">
              <div class="row">
                
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label >Customer Name*</label>
                      <select class="form-control" (change)="onChange($event.target.value)" formControlName="customer_id" [ngClass]="{ 'is-invalid': submitted && f.customer_id.errors }" >
                        <option value="">Select</option>
                        <ng-container *ngFor="let item of customers; let i = index">
                          <option  value="{{item.id}}" *ngIf="item.balance_confirmed">{{item.customer_name}}</option>
                        </ng-container>                          
                      </select>
                      <div *ngIf="submitted && f.customer_id.errors" class="invalid-feedback">
                        <div *ngIf="f.customer_id.errors.required">Customer is required</div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label >Email*</label>
                      <input type="email" placeholder="Enter Email" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" readonly="">
                      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">email is required</div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label >Mobile*</label>
                      <input type="number" placeholder="Enter Mobile" class="form-control" formControlName="mobile" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" readonly="">
                      <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                        <div *ngIf="f.mobile.errors.required">mobile is required</div>
                      </div>
                    </div>
                  </div><!-- end col -->
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label >Address*</label>
                      <textarea class="form-control" readonly="" style="min-height: 122px !important;" formControlName="address" [ngClass]="{ 'is-invalid': submitted && f.address.errors }"></textarea>
                      <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                        <div *ngIf="f.address.errors.required">address is required</div>
                      </div>
                    </div>  
                    <div class="form-group">
                      <label >Current Balance*</label>
                      <input type="text" placeholder="Enter Current Balance" class="form-control" formControlName="current_balance" [ngClass]="{ 'is-invalid': submitted && f.current_balance.errors }" readonly="">
                      <div *ngIf="submitted && f.current_balance.errors" class="invalid-feedback">
                        <div *ngIf="f.current_balance.errors.required">current balance is required</div>
                      </div>
                    </div>                  
                  </div><!-- end col -->
                  
              </div><!-- end row -->     
              <h4 class="header-title m-t-0 m-b-30">Payment Details</h4>    
              <div class="row">
                
                <div class="col-xl-6">                  
                  <div class="form-group">
                    <label >Payment Method*</label>
                    <select class="form-control" formControlName="payment_method" [ngClass]="{ 'is-invalid': submitted && f.payment_method.errors }">
                      <option value="Cash">Cash</option>
                      <option value="Card">Card</option>
                      <option value="Online">Online</option>
                    </select>
                    <div *ngIf="submitted && f.payment_method.errors" class="invalid-feedback">
                      <div *ngIf="f.payment_method.errors.required">payment method is required</div>
                    </div>
                  </div>
                </div><!-- end col -->
                <div class="col-xl-6">
                  <div class="form-group">
                    <label >Amount*</label>
                    <input type="number" placeholder="Enter Mobile" class="form-control" formControlName="amount" [ngClass]="{ 'is-invalid': submitted && f.amount.errors }">
                    <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
                      <div *ngIf="f.amount.errors.required">amount is required</div>
                    </div>
                  </div>                    
                </div><!-- end col -->
                
            </div><!-- end row --> 
              <h4 class="header-title m-t-0 m-b-30" *ngIf="invoices && invoices.length">Invoices</h4>       
              <div class="row" *ngIf="invoices && invoices.length">
                <table class="table">
                  <thead>
                    <tr>
                        <th>#</th>
                        <th>Invoice</th>
                        <th>Payable Amount</th>
                        <th>Current Due</th>
                        <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of invoices; let i = index">
                        <th scope="row">#{{i + 1}}</th>
                        <td>{{item.invoice_number}}</td>
                        <td>{{item.payable_amount}}</td>
                        <td>{{item.current_due_amount}}</td>
                        <td>{{item.date_added}}</td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
              <div class="row">
                <div class="col-xl-4">
                  <div class="form-group text-right m-b-0">
                    <button class="btn btn-primary waves-effect waves-light" (click)="onSubmit()">
                        Submit
                    </button>
                    <!-- <button type="reset" class="btn btn-secondary waves-effect waves-light m-l-5" (click)="onReset()">
                        Cancel
                    </button> -->
                  </div>   
                </div>               
              </div><!-- end row -->
            </form>
          </div>
        </div><!-- end col -->
        <div class="col-sm-3"></div>
      </div>
      <!-- end row -->
  </div>
</div>