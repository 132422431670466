import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../api/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-view-customer',
  templateUrl: './view-customer.component.html',
  styleUrls: ['./view-customer.component.css'],
  providers: [DatePipe]
})
export class ViewCustomerComponent implements OnInit {

  token: any;
  credentialsForm: FormGroup;
  submitted = false;
  myDate: any;
  id: any;
  customer: any;
  constructor(private formBuilder: FormBuilder, private _data: DataService, private _router: Router, private _actrouter: ActivatedRoute, private datePipe: DatePipe) { 
    _data.authToken$.subscribe(data => {
      this.token = data;
      if(this.token == null) {
        this._router.navigate(['/login']);
      }      
    });

    this._actrouter.paramMap.subscribe(params => {
      this.id = params.get('id');
      // console.log(this.slug);
      if( this.id ) {                
        this.getCustomer(this.id);
      }
      else {
        this._router.navigate(['/all-customer']);
      }
    });
    
  }

  ngOnInit(): void {
    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.credentialsForm = this.formBuilder.group({
      customer_name: ['', Validators.required],
      address: [''],
      mobile: ['', Validators.required],
      date_added: [this.myDate, Validators.required],
      opening_balance: ['', Validators.required],
      field_normal: ['280'],
      field_normal_extra_charge: [''],
      field_normal_minimum_charge: ['70'],
      field_dc: ['425'],
      field_dc_extra_charge: [''],
      field_dc_minimum_charge: ['80'],
      field_wax: ['600'],
      field_wax_extra_charge: [''],
      field_wax_minimum_charge: ['100'],
      field_yellow: ['350'],
      field_yellow_extra_charge: [''],
      field_yellow_minimum_charge: ['70'],
      email: ['', [Validators.required, Validators.email]],

      total_normal_cam:[''],
      total_packing_charge:[''],
      total_invoice:[''],
      total_dc_cam:[''],
      total_discount:[''],
      total_invoice_value:[''],
      total_wax_cam:[''],
      total_payment:[''],
      last_bill_date:[''],
      total_yellow_cam:[''],
      total_current_due:[''],
      last_bill_no:[''],
    });
  }

  get f() { return this.credentialsForm.controls; }

  getCustomer(id) {
    this.submitted = true;
    this._data.fetchSingleCustomer(this.token, id)
    .subscribe(  
        result => {
          // console.log(result); 
          this.submitted = false;

          if(result) {
            if(0 in result) {              
              var data = result[0]
              this.customer = data
              this.credentialsForm.get('customer_name').setValue(data['customer_name']);
              this.credentialsForm.get('address').setValue(data['address']);
              this.credentialsForm.get('mobile').setValue(data['mobile']);
              this.credentialsForm.get('date_added').setValue(data['date_added']);
              this.credentialsForm.get('opening_balance').setValue(data['opening_balance']);
              this.credentialsForm.get('field_normal').setValue(data['field_normal'] > 0 ? data['field_normal'] : '');
              this.credentialsForm.get('field_normal_extra_charge').setValue(data['field_normal_extra_charge'] > 0 ? data['field_normal_extra_charge'] : '');
              this.credentialsForm.get('field_normal_minimum_charge').setValue(data['field_normal_minimum_charge'] > 0 ? data['field_normal_minimum_charge'] : '');
              this.credentialsForm.get('field_dc').setValue(data['field_dc'] > 0 ? data['field_dc'] : '');
              this.credentialsForm.get('field_dc_extra_charge').setValue(data['field_dc_extra_charge'] > 0 ? data['field_dc_extra_charge'] : '');
              this.credentialsForm.get('field_dc_minimum_charge').setValue(data['field_dc_minimum_charge'] > 0 ? data['field_dc_minimum_charge'] : '');
              this.credentialsForm.get('field_wax').setValue(data['field_wax'] > 0 ? data['field_wax'] : '');
              this.credentialsForm.get('field_wax_extra_charge').setValue(data['field_wax_extra_charge'] > 0 ? data['field_wax_extra_charge'] : '');
              this.credentialsForm.get('field_wax_minimum_charge').setValue(data['field_wax_minimum_charge'] > 0 ? data['field_wax_minimum_charge'] : '');
              this.credentialsForm.get('field_yellow').setValue(data['field_yellow'] > 0 ? data['field_yellow'] : '');
              this.credentialsForm.get('field_yellow_extra_charge').setValue(data['field_yellow_extra_charge'] > 0 ? data['field_yellow_extra_charge'] : '');
              this.credentialsForm.get('field_yellow_minimum_charge').setValue(data['field_yellow_minimum_charge'] > 0 ? data['field_yellow_minimum_charge'] : '');
              this.credentialsForm.get('email').setValue(data['email']);


              var invo = data['invoices'];
              var _total_normal_cam = 0;
              var _total_packing_charge = 0;
              var _total_invoice = 0;
              var _total_dc_cam = 0;
              var _total_discount = 0;
              var _total_invoice_value = 0;
              var _total_wax_cam = 0;
              var _total_payment = 0;
              var _last_bill_date = 0;
              var _total_yellow_cam = 0;
              var _total_current_due = 0;
              var _last_bill_no = '#';
              if(invo && invo.length) {
                _total_invoice = invo.length
                console.log(invo)
                for (let index = 0; index < invo.length; index++) {
                  const element = invo[index];
                  _total_normal_cam += element.field_normal_weight * 1
                  _total_dc_cam += element.field_dc_weight * 1
                  _total_wax_cam += element.field_wax_weight * 1
                  _total_yellow_cam += element.field_yellow_weight * 1
                  _total_packing_charge += element.shipping_charge * 1
                  _total_discount += element.discount * 1
                  _total_invoice_value += element.gross_total * 1
                  _total_payment += element.paid_amount * 1
                  _last_bill_date = element.date_added
                  _last_bill_no = '#'+element.invoice_number
                }
              }
              this.credentialsForm.get('total_normal_cam').setValue(_total_normal_cam.toFixed(2));
              this.credentialsForm.get('total_packing_charge').setValue(_total_packing_charge.toFixed(2));
              this.credentialsForm.get('total_invoice').setValue(_total_invoice);
              this.credentialsForm.get('total_dc_cam').setValue(_total_dc_cam.toFixed(2));
              this.credentialsForm.get('total_discount').setValue(_total_discount.toFixed(2));
              this.credentialsForm.get('total_invoice_value').setValue(_total_invoice_value.toFixed(2));
              this.credentialsForm.get('total_wax_cam').setValue(_total_wax_cam.toFixed(2));
              this.credentialsForm.get('total_payment').setValue(_total_payment.toFixed(2));
              this.credentialsForm.get('last_bill_date').setValue(_last_bill_date);
              this.credentialsForm.get('total_yellow_cam').setValue(_total_yellow_cam.toFixed(2));
              this.credentialsForm.get('total_current_due').setValue(data['current_balance']);
              this.credentialsForm.get('last_bill_no').setValue(_last_bill_no);


            }
          }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }

  
}
