import { Component, OnInit } from '@angular/core';
import { DataService } from '../../api/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-edited-invoice',
  templateUrl: './edited-invoice.component.html',
  styleUrls: ['./edited-invoice.component.css']
})
export class EditedInvoiceComponent implements OnInit {

  token: any;
  submitted = false;
  id: any;
  dataArray: any;
  credentialsForm: FormGroup;
  users: any

  constructor(private formBuilder: FormBuilder, private _data: DataService, private _router: Router, private _actrouter: ActivatedRoute) { 
    _data.authToken$.subscribe(data => {
      this.token = data;
      if(this.token == null) {
        this._router.navigate(['/login']);
      }      
    });
  }

  ngOnInit(): void {
    this.onSubmit()
    this.credentialsForm = this.formBuilder.group({
      user_id: [''],
      date_from: [''],
      date_to: [''],
      customer_id: [''],
      invoice_id: ['']
    })
    this.getData()
  }
  get f() { return this.credentialsForm.controls; }
  
  getData() {
    this.submitted = true;
    this._data.fetchEditedInvoiceActivityLog(this.token, this.credentialsForm.value)
    .subscribe(  
        result => {
          console.log(result); 
          this.submitted = false;

          if(result) {
            this.dataArray = result;
          }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }
  
  onSubmit() {
    this.submitted = true;
    
    this._data.fetchUsers(this.token)
    .subscribe(  
        result => {
          this.submitted = false;
          if(result) {
            if('status' in result && result['status']){ 
              if(result['status'] == "success") {
                this.users = result['result'];
                //console.log(this.users)
              }
              if(result['status'] == "fail") {
                this._data.showSuccess('warning', result['msg']);
              }
            }
          }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }

  onResetSearch() {
    this.credentialsForm.get('date_from').setValue('');
    this.credentialsForm.get('date_to').setValue('');
    this.credentialsForm.get('user_id').setValue('');
    this.credentialsForm.get('customer_id').setValue('');
    this.credentialsForm.get('invoice_id').setValue('');
    this.onSubmit()
  }

  setFrm(id, model) {
    if(model.indexOf('Invoice') > -1 || model.indexOf('Customer') > -1) {
      this.credentialsForm.reset();
      if(model.indexOf('Customer') > -1) {
        this.credentialsForm.get('customer_id').setValue(id);
        this.credentialsForm.get('invoice_id').setValue('');
      }
      if(model.indexOf('Invoice') > -1) {
        this.credentialsForm.get('invoice_id').setValue(id);
        this.credentialsForm.get('customer_id').setValue('');
      }

      this.credentialsForm.get('user_id').setValue('');
      this.credentialsForm.get('date_from').setValue('');
      this.credentialsForm.get('date_to').setValue('');
            
      this.getData()
    }
  }

}
