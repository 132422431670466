import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CreateInvoiceComponent } from './components/create-invoice/create-invoice.component';
import { AllInvoiceComponent } from './components/all-invoice/all-invoice.component';
import { AllCustomerComponent } from './components/all-customer/all-customer.component';
import { CreateCustomerComponent } from './components/create-customer/create-customer.component';
import { ViewCustomerComponent } from './components/view-customer/view-customer.component';
import { EditCustomerComponent } from './components/edit-customer/edit-customer.component';
import { ReportComponent } from './components/report/report.component';
import { ReminderComponent } from './components/reminder/reminder.component';
import { LoginComponent } from './components/login/login.component';
import { MakePaymentComponent } from './components/make-payment/make-payment.component';
import { UserComponent } from './components/user/user.component';
import { StatComponent } from './components/stat/stat.component';
import { EditedByComponent } from './components/edited-by/edited-by.component';
import { BusinessByComponent } from './components/business-by/business-by.component';
import { EditInvoiceComponent } from './components/edit-invoice/edit-invoice.component';
import { ViewInvoiceComponent } from './components/view-invoice/view-invoice.component';
import { PrintInvoiceComponent } from './components/print-invoice/print-invoice.component';
import { BusinessStatementComponent } from './components/business-statement/business-statement.component';
import { EditedInvoiceComponent } from './components/edited-invoice/edited-invoice.component';
import { PerticularInvoicePrintComponent } from './components/perticular-invoice-print/perticular-invoice-print.component';

const routes: Routes = [
  { path: 'dashboard', 
    component: DashboardComponent 
  },
  {
    path: 'create-invoice',
    component: CreateInvoiceComponent
  },
  {
    path: 'edit-invoice/:id',
    component: EditInvoiceComponent
  },
  {
    path: 'view-invoice/:id',
    component: ViewInvoiceComponent
  },
  {
    path: 'print-specific-invoice/:id',
    component: PerticularInvoicePrintComponent
  },
  { path: 'all-invoice',
    component: AllInvoiceComponent
  },
  { path: 'all-customer', 
    component: AllCustomerComponent 
  },
  {
    path: 'create-customer',
    component: CreateCustomerComponent
  },
  {
    path: 'edit-customer/:id',
    component: EditCustomerComponent
  },
  {
    path: 'view-customer/:id',
    component: ViewCustomerComponent
  },
  { path: 'report',
    component: ReportComponent
  },
  { path: 'reminder',
    component: ReminderComponent
  },
  { path: 'make-payment',
    component: MakePaymentComponent
  },
  { path: 'login',
    component: LoginComponent
  },
  { path: 'user',
    component: UserComponent
  },
  { path: 'user/:id',
    component: UserComponent
  },
  { path: 'stat',
    component: StatComponent
  },
  { path: 'edited-by',
    component: EditedByComponent
  },
  { path: 'business-by',
    component: BusinessByComponent
  },
  { path: 'business-statement',
    component: BusinessStatementComponent
  },
  { path: 'print-report',
    component: PrintInvoiceComponent
  },
  { path: 'edited-invoice',
    component: EditedInvoiceComponent
  },
  {path: '', redirectTo: '/login', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
