
<div class="wrapper">
  <div class="container-fluid">

      <!-- Page-Title -->
      <div class="row">
          <div class="col-sm-12">
              
              <h4 class="page-title">Acivity Log</h4>
          </div>
      </div>
      <!-- end page title end breadcrumb -->

        <div class="row">
            <div class="col-sm-12">
                <div class="card-box"> 
                    <form [formGroup]="credentialsForm">
                        <div class="row">
                            <div class="col-xl-3">
                                <div class="form-group">
                                    <label> From Date</label>
                                    <div class="input-group">
                                        <input type="date" formControlName="date_from" class="form-control">
                                    </div>
                                </div>                            
                            </div>
                            <div class="col-xl-3">
                                <div class="form-group">
                                    <label >To Date</label>
                                    <div class="input-group">
                                        <input type="date" formControlName="date_to" class="form-control">
                                    </div>
                                </div>                            
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label >Search By Customer Name*</label>

                                    <div class="input-group">
                                        <select formControlName="user_id" [ngClass]="{ 'is-invalid': submitted && f.user_id.errors }" class="form-control">
                                            <option value="">Select</option>
                                            <option *ngFor="let u of users; let i = index" value="{{u.id}}">{{u.email}}</option>
                                        </select>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary waves-effect waves-light" (click)="getData()">
                                                Submit
                                            </button>
                                            <button class="btn btn-warning waves-effect waves-light" (click)="onResetSearch()">
                                                Reset
                                            </button>
                                        </div>
                                        <div *ngIf="submitted && f.user_id.errors" class="invalid-feedback">
                                            <div *ngIf="f.user_id.errors.required">Customer Name is required</div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

      <div class="row">
          <div class="col-lg-12">
              <div class="card-box">
                  <!-- <h4 class="m-t-0 header-title">Basic example</h4> -->
                  <table class="table">
                      <thead>
                      <tr>
                          <th>#</th>
                          <th>Activity</th>
                          <th>Details</th>
                          <th>On</th>
                          <th>By</th>
                      </tr>
                      </thead>
                      <tbody>
                            <tr *ngFor="let item of dataArray; let i = index">
                                <td scope="row">#{{item.id}}</td>
                                <td>
                                    {{item.description}} - <a (click)="setFrm(item.commentable.id, item.commentable_type);" style="cursor: pointer;">Check</a>
                                </td>
                                <td>
                                    <span *ngIf="item.commentable_type.indexOf('Invoice') > -1">
                                        Invoice: #{{item.commentable ? item.commentable.invoice_number : null}} <br>
                                        Gross Amount: {{item.commentable ? item.commentable.gross_total : null}} <br>
                                        Paid Amount: {{item.commentable ? item.commentable.paid_amount : null}}
                                    </span>
                                    <span *ngIf="item.commentable_type.indexOf('Customer') > -1">
                                        Customer: {{item.commentable ? item.commentable.id+'_'+item.commentable.customer_name+'_'+item.commentable.mobile : null}} <br>
                                        Balane: {{item.commentable ? item.commentable.current_balance : null}}
                                    </span>
                                </td>
                                <td>{{item.created_at | date:'dd/MM/yyyy h:mm a'}}</td>
                                <td>{{ item.user ? item.user.email : null }}</td>
                            </tr>
                      </tbody>
                  </table>
              </div>

          </div>
      </div>
  </div>
</div>