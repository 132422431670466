import { Component, OnInit } from '@angular/core';
import {filter} from 'rxjs/operators';
import { DataService } from '../../api/data.service';
import { Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  currentUrl: any;
  showMenu: boolean = false;
  hideHeader: boolean = false;
  _currentUser: any
  chngePwdForm: FormGroup;
  submitted = false;
  token: any;

  constructor(private formBuilder: FormBuilder, private router: Router, private _data: DataService) { 
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
      )
      .subscribe(event => {
        this.currentUrl = event
        if(this.currentUrl.url == '/print-report' || this.currentUrl.url == '/login' || this.currentUrl.url == '/' ||  this.currentUrl.url.indexOf("print-specific-invoice") > -1) this.hideHeader = true
        else this.hideHeader = false
      });
    _data.authUser$.subscribe(data => {
      this._currentUser = data;  
      // console.log(this._currentUser)         
    });
    _data.authToken$.subscribe(data => {
      this.token = data;
      if(this.token == null) {
        this.router.navigate(['/login']);
      }      
    });
  }

  ngOnInit(): void {
    this.chngePwdForm = this.formBuilder.group({
      old_password: ['', Validators.required],
      new_password: ['', Validators.required],
      retype_password: ['', Validators.required]
    });
  }

  get f() { return this.chngePwdForm.controls; }

  toggleMenu() {
    if(this.showMenu) this.showMenu = false
    else this.showMenu = true
  }

  logout() {
    this._data.removeAuth();
    this._data.showSuccess('warning', 'Logged Out Successfully.');
    this.router.navigate(['/login']);
  }


  onSubmit() {
    this.submitted = true;

    //this.chngePwdForm.reset(); 
    //return;

    // console.log(this.credentialsForm.value);
    // return;
    // stop here if form is invalid
    if (this.chngePwdForm.invalid) {
        return;
    }
    // console.log(this.credentialsForm.value)
    // return;

    if(this.chngePwdForm.value.new_password != this.chngePwdForm.value.retype_password ) {
      this._data.showSuccess('warning', 'Confirm password not match');
      return;
    }

    this._data.changePassword(this.chngePwdForm.value, this.token )
    .subscribe(  
        result => {
          console.log(result); 
          this.submitted = false;
          if(result) {
            if('status' in result) {
              if(result['status'] == 'failed') {
                this._data.showSuccess('error', result['msg']);
              }
              else if(result['status'] == 'success') {

                this._data.setApiToken(result['api_key']);
                this._data.setUserData(result['user']);
                this._data.showSuccess('warning', result['msg']);
                this.chngePwdForm.reset(); 

              }
            }
          }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );

  }

}
