import { Component, OnInit } from '@angular/core';
import { DataService } from '../../api/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  users: any
  token: any;
  submitted = false;
  credentialsForm: FormGroup;
  id: any;
  user: any;
  title: string = 'Add User';
  editPassword: boolean = true;

  constructor(private formBuilder: FormBuilder, private _data: DataService, private _router: Router, private _actrouter: ActivatedRoute) { 
    _data.authToken$.subscribe(data => {
      this.token = data;
      if(this.token == null) {
        this._router.navigate(['/login']);
      }      
    });
    this._actrouter.paramMap.subscribe(params => {
      this.id = params.get('id');
      if( this.id ) {    
        this.editPassword = false            
        this.fetchUser(this.id);
      }
    });
  }

  ngOnInit(): void {
    this.onSubmit();

    this.credentialsForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: [''],
      editPassword: ['0'],
      change_password: [''],
      active: ['1']
    }); 

  }

  get f() { return this.credentialsForm.controls; }

  editRate(v) {
    this.credentialsForm.get('password').setValue("");
    this.editPassword = false
    if(v) {
      this.editPassword = true
    }
  }
  
  onSubmit() {
    this.submitted = true;
    
    this._data.fetchUsers(this.token)
    .subscribe(  
        result => {
          this.submitted = false;
          if(result) {
            if('status' in result && result['status']){ 
              if(result['status'] == "success") {
                this.users = result['result'];
              }
              if(result['status'] == "fail") {
                this._data.showSuccess('warning', result['msg']);
              }
            }
          }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }

  fetchUser(id) {
    this.submitted = true;
    this._data.fetchSingleUser(this.token, id)
    .subscribe(  
        result => {
          // console.log(result); 
          this.submitted = false;

          if(result) {
            if(0 in result) {              
              var data = result[0]
              this.credentialsForm.get('email').setValue(data['email']);
              this.credentialsForm.get('active').setValue(data['active']);
              // console.log(data['active'])
            }
          }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }

  saveUser() {
    this.submitted = true;
    // console.log(this.credentialsForm.value);
    // return;
    // stop here if form is invalid


    if (this.credentialsForm.invalid) {
        return;
    }

    if(this.id == null) {
      var p = this.credentialsForm.value.password
      console.log(p)
      if(p == undefined || p.trim() == '') {
        this._data.showSuccess('error', 'Password required');
        return
      }
    }
    // console.log(this.credentialsForm.value)
    // return;
    this._data.AddEditUser(this.credentialsForm.value, this.token, this.id)
    .subscribe(  
        result => {
          //console.log(result); 
          this.submitted = false;
          if(result) {
            if('status' in result && result['status']){ 
              if(result['status'] == "success") {
                this._data.showSuccess('success', result['msg']);
                if(this.id) {}
                else {
                  this.credentialsForm.reset();
                }
                this.onSubmit();
              }
              if(result['status'] == "fail") {
                this._data.showSuccess('warning', result['msg']);
              }
            }
          }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }

}
