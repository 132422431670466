
<div class="wrapper">
  <div class="container-fluid">

      <!-- Page-Title -->
      <div class="row">
          <div class="col-sm-12">
              
              <h4 class="page-title">Payment Received ({{dataArray ? dataArray.length : 0}})</h4>
          </div>
      </div>
      <!-- end page title end breadcrumb -->

      <div class="row">
        <div class="col-sm-12">
            <div class="card-box"> 
                <form [formGroup]="credentialsForm">
                    <div class="row">
                        <div class="col-xl-3">
                            <div class="form-group">
                                <label> From Date</label>
                                <div class="input-group">
                                    <input type="date" formControlName="date_from" class="form-control">
                                </div>
                            </div>                            
                        </div>
                        <div class="col-xl-3">
                            <div class="form-group">
                                <label >To Date</label>
                                <div class="input-group">
                                    <input type="date" formControlName="date_to" class="form-control">
                                </div>
                            </div>                            
                        </div>
                        <div class="col-xl-2">
                            <div class="form-group">
                                <label >Payment method*</label>
                                <select class="form-control" formControlName="payment_method">
                                    <option value="">Select</option>
                                    <option value="Cash">Cash</option>
                                    <option value="Check">Check</option>
                                    <option value="Online">Online</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xl-4">
                            <div class="form-group">
                                <label >Search By User*</label>

                                <div class="input-group">
                                    <select formControlName="user_id" [ngClass]="{ 'is-invalid': submitted && f.user_id.errors }" class="form-control">
                                        <option value="">Select</option>
                                        <option *ngFor="let u of users; let i = index" value="{{u.id}}">{{u.email}}</option>
                                    </select>
                                    <div class="input-group-append">
                                        <button class="btn btn-primary waves-effect waves-light" (click)="getData()">
                                            Submit
                                        </button>
                                        <button class="btn btn-warning waves-effect waves-light" (click)="onResetSearch()">
                                            Reset
                                        </button>
                                    </div>
                                    <div *ngIf="submitted && f.user_id.errors" class="invalid-feedback">
                                        <div *ngIf="f.user_id.errors.required">Customer Name is required</div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

      <div class="row">
          <div class="col-lg-12">
              <div class="card-box">
                  <!-- <h4 class="m-t-0 header-title">Basic example</h4> -->
                  <table class="table">
                      <thead>
                      <tr>
                          <th>#</th>
                          <th>Invoice</th>
                          <!-- <th>type</th> -->
                          <th>Amount (Rs. {{this.totalAmount}})</th>
                          <th>Method</th>
                          <th>On</th>
                          <th>Customer</th>
                          <th>By</th>
                      </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let item of dataArray; let i = index">
                              <td scope="row">#{{item.id}}</td>
                              <td>
                                  {{ item.invoice_number }}
                              </td>
                              <td>{{ item.paid_amount }}</td>
                              <td>{{ item.payment_method }}</td>
                              <td>{{item.created_at | date:'dd/MM/yyyy h:mm a'}}</td>
                              <td>{{ item.customer ? item.customer.customer_name : null }}</td>
                              <td>{{ item.user ? item.user.email : null }}</td>
                              <!-- <td>
                                  {{ item.commentable ? item.commentable.invoice_number : null }}
                              </td>
                              <td>
                                  {{item.description}}
                              </td>
                              <td>{{ item.commentable ? item.commentable.paid_amount : null }}</td>
                              <td>{{ item.commentable ? item.commentable.payment_method : null }}</td>
                              <td>{{item.created_at | date:'d/M/Y h:mm a'}}</td>
                              <td>{{ item.user ? item.user.email : null }}</td> -->
                          </tr>
                      </tbody>
                  </table>
              </div>

          </div>
      </div>
  </div>
</div>