<!-- Footer -->
<footer class="footer">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                2022 © RR3D by <a href="https://www.eresource.in/" target="_blank[]">E-Resource</a>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer -->