import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private REST_API_SERVER_URL = "https://rr3dprinterbill.co.in/admin/index.php/";
  //private REST_API_SERVER_URL = "https://al3dprinterbill.in/admin/";
  // private REST_API_SERVER_URL = "https://rr3dprinterbill.co.in/admin/index.php/";
  // private REST_API_SERVER_URL = "http://localhost:8000/";
  private REST_API_VERSION = "api/";
  private REST_API_SERVER: string; 

  private authUser = new BehaviorSubject<any>(null);
  public authUser$ = this.authUser.asObservable();
  
  private authToken = new BehaviorSubject<any>(null);
  public authToken$ = this.authToken.asObservable();
  
  private invoiceData = new BehaviorSubject<any>(null);
  public invoiceData$ = this.invoiceData.asObservable();
  private invoiceCustomer = new BehaviorSubject<any>(null);
  public invoiceCustomer$ = this.invoiceCustomer.asObservable();
  private invoiceDataHeading = new BehaviorSubject<any>(null);
  public invoiceDataHeading$ = this.invoiceDataHeading.asObservable();

  public printableData = {
    customer: [],
    invoice: []
  }
  
  loggedInUser = {};
  loggedInUserToken: string = null;
  invoiceDataV = [];
  invoiceCustomerV = {};
  InHeading: string = null;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'X-CSRF-TOKEN': 'tokenvaluetobeinserted235kwgeiOIulgsk',
      // 'strictSSL': false
          // 'Content-Type':  'application/json'
    })
  };

  constructor(private httpClient: HttpClient) { 
    this.REST_API_SERVER = this.REST_API_SERVER_URL+this.REST_API_VERSION;
    //localStorage.removeItem('auth-token');
    //localStorage.removeItem('auth-user');

    if (localStorage.getItem("auth-user") === null || localStorage.getItem("auth-user") === undefined) { }
    else {
      this.loggedInUser = JSON.parse(localStorage.getItem('auth-user'));
    }    
    this.authUser.next(this.loggedInUser);
    
    if (localStorage.getItem("auth-token") === null || localStorage.getItem("auth-token") === undefined) { }
    else {
      this.loggedInUserToken = localStorage.getItem('auth-token');
    }    
    this.authToken.next(this.loggedInUserToken);
    
    if (localStorage.getItem("invoice-data") === null || localStorage.getItem("invoice-data") === undefined) { }
    else {
      this.invoiceDataV = JSON.parse(localStorage.getItem('invoice-data'));
    }    
    this.invoiceData.next(this.invoiceDataV);

    if (localStorage.getItem("invoice-customer") === null || localStorage.getItem("invoice-customer") === undefined) { }
    else {
      this.invoiceCustomerV = JSON.parse(localStorage.getItem('invoice-customer'));
    }    
    this.invoiceCustomer.next(this.invoiceCustomerV);

    if (localStorage.getItem("invoice-heading") === null || localStorage.getItem("invoice-heading") === undefined) { }
    else {
      this.InHeading = localStorage.getItem('invoice-heading');
    }    
    this.invoiceDataHeading.next(this.InHeading);
  }

  private handleError(error: HttpErrorResponse) { 
		var msg: any = 'Something bad happened; please try again later.';		
	  if (error.error instanceof ErrorEvent) {
	    msg = error.message ? error.message : error.error.message;
	  } else {
	    msg = error.statusText;
	  }
	  return throwError(msg);
	}

  public postLogin(request_data){     

    return this.httpClient.post(this.REST_API_SERVER+'login', request_data, this.httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }
  
  public setApiToken(data) { 
    localStorage.setItem('auth-token', data);
    this.loggedInUserToken = data;
    this.authToken.next(data);
  }

  public setUserData(data) { 
    // console.log(data);
    localStorage.setItem('auth-user', JSON.stringify(data));
    this.loggedInUser = data;
    this.authUser.next(data);
  }

  public setPrintData(data) { 
    // console.log(data);
    localStorage.setItem('invoice-data', JSON.stringify(data));
    this.invoiceDataV = data;
    this.invoiceData.next(data);
  }

  public setPrintDataCustomer(data) { 
    // console.log(data);
    localStorage.setItem('invoice-customer', JSON.stringify(data));
    this.invoiceCustomerV = data;
    this.invoiceCustomer.next(data);
  }

  public setPrintDataHeading(data) { 
    // console.log(data);
    localStorage.setItem('invoice-heading', data);
    this.InHeading = data;
    this.invoiceDataHeading.next(data);
  }
  
  public removeAuth() { 
    var data = {};
    var dataString = null;
    localStorage.removeItem('auth-token');
    localStorage.removeItem('auth-user');
    this.authToken.next(dataString);
    this.authUser.next(data);
  }

  public createCustomer(request_data, key, id){    
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    if(id) {
      return this.httpClient.put(this.REST_API_SERVER+'customer/'+id, request_data, httpOptions) 
      .pipe(
        //retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
    }
    else {
      return this.httpClient.post(this.REST_API_SERVER+'customer', request_data, httpOptions) 
      .pipe(
        //retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
    }
    
  }
  
  public updateCustomer( key, id){  
    var request_data = {}  
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    return this.httpClient.put(this.REST_API_SERVER+'customer/update/'+id, request_data, httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
    
  }

  public fetchCustomerToCreateInvoice(key){    
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    return this.httpClient.get(this.REST_API_SERVER+'customer-to-create-invoice', httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }
  public fetchCustomer(key){    
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    return this.httpClient.get(this.REST_API_SERVER+'customer', httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }
  public fetchLatestCustomer(key){    
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    return this.httpClient.get(this.REST_API_SERVER+'latest-customer', httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }

  public searchCustomer(key, customer){    
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    return this.httpClient.get(this.REST_API_SERVER+'customer?customer_name='+customer, httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }
  
  public fetchCustomerWithInvoice(key){    
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    return this.httpClient.get(this.REST_API_SERVER+'customer/with/invoice', httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }

  public fetchSingleCustomer(key, id){    
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    return this.httpClient.get(this.REST_API_SERVER+'customer/'+id, httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }
  
  public AddEditInvoice(request_data, key, id){    
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    if(id) {
      return this.httpClient.put(this.REST_API_SERVER+'invoice/'+id, request_data, httpOptions) 
      .pipe(
        //retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
    }
    else {
      return this.httpClient.post(this.REST_API_SERVER+'invoice', request_data, httpOptions) 
      .pipe(
        //retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
    }
    
  }
  
  public updateInvoice( key, id){  
    var request_data = {}  
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    return this.httpClient.put(this.REST_API_SERVER+'invoice/update/'+id, request_data, httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
    
  }

  public fetchLatestInvoice(key){    
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    return this.httpClient.get(this.REST_API_SERVER+'latest-invoice', httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }

  public fetchSortInvoice(key,type){    
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    return this.httpClient.get(this.REST_API_SERVER+'sort-invoice/'+type, httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }

  public fetchPaginateInvoice(key, url){    
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    return this.httpClient.get(this.REST_API_SERVER+'latest-invoice?'+url, httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }

  public fetchInvoice(key){    
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    return this.httpClient.get(this.REST_API_SERVER+'invoice', httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }

  public searchInvoice(key, customer){    
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    return this.httpClient.get(this.REST_API_SERVER+'latest-invoice?customer_name='+customer, httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }

  public fetchSingleInvoice(key, id){    
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    return this.httpClient.get(this.REST_API_SERVER+'invoice/'+id, httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }
  
  public checkPayment(request_data, key){  
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    return this.httpClient.post(this.REST_API_SERVER+'make-payment', request_data, httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
    
  }

  public fetchUsers(key){    
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    return this.httpClient.get(this.REST_API_SERVER+'user', httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }

  public fetchSingleUser(key, id){    
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    return this.httpClient.get(this.REST_API_SERVER+'user/'+id, httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }
  
  public AddEditUser(request_data, key, id){    
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    if(id) {
      return this.httpClient.put(this.REST_API_SERVER+'user/'+id, request_data, httpOptions) 
      .pipe(
        //retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
    }
    else {
      return this.httpClient.post(this.REST_API_SERVER+'user', request_data, httpOptions) 
      .pipe(
        //retry(3), // retry a failed request up to 3 times
        catchError(this.handleError)
      );
    }
    
  }

  public fetchActivityLog(key, form){    
    
    const httpParams: HttpParamsOptions = { fromObject: form } as HttpParamsOptions;

    var httpOptions = {
      params: new HttpParams(httpParams),
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 
    var url = this.REST_API_SERVER+'activity-log'

    return this.httpClient.get(url, httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }

  public fetchEditedInvoiceActivityLog(key, form){    
    
    const httpParams: HttpParamsOptions = { fromObject: form } as HttpParamsOptions;

    var httpOptions = {
      params: new HttpParams(httpParams),
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 
    var url = this.REST_API_SERVER+'edited-invoice'

    return this.httpClient.get(url, httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }

  public fetchPaymentReceivedActivityLog(key, form){  
    
    const httpParams: HttpParamsOptions = { fromObject: form } as HttpParamsOptions;

    var httpOptions = {
      params: new HttpParams(httpParams),
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 
    var url = this.REST_API_SERVER+'payment-activity'
    //url = url+form
    return this.httpClient.get(url, httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }
  
  public fetchPaymentStatement(key, form){  
    
    const httpParams: HttpParamsOptions = { fromObject: form } as HttpParamsOptions;

    var httpOptions = {
      params: new HttpParams(httpParams),
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 
    var url = this.REST_API_SERVER+'payment-statement'
    // url = url + form
    console.log(url)
    return this.httpClient.get(url, httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }

  public fetchDashboard(key){    
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    return this.httpClient.get(this.REST_API_SERVER+'dashboard', httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }
  
  public searchStatement(request_data, key){    
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    return this.httpClient.post(this.REST_API_SERVER+'search-statement', request_data, httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }
  
  public importCustomer(request_data, key){    
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    return this.httpClient.post(this.REST_API_SERVER+'upload-customer', {request_data: request_data}, httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }
  
  public sendMail(request_data, key){    
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    return this.httpClient.post(this.REST_API_SERVER+'send-mail', request_data, httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }
  
  public sendReminder(key, request_data){    
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 
    
    return this.httpClient.post(this.REST_API_SERVER+'send-reminder', request_data, httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
  }

  showSuccess(type, msg) {
    alert(msg)
    // this.toastr[type](msg);
    // ToastrService.success/error/warning/info/show()
  }
  
  public changePassword( request_data,key){ 
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'api-token': key,
        //'strictSSL': false
            // 'Content-Type':  'application/json'
      })
    }; 

    return this.httpClient.post(this.REST_API_SERVER+'change-password', request_data, httpOptions) 
    .pipe(
      //retry(3), // retry a failed request up to 3 times
      catchError(this.handleError)
    );
    
  }

}
