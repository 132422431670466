
<div class="wrapper">
  <div class="container-fluid">

      <!-- Page-Title -->
      <div class="row">
          <div class="col-sm-12">
            <div class="btn-group pull-right m-t-20">
              <a class="btn btn-custom" [routerLink]="['/all-invoice']">All Invoices </a>              
            </div>
            <h4 class="page-title">View Invoice</h4>
          </div>
      </div>
      <!-- end page title end breadcrumb -->

      <div class="row">
        <div class="col-sm-12">
            <div class="card-box"> 
              <form [formGroup]="credentialsForm">
                <div class="row">
                  
                    <div class="col-xl-3">
                      <div class="form-group autocomplete">
                        <label >Customer Name*</label>
                        <label class="form-control" *ngIf="id">{{printedCustromerName}}</label>                        
                      </div>
                     
                      <div class="form-group">
                        <label >Email*</label>
                        <input type="email" placeholder="Enter Email" class="form-control" formControlName="email" readonly="">
                      </div>
                      <div class="form-group">
                        <label >Mobile*</label>
                        <input type="number" placeholder="Enter Mobile" class="form-control" formControlName="mobile"  readonly="">
                      </div>
                      
                    </div><!-- end col -->
                    <div class="col-xl-3">
                      <div class="form-group">
                        <label >Address</label>
                        <textarea class="form-control" readonly="" style="min-height: 122px !important;" formControlName="address" style="min-height: 204px !important;"></textarea>                        
                      </div>
                      
                    </div><!-- end col -->
                    <div class="col-xl-2">
                      <div class="form-group">
                        <label >Invoice Date*</label>
                        <input type="email" placeholder="Enter Invoice Date" class="form-control" formControlName="date_added"  readonly="">
                      </div>
                      <div class="form-group">
                        <label >Invoice No*</label>
                        <input type="email" placeholder="Enter Invoice No" class="form-control" formControlName="invoiceNo"  readonly="">
                      </div>
                      <div class="form-group">
                        <label >Customer Invoice No*</label>
                        <input type="email" placeholder="Enter Customer Invoice No" class="form-control" formControlName="c_invoiceNo"  readonly="">
                      </div>
                      
                    </div><!-- end col -->
                    <div class="col-xl-4">
                      <div class="form-group">
                        <label >Normal</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <select class="form-control" formControlName="NormalType" style="background-color: #c0ffc0;"  readonly="">
                              <option value="Normal">Normal</option>
                              <option value="Normal-Extra">N Extra</option>
                              <option value="Normal-Minimum">N Minimum</option>
                            </select>
                          </div>
                          <input type="text" class="form-control" placeholder="Weight" aria-label="Weight" aria-describedby="basic-addon1"  formControlName="field_normal_weight"   readonly="">
                          <div class="input-group-append">
                            <span class="input-group-text" id="basic-addon1">*</span>
                          </div>
                          <input type="text" class="form-control" placeholder="Amount" aria-label="Amount" aria-describedby="basic-addon1"  formControlName="field_normal_amount" readonly="">
                        </div>
                      </div>                    
                    
                      <div class="form-group">
                        <label >DC</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <select class="form-control" formControlName='DcType'  style="background-color: #008000; color: white;"   readonly="">
                              <option value="Dc">Dc</option>
                              <option value="Dc-Extra">D Extra</option>
                              <option value="Dc-Minimum">D Minimum</option>
                            </select>
                          </div>
                          <input type="text" class="form-control" placeholder="Weight" aria-label="Weight" aria-describedby="basic-addon1" formControlName="field_dc_weight"  readonly="" >
                          <div class="input-group-append">
                            <span class="input-group-text" id="basic-addon1">*</span>
                          </div>
                          <input type="text" class="form-control" placeholder="Amount" aria-label="Amount" aria-describedby="basic-addon1"   formControlName="field_dc_amount" readonly="">
                        </div>
                      </div>       

                      <div class="form-group">
                        <label >WAX</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <select class="form-control" formControlName="WaxType"  style="background-color: #ff80ff;"   readonly="">
                              <option value="Wax">Wax</option>
                              <option value="Wax-Extra">W Extra</option>
                              <option value="Wax-Minimum">W Minimum</option>
                            </select>
                          </div>
                          <input type="text" class="form-control" placeholder="Weight" aria-label="Weight" aria-describedby="basic-addon1" formControlName="field_wax_weight"   readonly="">
                          <div class="input-group-append">
                            <span class="input-group-text" id="basic-addon1">*</span>
                          </div>
                          <input type="text" class="form-control" placeholder="Amount" aria-label="Amount" aria-describedby="basic-addon1"   formControlName="field_wax_amount" readonly="">
                        </div>
                      </div>
                      
                      <div class="form-group">
                        <label >Yellow</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <select class="form-control" formControlName="YellowType" style="background-color: #ffffc0;"  readonly="" >
                              <option value="Yellow">Yellow</option>
                              <option value="Yellow-Extra">Y Extra</option>
                              <option value="Yellow-Minimum">Y Minimum</option>
                            </select>
                          </div>
                          <input type="text" class="form-control" placeholder="Weight" aria-label="Weight" aria-describedby="basic-addon1" formControlName="field_yellow_weight"  readonly="" >
                          <div class="input-group-append">
                            <span class="input-group-text" id="basic-addon1">*</span>
                          </div>
                          <input type="text" class="form-control" aria-describedby="basic-addon1"   formControlName="field_yellow_amount" placeholder="Amount" readonly="">
                        </div>
                      </div> 

                      <!-- <div class="form-group">
                        <label >Total</label>
                        <input type="text" class="form-control" placeholder="Weight" aria-label="Weight" aria-describedby="basic-addon1" formControlName="total" [ngClass]="{ 'is-invalid': submitted && f.total.errors }">                          
                      </div>  -->

                    </div>
                    
                </div>
                <!-- <h4 class="header-title m-t-0 m-b-30">Other Details</h4> -->
                <!-- <div class="checkbox">
                  <input id="checkbox0" type="checkbox" formControlName="rateEdit" value="1" (change)="editRate();">
                  <label for="checkbox0">
                      Edit Rate only for this Bill
                  </label>
                </div> -->
                <div class="row">
                  
                  <div class="col-xl-3">
                    <div class="form-group">
                      <label >Normal</label>
                      <input type="text" placeholder="Enter Normal" class="form-control" readonly=""   formControlName="field_normal"  style="background-color: #c0ffc0;">
                    </div>
                    <div class="form-group">
                      <label >DC</label>
                      <input type="text" placeholder="Enter DC" class="form-control"  readonly=""   formControlName="field_dc" style="background-color: #008000; color: white;">
                    </div>
                    <div class="form-group">
                      <label >WAX</label>
                      <input type="text" placeholder="Enter WAX" class="form-control"  readonly=""    formControlName="field_wax" style="background-color: #ff80ff;">
                    </div>                   
                    <div class="form-group">
                      <label >Yellow</label>
                      <input type="text" placeholder="Enter Yellow" class="form-control"  readonly=""    formControlName="field_yellow"  style="background-color: #ffffc0;">
                    </div>
                    
                  </div>
                  <div class="col-xl-3">
                    <div class="form-group">
                      <label >Normal Extra Charge</label>
                      <input type="text" placeholder="Enter Normal Extra Charge" class="form-control"  readonly=""    formControlName="field_normal_extra_charge" style="background-color: #c0ffc0;">
                    </div>
                    <div class="form-group">
                      <label >DC Extra Charge</label>
                      <input type="text" placeholder="Enter DC Extra Charge" class="form-control"  readonly=""    formControlName="field_dc_extra_charge" style="background-color: #008000; color: white;">
                    </div>
                    <div class="form-group">
                      <label >WAX Extra Charge</label>
                      <input type="text" placeholder="Enter WAX Extra Charge" class="form-control"  readonly=""    formControlName="field_wax_extra_charge" style="background-color: #ff80ff;">                      
                    </div>
                    <div class="form-group">
                      <label >Yellow Extra Charge</label>
                      <input type="text" placeholder="Enter Yellow Extra Charge" class="form-control"  readonly=""    formControlName="field_yellow_extra_charge" style="background-color: #ffffc0;">
                    </div>
                  </div>
                  <div class="col-xl-3">
                    <div class="form-group">
                      <label >Normal Minimum*</label>
                      <input type="text" placeholder="Enter Normal Minimum" class="form-control"  readonly=""    formControlName="field_normal_minimum_charge"  style="background-color: #c0ffc0;">
                      
                    </div>
                    <div class="form-group">
                      <label >DC Minimum*</label>
                      <input type="text" placeholder="Enter DC Minimum" class="form-control"  readonly=""    formControlName="field_dc_minimum_charge"  style="background-color: #008000; color: white;">
                      
                    </div>
                    <div class="form-group">
                      <label >WAX Minimum*</label>
                      <input type="text" placeholder="Enter WAX Minimum" class="form-control"  readonly=""    formControlName="field_wax_minimum_charge"  style="background-color: #ff80ff;">
                      
                    </div> 
                    <div class="form-group">
                      <label >Yellow Minimum*</label>
                      <input type="text" placeholder="Enter Yellow Minimum" class="form-control"  readonly=""    formControlName="field_yellow_minimum_charge" style="background-color: #ffffc0;">
                      
                    </div>
                  </div>
                  <div class="col-xl-3">
                    <div class="form-group">
                      <label > Total</label>
                      <input type="text" placeholder="Amount" class="form-control"  readonly="" formControlName="gross_total">                      
                    </div>                
                    <div class="form-group">
                      <label >Shiping Charge</label>
                      <input type="text" placeholder="Enter Shiping Charge" class="form-control" formControlName="shipping_charge"  readonly="">                      
                    </div> 
                    <div class="form-group">
                      <label >Due/Return</label>
                      <input type="text" placeholder="Balance" class="form-control" formControlName="current_balance"  readonly="">
                    </div>
                    <div class="form-group">
                      <label >Gross Total</label>
                      <input type="text" placeholder="Gross Total" class="form-control" formControlName="payable_amount_without_discount"  readonly="">                      
                    </div>
                    
                    <div class="form-group">
                      <label >Discount</label>
                      <input type="text" placeholder="Enter Discount" class="form-control" formControlName="discount"  readonly="">                      
                    </div>
                    <!-- <div class="form-group">
                      <label >Payable</label>
                      <input type="text" placeholder="Net Total" class="form-control" formControlName="payable_amount"  readonly="">                      
                    </div>  -->
                    <div class="form-group">
                      <label >Payment</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <select class="form-control" formControlName="payment_method">
                            <option value="Cash">Cash</option>
                            <option value="Check">Check</option>
                            <option value="Online">Online</option>
                          </select>
                        </div>
                        <input type="text" class="form-control" placeholder="Paid Amount" aria-label="Weight" aria-describedby="basic-addon1"  formControlName="paid_amount" readonly="">
                      </div>
                    </div> 
                    <div class="form-group">
                      <label >Current Due / Return</label>
                      <input type="tex" placeholder="Amount" class="form-control" formControlName="balance_after_payment"  readonly="" >
                      
                    </div>
                    
                    <div class="form-group text-right m-b-0 m-t-30">
                      <!-- <a class="btn btn-primary waves-effect waves-light" [routerLink]="['/print-specific-invoice',id]" target="_blank[]">
                          Print
                      </a> -->


                      <!-- <a class="btn btn-primary waves-effect waves-light" target="_blank[]" href="https://al3dprinterbill.in/admin/api/pdf/{{id}}">
                        Print
                      </a> -->

                      <a class="btn btn-primary waves-effect waves-light" target="_blank[]" href="https://rr3dprinterbill.co.in/admin/index.php/api/pdf/{{id}}">
                        Print
                      </a>

                    </div>

                  </div>
                </div>  
              </form>
            </div>
          </div><!-- end col -->
      </div>
      <!-- end row -->
  </div>
</div>
