<!-- Navigation Bar-->
<header id="topnav" *ngIf="!hideHeader">
    <div class="topbar-main">
        <div class="container-fluid">

            <!-- Logo container-->
            <div class="logo">
                <!-- Text Logo -->
                <a href="#" class="logo">
                    <span class="logo-small"><i class="mdi mdi-radar"></i></span>
                    <span class="logo-large"><i class="mdi mdi-radar"></i> RR 3D PRINTER</span>
                </a>
            </div>
            <!-- End Logo container-->

            <div class="menu-extras topbar-custom">

                <ul class="list-unstyled topbar-right-menu float-right mb-0">

                    <li class="menu-item">
                        <!-- Mobile menu toggle-->
                        <a (click) ="toggleMenu()" [ngClass]="showMenu ? 'navbar-toggle nav-link open' : 'navbar-toggle nav-link'">
                            <div class="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </a>
                        <!-- End mobile menu toggle-->
                    </li>
                    <li class="dropdown notification-list">
                        <a class="nav-link dropdown-toggle waves-effect waves-light nav-user" data-toggle="dropdown" href="#" role="button"
                           aria-haspopup="false" aria-expanded="false">
                            <img src="assets/images/users/avatar-1.jpg" alt="user" class="rounded-circle">
                        </a>
                        <div class="dropdown-menu dropdown-menu-right profile-dropdown ">
                            <!-- item-->
                            <a (click)="logout()" class="dropdown-item notify-item">
                                <i class="ti-power-off m-r-5"></i> {{_currentUser ? '('+_currentUser.email+')' : null}}Logout
                            </a>
                        </div>
                    </li>

                </ul>
            </div>
            <!-- end menu-extras -->

            <div class="clearfix"></div>

        </div> <!-- end container -->
    </div>
    <!-- end topbar-main -->

    <div class="navbar-custom">
        <div class="container-fluid">
            <div id="navigation" [ngClass]="{'nv': showMenu === true}">
                <!-- Navigation Menu-->
                <ul class="navigation-menu">
                    <li class="has-submenu">
                        <a (click)="toggleMenu()" routerLink="dashboard"><i class="ti-dashboard"></i> <span> Dashboard </span> </a>
                    </li>
                    <li class="has-submenu" *ngIf="_currentUser && _currentUser.role_id == 1">
                        <a (click)="toggleMenu()" routerLink="stat"><i class="ti-bar-chart"></i> <span> Statistics </span> </a>
                    </li>
                    <li class="has-submenu">
                        <a ><i class="ti-user"></i><span> Customer </span> </a>
                        <ul class="submenu">
                            <li><a (click)="toggleMenu()" routerLink="all-customer">All Customer</a></li>
                            <li><a (click)="toggleMenu()" routerLink="create-customer">Create Customer</a></li>
                        </ul>
                    </li>
                    <li class="has-submenu">
                        <a ><i class="ti-file"></i><span> Invoice </span> </a>
                        <ul class="submenu">
                            <li><a (click)="toggleMenu()" routerLink="all-invoice">All Invoice</a></li>
                            <li><a (click)="toggleMenu()" routerLink="create-invoice">Create Invoice</a></li>
                            <!-- <li><a (click)="toggleMenu()"  routerLink="make-payment"> Make Payment  </a></li> -->
                        </ul>
                    </li>
                    <li class="has-submenu">
                        
                    </li>
                    <li class="has-submenu">
                        <a (click)="toggleMenu()" routerLink="report"><i class="ti-clipboard"></i> <span> Reports </span> </a>
                    </li>
                    <li class="has-submenu">
                        <a (click)="toggleMenu()" routerLink="reminder"><i class="ti-email"></i> <span> Reminder Alert </span> </a>
                    </li>
                    <li class="has-submenu" *ngIf="_currentUser && _currentUser.role_id == 1">
                        <a ><i class="ti-user"></i><span> Others </span> </a>
                        <ul class="submenu">
                            <li><a (click)="toggleMenu()" routerLink="user">Users </a></li>
                            <li><a (click)="toggleMenu()" routerLink="business-statement">Business Statement</a></li>
                            <li><a (click)="toggleMenu()" routerLink="business-by">Business By</a></li>
                            <li><a (click)="toggleMenu()" routerLink="edited-invoice">Edited Invoice</a></li>
                            <!-- <li><a (click)="toggleMenu()" routerLink="edited-by">History</a></li> -->
                        </ul>
                    </li>

                    <li class="has-submenu">
                        <a data-toggle="modal" data-target="#chngePwdModal"><span>Change Password</span> </a>
                    </li>
                </ul>
                <!-- End navigation menu -->
            </div> <!-- end #navigation -->
        </div> <!-- end container -->
    </div> <!-- end navbar-custom -->
</header>
<!-- End Navigation Bar-->

<!-- Modal -->
<div id="chngePwdModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
  
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body">
            
            <form [formGroup]="chngePwdForm">
                <div class="row">
                  
                    <div class="col-xl-12">
                        <h3>Change Password</h3>
                        <div class="form-group">
                            <label >Old Password</label>
                            <input type="password" placeholder="Enter Old Password" class="form-control" formControlName="old_password" [ngClass]="{ 'is-invalid': submitted && f.old_password.errors }" >
                            <div *ngIf="submitted && f.old_password.errors" class="invalid-feedback">
                                <div *ngIf="f.old_password.errors.required">old password is required</div>
                            </div>
                        </div>
                     
                        <div class="form-group">
                            <label >New Password</label>
                            <input type="password" placeholder="Enter Old Password" class="form-control" formControlName="new_password" [ngClass]="{ 'is-invalid': submitted && f.new_password.errors }" >
                            <div *ngIf="submitted && f.new_password.errors" class="invalid-feedback">
                                <div *ngIf="f.new_password.errors.required">New password is required</div>
                            </div>
                        </div>
                     
                        <div class="form-group">
                            <label >Re-Enter Password</label>
                            <input type="password" placeholder="Enter Old Password" class="form-control" formControlName="retype_password" [ngClass]="{ 'is-invalid': submitted && f.retype_password.errors }" >
                            <div *ngIf="submitted && f.retype_password.errors" class="invalid-feedback">
                                <div *ngIf="f.retype_password.errors.required">Confirm password is required</div>
                            </div>
                        </div>

                        <div class="form-group text-right m-b-0 m-t-30">
                            <button class="btn btn-success waves-effect waves-light" (click)="onSubmit()">
                                Change
                            </button>
                            <button class="btn btn-default waves-effect waves-light"  data-dismiss="modal">
                                Close
                            </button>
                        </div>

                    </div>
                </div>
            </form>

        </div>
        
      </div>
  
    </div>
  </div>