import { Component, OnInit } from '@angular/core';
import { DataService } from '../../api/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.css'],
  providers: [DatePipe]
})
export class MakePaymentComponent implements OnInit {

  token: any;
  credentialsForm: FormGroup;
  submitted = false;
  myDate: any;
  id: any;
  customers = [];
  invoices = [];

  constructor(private formBuilder: FormBuilder, private _data: DataService, private _router: Router, private _actrouter: ActivatedRoute, private datePipe: DatePipe) { 
    _data.authToken$.subscribe(data => {
      this.token = data;
      if(this.token == null) {
        this._router.navigate(['/login']);
      } 
      else {
        this.fetchCustomer();
      }     
    });
            
  }

  ngOnInit(): void {
    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.credentialsForm = this.formBuilder.group({
      customer_id: ['', Validators.required],
      address: ['', Validators.required],
      mobile: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      date_added: [this.myDate, Validators.required],
      payment_method: ['', Validators.required],
      amount: ['', Validators.required],
      current_balance: ['0', Validators.required]
    });    
  }

  get f() { return this.credentialsForm.controls; }
  
  fetchCustomer() {
    this.submitted = true;
    
    this._data.fetchCustomerWithInvoice(this.token)
    .subscribe(  
        result => {
          this.submitted = false;
          // console.log(result); 
          if(result) {
            if('status' in result && result['status']){ 
              if(result['status'] == "success") {
                this.customers = result['result'];
                //console.log(this.customers)
                // this._data.showSuccess('success', result['msg']);
              }
              if(result['status'] == "fail") {
                this._data.showSuccess('warning', result['msg']);
              }
            }
          }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }

  onChange(deviceValue) {
    
    var _e = '';
    var _m = '';
    var _a = '';
    var c_invoiceNo = "";
    var _due = 0
    if(deviceValue) {
      var d = this.customers.find(x => x.id == deviceValue);
      if(d) {
        // console.log(deviceValue);
        _e = d['email']
        _m = d['mobile']
        _a = d['address']
        _due = d['current_balance']
        this.invoices = d['due_invoices']
        console.log(this.invoices )
      }
      this.credentialsForm.get('email').setValue(_e);
      this.credentialsForm.get('mobile').setValue(_m);
      this.credentialsForm.get('address').setValue(_a);
      this.credentialsForm.get('current_balance').setValue(_due);
    }    
    
  }

  onSubmit() {
    this.submitted = true;
    // console.log(this.credentialsForm.value);
    // return;
    // stop here if form is invalid
    if (this.credentialsForm.invalid) {
        return;
    }
    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.loginForm.value, null, 4));
    this._data.checkPayment(this.credentialsForm.value, this.token)
    .subscribe(  
        result => {
          console.log(result); 
          this.submitted = false;
          this.fetchCustomer();
          this.credentialsForm.reset()
          this.invoices = []
          if(result) {
            if('status' in result && result['status']){ 
              if(result['status'] == "success") {
                this._data.showSuccess('success', result['msg']);                                  
              }
              if(result['status'] == "fail") {
                this._data.showSuccess('warning', result['msg']);
              }
            }
          }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }

}
