
<div class="wrapper">
  <div class="container-fluid">

      <!-- Page-Title -->
      <div class="row">
          <div class="col-sm-12">
            <!-- <div class="btn-group pull-right m-t-20">
              <a class="btn btn-custom" [routerLink]="['/all-invoice']">All Invoices </a>              
            </div> -->
            <h4 class="page-title">Payment Reminder</h4>
          </div>
      </div>
      <!-- end page title end breadcrumb -->

      <div class="row">
        <div class="col-sm-3"></div>
        <div class="col-sm-6">
          <div class="card-box"> 
            <form [formGroup]="credentialsForm">
              <div class="row">
                
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label >Customer Name*</label>
                      <!-- <select class="form-control" (change)="onChange($event.target.value)" formControlName="customer_id" [ngClass]="{ 'is-invalid': submitted && f.customer_id.errors }" >
                        <option value="">Select</option>
                        <ng-container *ngFor="let item of customers; let i = index">
                          <option  value="{{item.id}}" *ngIf="item.balance_confirmed">{{item.customer_name}}</option>
                        </ng-container>                          
                      </select> -->
                      <input type="text" formControlName="c_name" (keyup)="nameSuggestion($event.target.value)" placeholder="Customer" class="form-control" >    
                        
                      <div class="search-results" *ngIf="showSuggestionDiv">
                        <ul>
                          <li *ngFor="let item of suggestionCustomers;"><a (click)="onChange(item.id)">{{item.id}}_{{item.customer_name}}_{{item.mobile}}</a></li>
                        </ul>
                      </div>
                      <div *ngIf="submitted && f.customer_id.errors" class="invalid-feedback">
                        <div *ngIf="f.customer_id.errors.required">Customer is required</div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label >Email*</label>
                      <input type="email" placeholder="Enter Email" class="form-control" formControlName="email" readonly="">
                    </div>
                    <div class="form-group">
                      <label >Mobile*</label>
                      <input type="number" placeholder="Enter Mobile" class="form-control" formControlName="mobile"readonly="">
                    </div>
                  </div><!-- end col -->
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label >Address*</label>
                      <textarea class="form-control" readonly="" style="min-height: 122px !important;" formControlName="address"></textarea>
                    </div>  
                    <div class="form-group">
                      <label >Current Balance*</label>
                      <input type="text" placeholder="Enter Current Balance" class="form-control" formControlName="current_balance" [ngClass]="{ 'is-invalid': submitted && f.current_balance.errors }" readonly="">
                      <div *ngIf="submitted && f.current_balance.errors" class="invalid-feedback">
                        <div *ngIf="f.current_balance.errors.required">current balance is required</div>
                      </div>
                    </div>                  
                  </div><!-- end col -->
                  
              </div><!-- end row -->     
                            
              <div class="row">
                <div class="col-xl-4">
                  <div class="form-group text-right m-b-0">
                    <button class="btn btn-primary waves-effect waves-light" (click)="onSubmit()">
                        Send
                    </button>
                    <!-- <button type="reset" class="btn btn-secondary waves-effect waves-light m-l-5" (click)="onReset()">
                        Cancel
                    </button> -->
                  </div>   
                </div>               
              </div><!-- end row -->
            </form>
          </div>
        </div><!-- end col -->
        <div class="col-sm-3"></div>
      </div>
      <!-- end row -->
  </div>
</div>