import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../api/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.css'],
  providers: [DatePipe]
})
export class EditCustomerComponent implements OnInit {

  token: any;
  credentialsForm: FormGroup;
  submitted = false;
  myDate: any;
  id: any;
  customer: any;

  constructor(private formBuilder: FormBuilder, private _data: DataService, private _router: Router, private _actrouter: ActivatedRoute, private datePipe: DatePipe) { 
    _data.authToken$.subscribe(data => {
      this.token = data;
      if(this.token == null) {
        this._router.navigate(['/login']);
      }      
    });

    this._actrouter.paramMap.subscribe(params => {
      this.id = params.get('id');
      // console.log(this.slug);
      if( this.id ) {                
        this.getCustomer(this.id);
      }
      else {
        this._router.navigate(['/all-customer']);
      }
    });
    
  }

  ngOnInit(): void {
    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.credentialsForm = this.formBuilder.group({
      customer_name: ['', Validators.required],
      address: [''],
      mobile: ['', Validators.required],
      date_added: [this.myDate, Validators.required],
      opening_balance: ['', Validators.required],
      field_normal: ['280'],
      field_normal_extra_charge: [''],
      field_normal_minimum_charge: ['70'],
      field_dc: ['425'],
      field_dc_extra_charge: [''],
      field_dc_minimum_charge: ['80'],
      field_wax: ['600'],
      field_wax_extra_charge: [''],
      field_wax_minimum_charge: ['100'],
      field_yellow: ['350'],
      field_yellow_extra_charge: [''],
      field_yellow_minimum_charge: ['70'],
      email: [''],
      last_invoice_date: ['']
    });
  }

  get f() { return this.credentialsForm.controls; }

  getCustomer(id) {
    this.submitted = true;
    this._data.fetchSingleCustomer(this.token, id)
    .subscribe(  
        result => {
          //console.log(result); 
          this.submitted = false;

          if(result) {
            if(0 in result) {              
              var data = result[0]
              this.customer = data
              this.credentialsForm.get('customer_name').setValue(data['customer_name']);
              this.credentialsForm.get('address').setValue(data['address']);
              this.credentialsForm.get('mobile').setValue(data['mobile']);
              this.credentialsForm.get('date_added').setValue(data['date_added']);
              this.credentialsForm.get('opening_balance').setValue(data['opening_balance']);
              this.credentialsForm.get('field_normal').setValue(data['field_normal'] > 0 ? data['field_normal'] : '');
              this.credentialsForm.get('field_normal_extra_charge').setValue(data['field_normal_extra_charge'] > 0 ? data['field_normal_extra_charge'] : '');
              this.credentialsForm.get('field_normal_minimum_charge').setValue(data['field_normal_minimum_charge'] > 0 ? data['field_normal_minimum_charge'] : '');
              this.credentialsForm.get('field_dc').setValue(data['field_dc'] > 0 ? data['field_dc'] : '');
              this.credentialsForm.get('field_dc_extra_charge').setValue(data['field_dc_extra_charge'] > 0 ? data['field_dc_extra_charge'] : '');
              this.credentialsForm.get('field_dc_minimum_charge').setValue(data['field_dc_minimum_charge'] > 0 ? data['field_dc_minimum_charge'] : '');
              this.credentialsForm.get('field_wax').setValue(data['field_wax'] > 0 ? data['field_wax'] : '');
              this.credentialsForm.get('field_wax_extra_charge').setValue(data['field_wax_extra_charge'] > 0 ? data['field_wax_extra_charge'] : '');
              this.credentialsForm.get('field_wax_minimum_charge').setValue(data['field_wax_minimum_charge'] > 0 ? data['field_wax_minimum_charge'] : '');
              this.credentialsForm.get('field_yellow').setValue(data['field_yellow'] > 0 ? data['field_yellow'] : '');
              this.credentialsForm.get('field_yellow_extra_charge').setValue(data['field_yellow_extra_charge'] > 0 ? data['field_yellow_extra_charge'] : '');
              this.credentialsForm.get('field_yellow_minimum_charge').setValue(data['field_yellow_minimum_charge'] > 0 ? data['field_yellow_minimum_charge'] : '');
              this.credentialsForm.get('email').setValue(data['email']);
              this.credentialsForm.get('last_invoice_date').setValue(data['last_invoice_date']);
            }
          }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }

  onSubmit() {
    this.submitted = true;
    // console.log(this.credentialsForm.value);
    // return;
    // stop here if form is invalid
    if (this.credentialsForm.invalid) {
        return;
    }
    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.loginForm.value, null, 4));
    this._data.createCustomer(this.credentialsForm.value, this.token, this.id)
    .subscribe(  
        result => {
          // console.log(result); 
          this.submitted = false;
          if(result) {
            if('status' in result && result['status']){ 
              if(result['status'] == "success") {
                this._data.showSuccess('success', result['msg']);
                                  
              }
              if(result['status'] == "fail") {
                this._data.showSuccess('warning', result['msg']);
              }
            }
          }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }

  onReset() {
    this.submitted = false;
    this.credentialsForm.reset();
    this.credentialsForm.get('field_normal_minimum_charge').setValue(70);
    this.credentialsForm.get('field_dc_minimum_charge').setValue(80);
    this.credentialsForm.get('field_wax_minimum_charge').setValue(100);
    this.credentialsForm.get('field_yellow_minimum_charge').setValue(70);
  }

}
