import { Component, OnInit } from '@angular/core';
import { DataService } from '../../api/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var $ : any;

@Component({
  selector: 'app-view-invoice',
  templateUrl: './view-invoice.component.html',
  styleUrls: ['./view-invoice.component.css'],
  providers: [DatePipe]
})
export class ViewInvoiceComponent implements OnInit {

  token: any;
  credentialsForm: FormGroup;
  submitted = false;
  myDate: any;
  id: any;
  customers = [];
  suggestionCustomers = [];
  printedCustromerName: string;
  buttonShow: boolean = true
  _reteEditVar:boolean = false;
  showSuggestionDiv: boolean = false;

  constructor(private formBuilder: FormBuilder, private _data: DataService, private _router: Router, private _actrouter: ActivatedRoute, private datePipe: DatePipe) { 
    _data.authToken$.subscribe(data => {
      this.token = data;
      if(this.token == null) {
        this._router.navigate(['/login']);
      }      
    });
    
    this._actrouter.paramMap.subscribe(params => {
      this.id = params.get('id');
      // console.log(this.slug);
      if( this.id ) {                
        this.getInvoice(this.id);
      }
      else {
        this._router.navigate(['/all-invoice']);
      } 
    });
    
  }

  ngOnInit(): void {
    // this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    var curYear = this.datePipe.transform(new Date(), 'yyyy');
    this.credentialsForm = this.formBuilder.group({
      c_name: [''],
      customer_id: [''],
      address: [''],
      mobile: [''],
      email: [''],
      date_added: [this.myDate],
      field_normal: [''],
      field_normal_minimum_charge: [''],
      field_normal_extra_charge: [''],
      field_dc: [''],
      field_dc_minimum_charge: [''],
      field_dc_extra_charge: [''],
      field_wax: [''],
      field_wax_minimum_charge: [''],
      field_wax_extra_charge: [''],
      field_yellow: [''],
      field_yellow_minimum_charge: [''],
      field_yellow_extra_charge: [''],
      field_normal_weight: [''],
      field_normal_amount: [''],
      field_dc_weight: [''],
      field_dc_amount: [''],
      field_wax_weight: [''],
      field_wax_amount: [''],
      field_yellow_weight: [''],
      field_yellow_amount: [''],
      NormalType: [''],
      DcType: [''],
      WaxType: [''],
      YellowType: [''],
      shipping_charge: [''],
      payable_amount: [''],
      payable_amount_without_discount: [''],
      discount: [''],
      c_invoiceNo: ['#'],
      current_balance: [''],
      invoiceNo:  ['AI-'+curYear],
      rateEdit: [0],
      balance_after_payment: [''],
      paid_amount: [''],
      payment_method: [''],
      gross_total: ['']
    });   
  }

  get f() { return this.credentialsForm.controls; }

  getInvoice(id) {
    this.submitted = true;
    this._data.fetchSingleInvoice(this.token, id)
    .subscribe(  
        result => {
          console.log(result); 
          this.submitted = false;

          if(result) {
              var data: any = result
              //console.log(data);
              // if(data['edit'] == 0) {
              //   this.buttonShow = false
              // }
              this.printedCustromerName = data.customer.customer_name;
              this.credentialsForm.get('customer_id').setValue(data['customer_id']);
              this.credentialsForm.get('c_name').setValue(data.customer.customer_name);
              this.credentialsForm.get('address').setValue(data.customer.address);
              this.credentialsForm.get('mobile').setValue(data.customer.mobile);
              this.credentialsForm.get('email').setValue(data.customer.email);
              this.credentialsForm.get('invoiceNo').setValue(data['invoice_number']);
              this.credentialsForm.get('c_invoiceNo').setValue(data['customer_invoice_number']);
              this.credentialsForm.get('field_normal').setValue(data['field_normal']);
              this.credentialsForm.get('field_normal_extra_charge').setValue(data['field_normal_extra_charge']);
              this.credentialsForm.get('field_normal_minimum_charge').setValue(data['field_normal_minimum_charge']);
              this.credentialsForm.get('field_dc').setValue(data['field_dc']);
              this.credentialsForm.get('field_dc_extra_charge').setValue(data['field_dc_extra_charge']);
              this.credentialsForm.get('field_dc_minimum_charge').setValue(data['field_dc_minimum_charge']);
              this.credentialsForm.get('field_wax').setValue(data['field_wax']);
              this.credentialsForm.get('field_wax_extra_charge').setValue(data['field_wax_extra_charge']);
              this.credentialsForm.get('field_wax_minimum_charge').setValue(data['field_wax_minimum_charge']);
              this.credentialsForm.get('field_yellow').setValue(data['field_yellow']);
              this.credentialsForm.get('field_yellow_extra_charge').setValue(data['field_yellow_extra_charge']);
              this.credentialsForm.get('field_yellow_minimum_charge').setValue(data['field_yellow_minimum_charge']);              
              this.credentialsForm.get('field_normal_weight').setValue(data['field_normal_weight']);
              this.credentialsForm.get('field_normal_amount').setValue(data['field_normal_amount']);
              this.credentialsForm.get('field_dc_weight').setValue(data['field_dc_weight']);
              this.credentialsForm.get('field_dc_amount').setValue(data['field_dc_amount']);
              this.credentialsForm.get('field_wax_weight').setValue(data['field_wax_weight']);
              this.credentialsForm.get('field_wax_amount').setValue(data['field_wax_amount']);
              this.credentialsForm.get('field_yellow_weight').setValue(data['field_yellow_weight']);
              this.credentialsForm.get('field_yellow_amount').setValue(data['field_yellow_amount']);

              this.credentialsForm.get('shipping_charge').setValue(data['shipping_charge']);
              this.credentialsForm.get('payable_amount').setValue(data['payable_amount']);
              this.credentialsForm.get('NormalType').setValue(data['NormalType']);
              this.credentialsForm.get('DcType').setValue(data['DcType']);
              this.credentialsForm.get('WaxType').setValue(data['WaxType']);
              this.credentialsForm.get('YellowType').setValue(data['YellowType']);
              this.credentialsForm.get('discount').setValue(data['discount']);
              this.credentialsForm.get('current_balance').setValue(data['current_balance']);
              this.credentialsForm.get('balance_after_payment').setValue(data['current_amount']);
              this.credentialsForm.get('payment_method').setValue(data['payment_method']);
              this.credentialsForm.get('date_added').setValue(data['date_added']);
              this.credentialsForm.get('paid_amount').setValue(data['paid_amount']);
              this.credentialsForm.get('gross_total').setValue(Math.round(data['gross_total']));

              var _pa = data['payable_amount'] > 0 ? data['payable_amount'] : 0;
              var _da = data['discount'] > 0 ? data['discount'] : 0;
              var payable_amount_without_discount =  _pa - _da;
              this.credentialsForm.get('payable_amount_without_discount').setValue(Math.round(payable_amount_without_discount));
              this.calculateTotal()
           }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }

  
  calculateTotal() {
    
    var grosstotal = 0;
    if(this.credentialsForm.value.field_normal_amount > 0) {
      grosstotal +=  (this.credentialsForm.value.field_normal_amount * 1)
    }
    if(this.credentialsForm.value.field_wax_amount > 0) {
      grosstotal +=  (this.credentialsForm.value.field_wax_amount * 1)
    }
    if(this.credentialsForm.value.field_dc_amount > 0) {
      grosstotal +=  (this.credentialsForm.value.field_dc_amount * 1)
    }
    if(this.credentialsForm.value.field_yellow_amount > 0) {
      grosstotal +=  (this.credentialsForm.value.field_yellow_amount * 1)
    }
    
    // var total = (this.credentialsForm.value.current_balance * 1) - grosstotal; 
    var total = grosstotal + (this.credentialsForm.value.current_balance * 1);   
        
    if(this.credentialsForm.value.shipping_charge > 0) {
      // total -= this.credentialsForm.value.shipping_charge * 1
      total += this.credentialsForm.value.shipping_charge * 1
    }
    this.credentialsForm.get('payable_amount_without_discount').setValue(Math.round(total));
    if(this.credentialsForm.value.discount > 0) {
      // total += (this.credentialsForm.value.discount * 1)
      total -= (this.credentialsForm.value.discount * 1)
    }
    // this.credentialsForm.get('payable_amount').setValue(-(total));
    this.credentialsForm.get('payable_amount').setValue(total);
    this.credentialsForm.get('gross_total').setValue(Math.round(grosstotal));

    var a = total
    if(this.credentialsForm.value.paid_amount > 0 || this.credentialsForm.value.paid_amount < 0 || this.credentialsForm.value.paid_amount == 0) {       
      // a = (total * 1) + (this.credentialsForm.value.paid_amount * 1); 
      a = (total * 1) - (this.credentialsForm.value.paid_amount * 1);      
    } 
    a = Math.round(a);   
    this.credentialsForm.get('balance_after_payment').setValue(a);
  }

  

}
