<div id="prnt_area" >
  <p style="text-align: center; ">
    <img src="assets/images/logo.jpg" alt="" width="140" height="110">
    <span style="font-size: 18px;">&nbsp;&nbsp;{{fetchedInvoice.customer_invoice_number}}  </span>
  </p>
</div>

<h4 style="font-size: 20px; text-align: center;">AL 3D PRINTER</h4>
<h5 style="font-size: 16px; text-align: center;">ZAVERI BAZAR, MUMBAI-400003</h5>
<h5 style="font-size: 16px; text-align: center;">MOB-7666551805/9819915775</h5>
<h5 style="font-size: 16px; text-align: center;">Email:cam/dc/wax@al3dprinter.com</h5>

<table style="border-top: 0.4px solid black;" width="100%" height="100%">
  <tbody>
    <tr>
      <td></td>
      <td style="text-align: left;">Challan No : {{fetchedInvoice.invoice_number}}</td>
      <td style="text-align: right;">Date : {{fetchedInvoice.date_added | date:'dd/MM/yyyy'}}</td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td style="border-top: 0.5px solid black; padding-top: 1px; padding-bottom: 1px;">
        {{printedCustromerName}} 
      </td>
      <td style="border-top: 0.5px solid black;">
      </td>
      <td></td>
    </tr>
  </tbody>
</table>

<table style="border-top: 0.2px solid black; border-bottom: 0.2px solid black;" width="100%">
  <tbody>
    <tr>  
      <td></td>
      <td></td>
      <td  width="33%">&nbsp;&nbsp;<b>Cam Type</b></td> 
      <td style="text-align: right;" width="33%">&nbsp;&nbsp;<b>Weight</b></td>
      <td style="text-align: right;" width="33%">&nbsp;&nbsp;<b>Amount</b></td>
      <td></td>
      <td></td>
    </tr>          
    <tr *ngIf="fetchedInvoice.field_normal_weight > 0">
      <td></td>
      <td></td>
      <td style="text-align: left">&nbsp;&nbsp;Normal</td>
      <td style="text-align: right;">&nbsp;&nbsp;{{fetchedInvoice.field_normal_weight}}</td>     
      <td style="text-align: right;">&nbsp;&nbsp;{{fetchedInvoice.field_normal_amount}}</td>  
      <td></td>  
      <td></td>
    </tr>
    <tr *ngIf="fetchedInvoice.field_dc_weight > 0">
      <td></td>
      <td></td>
      <td style="text-align: left">&nbsp;&nbsp;Dc</td>
      <td style="text-align: right;">&nbsp;&nbsp;{{fetchedInvoice.field_dc_weight}}</td>     
      <td style="text-align: right;">&nbsp;&nbsp;{{fetchedInvoice.field_dc_amount}}</td>  
      <td></td>  
      <td></td>
     </tr>
     <tr *ngIf="fetchedInvoice.field_wax_weight > 0">
      <td></td>
      <td></td>
      <td style="text-align: left">&nbsp;&nbsp;Wax</td>
      <td style="text-align: right;">&nbsp;&nbsp;{{fetchedInvoice.field_wax_weight}}</td>     
      <td style="text-align: right;">&nbsp;&nbsp;{{fetchedInvoice.field_wax_amount}}</td>  
      <td></td>  
      <td></td>
     </tr>
     <tr *ngIf="fetchedInvoice.field_yellow_weight > 0">
      <td></td>
      <td></td>
      <td style="text-align: left">&nbsp;&nbsp;Yellow</td>
      <td style="text-align: right;">&nbsp;&nbsp;{{fetchedInvoice.field_yellow_weight}}</td>     
      <td style="text-align: right;">&nbsp;&nbsp;{{fetchedInvoice.field_yellow_amount}}</td>  
      <td></td>  
      <td></td>
     </tr>
  </tbody>
</table>

<table width="100%">
  <tbody>
    <tr>
      <td></td>
      <td style="text-align: left;" width="65%">Total : </td>
      <td style="text-align: right;" width="25%" >&nbsp;&nbsp; <b>{{fetchedInvoice.gross_total}}</b></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td >Shipping : </td>
      <td style="text-align: right;">&nbsp;&nbsp; <b>{{fetchedInvoice.shipping_charge}}</b></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td >Old Due : </td>
      <td style="text-align: right;">&nbsp;&nbsp; {{fetchedInvoice.current_balance}}</td> 
      <td></td>
    </tr>
    <tr>  
      <td></td>
      <td >Gross Totel : </td>
      <td style="text-align: right;">&nbsp;&nbsp; <b>{{_grossTotal}}</b></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td >Discount : </td>
      <td style="text-align: right;">&nbsp;&nbsp; <b>{{fetchedInvoice.discount}}</b></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td >Payment : </td>
      <td style="text-align: right;">&nbsp;&nbsp; <b>{{fetchedInvoice.paid_amount}}</b></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td >Current Due : </td>
      <td style="text-align: right;">&nbsp;&nbsp; <b>{{lastDue}}</b></td>
      <td></td>
    </tr>
    <tr>
    </tr>
  </tbody>
</table>
<br><br>
<h5 style="font-size: 16px; text-align: right; margin-right: 30px;"><u>Authorised Signatory</u></h5>
<h5 style="font-size: 16px; text-align: center;">THANK YOU VISIT AGAIN</h5>