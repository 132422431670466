
<div class="wrapper">
    <div class="container-fluid">

        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12">
                <div class="btn-group pull-right m-t-20">
                    <a class="btn btn-custom" [routerLink]="['/create-invoice']">Add Invoices </a>              
                </div>
                <h4 class="page-title">All Invoices ({{visibleInvoices ? visibleInvoices.length : 0}} {{ total ? ' / '+total : null }})</h4>
            </div>
        </div>
        <!-- end page title end breadcrumb -->

        <div class="row">
            <div class="col-sm-12">
                <div class="card-box"> 
                    <form [formGroup]="credentialsForm"  *ngIf="!showSearch">
                        <div class="row">
                        
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label >Search *</label>

                                    <div class="input-group">
                                        <input type="text" placeholder="Invoice No/ customer name / customer mobile" class="form-control" formControlName="customer_name" [ngClass]="{ 'is-invalid': submitted && f.customer_name.errors }" (keyup)="checkCustomer()">
                                        <div class="input-group-append">
                                            <button class="btn btn-primary waves-effect waves-light" (click)="onSearch()">
                                                Submit
                                            </button>
                                            <button class="btn btn-warning waves-effect waves-light" (click)="onResetSearch()">
                                                Reset
                                            </button>
                                        </div>
                                        <div *ngIf="submitted && f.customer_name.errors" class="invalid-feedback">
                                            <div *ngIf="f.customer_name.errors.required">Customer Name is required</div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </form>
                    <form [formGroup]="credentialsForm"  *ngIf="showSearch">
                        <div class="row">
                        
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label >Search *</label>

                                    <div class="input-group">
                                        <input type="text" placeholder="Invoice No/ customer name / customer mobile" class="form-control" formControlName="customer_name" [ngClass]="{ 'is-invalid': submitted && f.customer_name.errors }" (keyup)="checkCustomer()">
                                        <div class="input-group-append">
                                            <button class="btn btn-primary waves-effect waves-light" (click)="onSearch()">
                                                Submit
                                            </button>
                                            <button class="btn btn-warning waves-effect waves-light" (click)="onResetSearch()">
                                                Reset
                                            </button>
                                        </div>
                                        <div *ngIf="submitted && f.customer_name.errors" class="invalid-feedback">
                                            <div *ngIf="f.customer_name.errors.required">Customer Name is required</div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="card-box">
                    <!-- <h4 class="m-t-0 header-title">Basic example</h4> -->
                    <table class="table">
                        <thead>
                        <tr>
                            <th>#
                                <a style="cursor: pointer;" (click)="idSort()">
                                    <span *ngIf="_id_sort_desc"><i class="ti-arrow-circle-down"></i></span>
                                    <span *ngIf="!_id_sort_desc"><i class="ti-arrow-circle-up"></i></span>
                                </a>
                            </th>
                            <th>Invoice No</th>
                            <th>Add/Edit By</th>
                            <th>Customer</th>
                            <!-- <th>Address</th> -->
                            <!-- <th>Payable Aount</th> -->
                            <th>Invoice Amount</th>
                            <th>Paid Payment</th>
                            <!-- <th>Current Due</th> -->
                            <th>Invoice Date</th>
                            <!-- <th>edit</th> -->
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of visibleInvoices; let i = index">
                                <th scope="row">#{{item.id}}</th>
                                <td>#{{item.invoice_number}}</td>
                                <td>
                                    <p [innerHTML]="item.activities | byuserfilter"></p>
                                </td>
                                
                                <td>
                                    {{item.customer.customer_name}}
                                    <br *ngIf="item.customer.email"><span *ngIf="item.customer.email"><i class="fa fa-envelope-open"></i> {{item.customer.email}}</span>
                                    <br><span><i class="fa fa-volume-control-phone"></i> {{item.customer.mobile}}</span>
                                </td>
                                <!-- <td>{{item.customer.address}}</td> -->
                                <!-- <td><i class="fa fa-rupee"></i> {{item.payable_amount}}</td> -->
                                <td><i class="fa fa-rupee"></i> {{item.gross_total}}</td>
                                <td><i class="fa fa-rupee"></i> {{item.paid_amount}}</td>
                                <!-- <td><i class="fa fa-rupee"></i> {{item.current_amount}}</td> -->
                                <td>{{item.created_at | date:'dd/MM/yyyy h:mm a'}}</td>
                                <!-- <td>
                                    <div class="badge badge-warning" *ngIf="item.edit" (click)="confirmB(item.id)">
                                        <i class="fa  fa-check"></i>
                                    </div>
                                    <div class="badge badge-primary" *ngIf="!item.edit">
                                        <i class="fa fa-times"></i>
                                    </div>
                                </td> -->
                                <td>
                                    <button type="button" class="btn btn-primary m-b-5 btn-xs" [routerLink]="['/view-invoice',item.id]"><i class="fa fa-eye"></i></button>
                                    <button type="button" class="btn btn-success m-b-5 btn-xs" [routerLink]="['/edit-invoice',item.id]"><i class="fa fa-pencil"></i></button>
                                    <!-- <a target="_blank[]" class="btn btn-danger m-b-5 btn-xs" [routerLink]="['/print-specific-invoice',item.id]"><i class="fa fa-print"></i></a> -->


                                    <!-- <a target="_blank[]" class="btn btn-danger m-b-5 btn-xs" href="https://al3dprinterbill.in/admin/api/pdf/{{item.id}}"  ><i class="fa fa-print"></i></a> -->


                                    <a target="_blank[]" class="btn btn-danger m-b-5 btn-xs" href="https://rr3dprinterbill.co.in/admin/index.php/api/pdf/{{item.id}}"  ><i class="fa fa-print"></i></a>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            <div class="col-md-12">
                <div class="card-box">

                    <div class="btn-group m-b-10 text-center">
                        <ng-container *ngFor="let link of links;">
                            <button type="button" [ngClass]="link['label'] == current_page ? 'btn btn-inverse waves-effect waves-light' : 'btn btn-secondary waves-effect'" *ngIf="link['url'] && link['label'] != 'pagination.next' && link['label'] != 'pagination.previous'" (click)="paginateInvoice(link['label'])">
                                {{ link['label'] }}
                            </button>
                        </ng-container>
                        
                    </div>
                    <br>
                    

                </div>
            </div>


        </div>
    </div>
</div>