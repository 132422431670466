import { Component, OnInit } from '@angular/core';
import { DataService } from '../../api/data.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-all-invoice',
  templateUrl: './all-invoice.component.html',
  styleUrls: ['./all-invoice.component.css']
})
export class AllInvoiceComponent implements OnInit {

  invoices: any
  visibleInvoices: any
  token: any;
  submitted = false;
  credentialsForm: FormGroup;
  _id_sort_desc: boolean = true
  showSearch: boolean = false;
  step = 1;
  links: any
  current_page = 1
  total: any

  constructor(private formBuilder: FormBuilder, private _data: DataService, private _router: Router) { 
    _data.authToken$.subscribe(data => {
      this.token = data;
      if(this.token == null) {
        this._router.navigate(['/login']);
      }      
    });
  }

  ngOnInit(): void {
    //alert(1)
    this.onSubmit();
    this.credentialsForm = this.formBuilder.group({
      customer_name: ['',  Validators.required]
    })
  }
  get f() { return this.credentialsForm.controls; }
  
  onSubmit() {
    //this.submitted = true;
    if(this.step == 2) {
      this._data.fetchInvoice(this.token)
      .subscribe(  
          result => {
            //this.submitted = false;
            console.log(result); 
            if(result) {
              if('status' in result && result['status']){ 
                if(result['status'] == "success") {
                  this.invoices = result['result'];
                  this.visibleInvoices = result['result'];
                  //console.log(this.invoices)
                  // this._data.showSuccess('success', result['msg']);
                  this.showSearch = true;
                }
                if(result['status'] == "fail") {
                  this._data.showSuccess('warning', result['msg']);
                }
              }
            }
            
          },
          error => {
            this.submitted = false;
            this._data.showSuccess('error', error);
          }
      );
    }
    else if(this.step == 1) {
      this._data.fetchLatestInvoice(this.token)
      .subscribe(  
          result => {
            //this.submitted = false;
            //alert(2)
            //console.log(result);  
            //console.log(result['result']['data']);
            
            if(result) {
              if('status' in result && result['status']){ 
                if(result['status'] == "success") {
                  this.invoices = result['result']['data'];
                  this.visibleInvoices = result['result']['data'];
                  this.links = result['result']['links'];
                  this.current_page = result['result']['current_page'];
                  this.total = result['result']['total'];
                  //console.log(this.invoices)
                  // this._data.showSuccess('success', result['msg']);
                  //this.step = 2;
                  //this.onSubmit();
                }
                if(result['status'] == "fail") {
                  this._data.showSuccess('warning', result['msg']);
                }
              }
            }
            
            
          },
          error => {
            this.submitted = false;
            this._data.showSuccess('error', error);
          }
      );
      }
      else {}
  }


  paginateInvoice(url) {
    //console.log(url)
    //return
    var type = this._id_sort_desc ? 'desc' : 'asc';
    var text = 'page='+url+ '&sort='+type;
    if(this.credentialsForm.value.customer_name) {
      
      text = text + '&customer_name='+this.credentialsForm.value.customer_name;
    }
    //alert(text)
    //return;

    this._data.fetchPaginateInvoice(this.token, text)
      .subscribe(  
          result => {
            //this.submitted = false;
            //alert(2)
            //console.log(result);  
            //console.log(result['result']['data']);
            
            if(result) {
              if('status' in result && result['status']){ 
                if(result['status'] == "success") {
                  this.invoices = result['result']['data'];
                  this.visibleInvoices = result['result']['data'];
                  this.links = result['result']['links'];
                  this.current_page = result['result']['current_page'];
                  this.total = result['result']['total'];
                  //console.log(this.invoices)
                  // this._data.showSuccess('success', result['msg']);
                  //this.step = 2;
                  //this.onSubmit();
                }
                if(result['status'] == "fail") {
                  this._data.showSuccess('warning', result['msg']);
                }
              }
            }
            
            
          },
          error => {
            this.submitted = false;
            this._data.showSuccess('error', error);
          }
      );

  }

  confirmB(id) {
    if(confirm("Are you sure, you have entered correct opening balance? \nIf yes click ok. \nAfter clicking ok button, you are not able to modify customer's opening balance")) {
      console.log(id)
      this.submitted = true;
        
      this._data.updateInvoice(this.token, id)
      .subscribe(  
          result => {
            this.submitted = false;
            // console.log(result); 
            if(result) {
              if('status' in result && result['status']){ 
                if(result['status'] == "success") {
                  var d = this.invoices.findIndex(x => x.id == id);
                  if(d > -1) {
                    this.invoices[d].edit = 0
                  }
                  this._data.showSuccess('success', result['msg']);
                }
                if(result['status'] == "fail") {
                  this._data.showSuccess('warning', result['msg']);
                }
              }
            }
            
          },
          error => {
            this.submitted = false;
            this._data.showSuccess('error', error);
          }
      );

    }
  }
  
  onSearch() {
    this.submitted = true;
    if(!this.credentialsForm.valid) {
      return;
    }
    this._data.searchInvoice(this.token, this.credentialsForm.value.customer_name)
    .subscribe(  
        result => {
          this.submitted = false;
          //console.log(result); 
          if(result) {
            if('status' in result && result['status']){ 
              if(result['status'] == "success") {
                this.invoices = result['result']['data'];
                this.visibleInvoices = result['result']['data'];
                this.links = result['result']['links'];
                this.current_page = result['result']['current_page'];
                this.total = result['result']['total'];
                //console.log(this.invoices)
                // this._data.showSuccess('success', result['msg']);
                //this.step = 2;
                //this.onSubmit();
              }
              if(result['status'] == "fail") {
                this._data.showSuccess('warning', result['msg']);
              }
            }
          }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }

  onResetSearch() {
    this.credentialsForm.get('customer_name').setValue('');
    this.step = 1;
    this.onSubmit()
  }

  checkCustomer() {
    var n = this.credentialsForm.value.customer_name;
    if(n && n.length > 1) {
      this.visibleInvoices = this.invoices.filter(function(itm){
        return itm.customer.customer_name.toLowerCase().indexOf(n.toLowerCase()) !== -1 || itm.invoice_number.toLowerCase().indexOf(n.toLowerCase()) !== -1 || itm.id == n || itm.customer.mobile.indexOf(n) !== -1
      });
    }
    else {
      this.visibleInvoices = this.invoices
    }
  }

  idSort() {
    if(this._id_sort_desc) {
      this._id_sort_desc = false;
      // return this.visibleInvoices.sort((a, b) => {
      //   return <any>a.id - <any>b.id;
      // });
            
    }
    else {
      this._id_sort_desc = true;
      // return this.visibleInvoices.sort((a, b) => {
      //   return <any>b.id - <any>a.id;
      // });  
           
    }
    var type = this._id_sort_desc ? 'desc' : 'asc';
    this._data.fetchSortInvoice(this.token, type)
    .subscribe(  
        result => {
          //this.submitted = false;
          //alert(2)
          //console.log(result);  
          //console.log(result['result']['data']);
          
          if(result) {
            if('status' in result && result['status']){ 
              if(result['status'] == "success") {
                this.invoices = result['result']['data'];
                this.visibleInvoices = result['result']['data'];
                this.links = result['result']['links'];
                this.current_page = result['result']['current_page'];
                this.total = result['result']['total'];
                //console.log(this.invoices)
                // this._data.showSuccess('success', result['msg']);
                //this.step = 2;
                //this.onSubmit();
              }
              if(result['status'] == "fail") {
                this._data.showSuccess('warning', result['msg']);
              }
            }
          }
          
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );


  }

}
