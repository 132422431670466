
<div class="wrapper">
    <div class="container-fluid">

        <!-- Page-Title -->
        <div class="row">
            <div class="col-sm-12">                
                <h4 class="page-title">Dashboard</h4>
            </div>
        </div>
        <!-- end page title end breadcrumb -->


        <div class="row" *ngIf="dashboardData && dashboardData.role_name == 'Superadmin'">

            <div class="col-xl-3 col-md-6">
                <div class="card-box">
                    <h4 class="header-title mt-0 m-b-30">Total Invoice</h4>

                    <div class="widget-box-2">
                        <div class="widget-detail-2">
                            <!-- <span class="badge badge-success badge-pill pull-left m-t-20">32% <i class="mdi mdi-trending-up"></i> </span> -->
                            <h2 class="mb-0"> {{dashboardData.invoiceCount}} </h2>
                            <!-- <p class="text-muted m-b-25">Revenue today</p> -->
                        </div>
                        <!-- <div class="progress progress-bar-success-alt progress-sm mb-0">
                            <div class="progress-bar progress-bar-success" role="progressbar"
                                    aria-valuenow="77" aria-valuemin="0" aria-valuemax="100"
                                    style="width: 77%;">
                                <span class="sr-only">77% Complete</span>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div><!-- end col -->
            <div class="col-xl-3 col-md-6">
                <div class="card-box">
                    <span class="badge badge-success badge-pill pull-left m-t-20">{{dashboardData.invoiceCountYesterDay}} </span>
                    <h4 class="header-title mt-0 m-b-30">Yesterday Invoice</h4>
                    <div class="widget-box-2">
                        <div class="widget-detail-2">
                            <h2 class="mb-0"> {{dashboardData.invoiceCountYesterTotal}} </h2>
                        </div>
                    </div>
                </div>
            </div><!-- end col -->
            <div class="col-xl-3 col-md-6">
                <div class="card-box">
                    <span class="badge badge-success badge-pill pull-left m-t-20">{{dashboardData.invoiceCountToday}} </span>
                    <h4 class="header-title mt-0 m-b-30">Today Invoice</h4>
                    <div class="widget-box-2">
                        <div class="widget-detail-2">
                            <h2 class="mb-0"> {{dashboardData.invoiceCountTodayTotal}} </h2>
                        </div>
                    </div>
                </div>
            </div><!-- end col -->
            <div class="col-xl-3 col-md-6">
                <div class="card-box">
                    <h4 class="header-title mt-0 m-b-30">Today NORMAL Cam (WEIGHT) </h4>
                    <div class="widget-box-2">
                        <div class="widget-detail-2">
                            <h2 class="mb-0"> {{dashboardData.normal_weight}} </h2>
                        </div>
                    </div>
                </div>
            </div><!-- end col -->
            <div class="col-xl-3 col-md-6">
                <div class="card-box">
                    <h4 class="header-title mt-0 m-b-30">Today DC Cam (WEIGHT)</h4>
                    <div class="widget-box-2">
                        <div class="widget-detail-2">
                            <h2 class="mb-0"> {{dashboardData.dc_weight}} </h2>
                        </div>
                    </div>
                </div>
            </div><!-- end col -->
            <div class="col-xl-3 col-md-6">
                <div class="card-box">
                    <h4 class="header-title mt-0 m-b-30">Today WAX Cam (WEIGHT)</h4>
                    <div class="widget-box-2">
                        <div class="widget-detail-2">
                            <h2 class="mb-0"> {{dashboardData.wax_weight}} </h2>
                        </div>
                    </div>
                </div>
            </div><!-- end col -->
            <div class="col-xl-3 col-md-6">
                <div class="card-box">
                    <h4 class="header-title mt-0 m-b-30">Today YELLOW Cam (WEIGHT)</h4>
                    <div class="widget-box-2">
                        <div class="widget-detail-2">
                            <h2 class="mb-0"> {{dashboardData.yellow_weight}} </h2>
                        </div>
                    </div>
                </div>
            </div><!-- end col -->
            <div class="col-xl-3 col-md-6">
                <div class="card-box">
                    <h4 class="header-title mt-0 m-b-30">Cash Payment</h4>
                    <div class="widget-box-2">
                        <div class="widget-detail-2">
                            <h2 class="mb-0"> {{dashboardData.cashPayment}} </h2>
                        </div>
                    </div>
                </div>
            </div><!-- end col -->
            <div class="col-xl-3 col-md-6">
                <div class="card-box">
                    <h4 class="header-title mt-0 m-b-30">Check Payment</h4>
                    <div class="widget-box-2">
                        <div class="widget-detail-2">
                            <h2 class="mb-0"> {{dashboardData.checkPayment}} </h2>
                        </div>
                    </div>
                </div>
            </div><!-- end col -->
            <div class="col-xl-3 col-md-6">
                <div class="card-box">
                    <h4 class="header-title mt-0 m-b-30">Online Payment</h4>
                    <div class="widget-box-2">
                        <div class="widget-detail-2">
                            <h2 class="mb-0"> {{dashboardData.onlinePayment}} </h2>
                        </div>
                    </div>
                </div>
            </div><!-- end col -->
            <div class="col-xl-3 col-md-6">
                <div class="card-box">
                    <span class="badge badge-success badge-pill pull-left m-t-20">{{dashboardData.clearpaymentCount}} </span>
                    <h4 class="header-title mt-0 m-b-30">Clear Payment (PERSON)</h4>
                    <div class="widget-box-2">
                        <div class="widget-detail-2">
                            <h2 class="mb-0"> {{dashboardData.clearpayment}} </h2>
                        </div>
                    </div>
                </div>
            </div><!-- end col -->
            <div class="col-xl-3 col-md-6">
                <div class="card-box">
                    <span class="badge badge-success badge-pill pull-left m-t-20">{{dashboardData.paypaymentCount}} </span>
                    <h4 class="header-title mt-0 m-b-30">Pay Payment (PERSON)</h4>
                    <div class="widget-box-2">
                        <div class="widget-detail-2">
                            <h2 class="mb-0"> {{dashboardData.paypayment}} </h2>
                        </div>
                    </div>
                </div>
            </div><!-- end col -->
            <div class="col-xl-3 col-md-6">
                <div class="card-box">
                    <span class="badge badge-success badge-pill pull-left m-t-20">{{dashboardData.pendingPaymentCount}} </span>
                    <h4 class="header-title mt-0 m-b-30">Pending Payment(PERSON)</h4>
                    <div class="widget-box-2">
                        <div class="widget-detail-2">
                            <h2 class="mb-0"> {{dashboardData.pendingPayment}} </h2>
                        </div>
                    </div>
                </div>
            </div><!-- end col -->

        </div>
        <!-- end row -->

        <div class="row" *ngIf="dashboardData && dashboardData.role_name != 'Superadmin'">

            <div class="col-xl-3 col-md-6">
                <div class="card-box">                    
                    <h4 class="header-title mt-0 m-b-30">Today Invoice Count</h4>
                    <div class="widget-box-2">
                        <div class="widget-detail-2">
                            <h2 class="mb-0"> {{dashboardData.invoiceCountToday}} </h2>
                        </div>
                    </div>
                </div>
            </div><!-- end col -->
            <div class="col-xl-3 col-md-6">
                <div class="card-box">
                    <h4 class="header-title mt-0 m-b-30">Today NORMAL Cam (WEIGHT) </h4>
                    <div class="widget-box-2">
                        <div class="widget-detail-2">
                            <h2 class="mb-0"> {{dashboardData.normal_weight}} </h2>
                        </div>
                    </div>
                </div>
            </div><!-- end col -->
            <div class="col-xl-3 col-md-6">
                <div class="card-box">
                    <h4 class="header-title mt-0 m-b-30">Today DC Cam (WEIGHT)</h4>
                    <div class="widget-box-2">
                        <div class="widget-detail-2">
                            <h2 class="mb-0"> {{dashboardData.dc_weight}} </h2>
                        </div>
                    </div>
                </div>
            </div><!-- end col -->
            <div class="col-xl-3 col-md-6">
                <div class="card-box">
                    <h4 class="header-title mt-0 m-b-30">Today WAX Cam (WEIGHT)</h4>
                    <div class="widget-box-2">
                        <div class="widget-detail-2">
                            <h2 class="mb-0"> {{dashboardData.wax_weight}} </h2>
                        </div>
                    </div>
                </div>
            </div><!-- end col -->
            <div class="col-xl-3 col-md-6">
                <div class="card-box">
                    <h4 class="header-title mt-0 m-b-30">Today YELLOW Cam (WEIGHT)</h4>
                    <div class="widget-box-2">
                        <div class="widget-detail-2">
                            <h2 class="mb-0"> {{dashboardData.yellow_weight}} </h2>
                        </div>
                    </div>
                </div>
            </div><!-- end col -->
            <div class="col-xl-3 col-md-6">
                <div class="card-box">
                    <span class="badge badge-success badge-pill pull-left m-t-20">{{dashboardData.clearpaymentCount}} </span>
                    <h4 class="header-title mt-0 m-b-30">Clear Payment (PERSON)</h4>
                    <div class="widget-box-2">
                        <div class="widget-detail-2">
                            <h2 class="mb-0"> {{dashboardData.clearpayment}} </h2>
                        </div>
                    </div>
                </div>
            </div><!-- end col -->
            <div class="col-xl-3 col-md-6">
                <div class="card-box">
                    <span class="badge badge-success badge-pill pull-left m-t-20">{{dashboardData.paypaymentCount}} </span>
                    <h4 class="header-title mt-0 m-b-30">Pay Payment (PERSON)</h4>
                    <div class="widget-box-2">
                        <div class="widget-detail-2">
                            <h2 class="mb-0"> {{dashboardData.paypayment}} </h2>
                        </div>
                    </div>
                </div>
            </div><!-- end col -->
            <div class="col-xl-3 col-md-6">
                <div class="card-box">
                    <span class="badge badge-success badge-pill pull-left m-t-20">{{dashboardData.pendingPaymentCount}} </span>
                    <h4 class="header-title mt-0 m-b-30">Pending Payment(PERSON)</h4>
                    <div class="widget-box-2">
                        <div class="widget-detail-2">
                            <h2 class="mb-0"> {{dashboardData.pendingPayment}} </h2>
                        </div>
                    </div>
                </div>
            </div><!-- end col -->
        </div>


    </div>
</div>