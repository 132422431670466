
<div class="wrapper">
  <div class="container-fluid">

      <!-- Page-Title -->
      <div class="row">
          <div class="col-sm-12">
            <div class="btn-group pull-right m-t-20">
              <a class="btn btn-custom" [routerLink]="['/user']">Add User </a>              
            </div>
            <h4 class="page-title">Users</h4>
          </div>
      </div>
      <!-- end page title end breadcrumb -->

      <div class="row">
        <div class="col-sm-6">
            <div class="card-box"> 
              <h4 class="m-t-0 header-title">{{title}}</h4>
              <form [formGroup]="credentialsForm">
                
                <div class="row">
                  
                  <div class="col-xl-12">
                    
                    <div class="form-group">
                      <label >Email*</label>
                      <input type="text" placeholder="Enter Email" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" >
                      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Email is required</div>
                        <div *ngIf="f.email.errors.email">Not a valid email</div>
                      </div>
                    </div> 
                    <div class="checkbox" *ngIf="id">
                      <input id="checkbox0" type="checkbox" (change)="editRate($event.target.checked);" value="1">
                      <label for="checkbox0">
                          Edit Password
                      </label>
                    </div>
                    <div class="form-group" *ngIf="editPassword">
                      <label >Password*</label>
                      <input type="password" placeholder="Enter Password" class="form-control" formControlName="password">                      
                    </div> 
                    <div class="form-group" >
                      <label >Active*</label>
                      <select formControlName="active" class="form-control">
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>                     
                    </div> 
                    <div class="form-group text-right m-b-0 m-t-30">
                      <button class="btn btn-primary waves-effect waves-light" (click)="saveUser()">
                          Submit
                      </button>
                    </div>
                  </div>
                </div>  
              </form>
            </div>
          </div><!-- end col -->
          <div class="col-sm-6">
            <div class="card-box">
              <h4 class="m-t-0 header-title">All Users</h4>
              <table class="table">
                  <thead>
                  <tr>
                      <th>#</th>
                      <th>Email</th>
                      <!-- <th>Check</th> -->
                      <th>Active</th>
                      <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let item of users; let i = index">
                          <!-- <th scope="row">#{{i+1}}</th> -->
                          <th scope="row">#{{item.id}}</th>
                          <td>{{item.email}}</td>
                          <!-- <td>
                            <a [routerLink]="['/edited-by',item.id]">Activities</a> <br>
                            <a [routerLink]="['/business-by',item.id]">Payment Received</a>
                          </td> -->
                          <td>
                            <span *ngIf="item.active == 1" style="color: green;">Yes</span>
                            <span *ngIf="item.active == 0" style="color: red;">No</span>
                          </td>
                          <td>
                              <!-- <button type="button" class="btn btn-primary m-b-5 btn-xs"><i class="fa fa-eye"></i></button> -->
                              <button type="button" class="btn btn-success m-b-5 btn-xs" [routerLink]="['/user',item.id]"><i class="fa fa-pencil"></i></button>
                              <!-- <button type="button" class="btn btn-danger m-b-5 btn-xs"><i class="fa fa-trash-o"></i></button> -->
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          </div>
      </div>
      <!-- end row -->
  </div>
</div>