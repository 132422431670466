import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'ai3d';
  currentUrl: any;
  showheader: boolean = true;

  constructor(private router: Router) { 
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
      )
      .subscribe(event => {
        this.currentUrl = event
        if(this.currentUrl.url.indexOf("print-specific-invoice") > -1) this.showheader = false
      });
  }

}
