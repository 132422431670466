import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, MultiDataSet } from 'ng2-charts';
import { DataService } from '../../api/data.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.css']
})
export class StatComponent implements OnInit {

  lineChartData: ChartDataSets[] = [
    { data: [85, 72, 78, 75, 77, 75], label: 'Sales' },
  ];

  lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June'];

  lineChartOptions = {
    responsive: true,
  };

  lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,255,0,0.28)',
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';

  // bar chart
  barChartOptions: ChartOptions = {
    responsive: true,
  };
  barChartLabels: Label[] = ['NORMAL', 'DC', 'WAX', 'YELLOW'];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];

  barChartData: ChartDataSets[] = [
    { data: [45, 37, 60, 70, 46, 33], label: 'Best Fruits' }
  ];

  // doughnut
  doughnutChartLabels: Label[] = ['Cash', 'Check', 'Online'];
  doughnutChartData: MultiDataSet = [
    [0, 0, 0]
  ];
  doughnutChartType: ChartType = 'doughnut';

  
  token: any;
  _authUser: any;
  submitted: boolean = false;
  dashboardData: any;

  _barChartData: any;
  _doughnutChartData: any;

  constructor(private _router:Router, private _data: DataService) { 
    _data.authToken$.subscribe(data => {
      this.token = data;
      if(this.token == null) {
        this._router.navigate(['/login']);
      }      
    });
  }

  ngOnInit(): void {
    this._data.fetchDashboard(this.token)
    .subscribe(  
        result => {
          this.submitted = false;
          //console.log(result); 
          if(result) {
            this.dashboardData = result

            
            var v_barChartData = [this.dashboardData.normal_weight, this.dashboardData.dc_weight, this.dashboardData.wax_weight, this.dashboardData.yellow_weight ];
            this._barChartData = [{ data: v_barChartData, label: 'Analytics' }];

            
            var v_doughnutChartData = [this.dashboardData.cashPayment, this.dashboardData.checkPayment, this.dashboardData.onlinePayment ];
            this._doughnutChartData = [ v_doughnutChartData];


          }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }

}
