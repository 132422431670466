import { Component, OnInit } from '@angular/core';
import { DataService } from '../../api/data.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import * as XLSX from 'xlsx'; 
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-all-customer',
  templateUrl: './all-customer.component.html',
  styleUrls: ['./all-customer.component.css'],
  providers: [DatePipe]
})
export class AllCustomerComponent implements OnInit {

  customers: any
  visibleCustomers: any
  token: any;
  submitted = false;
  credentialsForm: FormGroup;
  _id_sort_desc: boolean = true;
  _balance_sort_desc: boolean = true;
  _date_sort_desc: boolean = true;
  fileName= 'customer.xlsx';  
  dt: any
  file: File;
  arrayBuffer: any
  filelist: any = []
  msg: string
  showSearch: boolean = false;
  step = 1;

  importForm = new FormGroup({
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });

  constructor(private formBuilder: FormBuilder, private _data: DataService, private _router: Router, private datePipe: DatePipe) { 
    _data.authToken$.subscribe(data => {
      this.token = data;
      if(this.token == null) {
        this._router.navigate(['/login']);
      }      
    });
  }

  ngOnInit(): void {
    this.onSubmit();
    this.credentialsForm = this.formBuilder.group({
      customer_name: [''],
      due_amount: [''],
      advance_amount: [''],
      last_paid_date: [''],
      amount_due: ['']
    })
    this.importForm = this.formBuilder.group({
      file: new FormControl('', [Validators.required]),
      fileSource: new FormControl('', [Validators.required])
    })
  }
  get f() { return this.credentialsForm.controls; }
  
  onSubmit() {
    this.submitted = true;
    console.log(this.step)
    if(this.step == 2) {
      this._data.fetchCustomer(this.token)
      .subscribe(  
          result => {
            this.submitted = false;
            console.log(result); 
            if(result) {
              if('status' in result && result['status']){ 
                if(result['status'] == "success") {
                  this.customers = result['result'];
                  this.visibleCustomers = result['result'];
                  // console.log(this.customers)
                  // this._data.showSuccess('success', result['msg']);
                  this.showSearch = true;
                }
                if(result['status'] == "fail") {
                  this._data.showSuccess('warning', result['msg']);
                }
              }
            }
            
          },
          error => {
            this.submitted = false;
            this._data.showSuccess('error', error);
          }
      );
    }
    else if(this.step == 1) {
      this._data.fetchLatestCustomer(this.token)
      .subscribe(  
          result => {
            this.submitted = false;
            //console.log(result); 
            if(result) {
              if('status' in result && result['status']){ 
                if(result['status'] == "success") {
                  this.customers = result['result'];
                  this.visibleCustomers = result['result'];
                  // console.log(this.customers)
                  // this._data.showSuccess('success', result['msg']);
                  this.step = 2;
                  this.onSubmit();
                }
                if(result['status'] == "fail") {
                  this._data.showSuccess('warning', result['msg']);
                }
              }
            }
            
          },
          error => {
            this.submitted = false;
            this._data.showSuccess('error', error);
          }
      );
    }
    else {}
  }

  onSearch() {
    this.submitted = true;
    if(!this.credentialsForm.valid) {
      return;
    }
    this._data.searchCustomer(this.token, this.credentialsForm.value.customer_name)
    .subscribe(  
        result => {
          this.submitted = false;
          console.log(result); 
          if(result) {
            if('status' in result && result['status']){ 
              if(result['status'] == "success") {
                // this.customers = result['result'];
                this.visibleCustomers = result['result'];
                // console.log(this.customers)
                // this._data.showSuccess('success', result['msg']);
              }
              if(result['status'] == "fail") {
                this._data.showSuccess('warning', result['msg']);
              }
            }
          }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }

  onResetSearch() {
    this.credentialsForm.get('customer_name').setValue('');
    this.credentialsForm.get('due_amount').setValue('');
    this.credentialsForm.get('advance_amount').setValue('');
    this.credentialsForm.get('last_paid_date').setValue('');
    this.credentialsForm.get('amount_due').setValue('');
    this.visibleCustomers = this.customers
  }

  checkCustomer() {
    var n = this.credentialsForm.value.customer_name;
    if(n && n.length > 1) {
      this.visibleCustomers = this.customers.filter(function(itm){
        return itm.customer_name.toLowerCase().indexOf(n.toLowerCase()) !== -1 || itm.mobile.indexOf(n) !== -1 || itm.id == n
      });
    }
    else {
      this.visibleCustomers = this.customers
    }
  }

  checkCustomerDue() {
    var n = this.credentialsForm.value.due_amount;
    if(Number(n)) {
      console.log(n)
      this.visibleCustomers = this.customers.filter(function(itm){
        return parseFloat(itm.current_balance) > n;
      });
    }
    else {
      this.visibleCustomers = this.customers
    }
  }

  checkCustomerAdvance() {
    var n = this.credentialsForm.value.advance_amount;
    if(Number(n)) {
      if(n > 0) n = 0-n;
      this.visibleCustomers = this.customers.filter(function(itm){
        return parseFloat(itm.current_balance) < 1 && parseFloat(itm.current_balance) > n;
      });
    }
    else {
      this.visibleCustomers = this.customers
    }
  }

  checkCustomerLastPaidDate() {
    var n = this.credentialsForm.value.due_amount;
    if(n && Number(n) ) {
      console.log(n)
      // this.visibleCustomers = this.customers.filter(function(itm){
      //   return itm.customer_name.toLowerCase().indexOf(n.toLowerCase()) !== -1 || itm.mobile.indexOf(n) !== -1 || itm.id == n
      // });
    }
    else {
      this.visibleCustomers = this.customers
    }
  }

  checkCustomerDateDue() {
    var amt = this.credentialsForm.value.amount_due;
    var dt = this.credentialsForm.value.last_paid_date;
    if(dt &&  Number(amt)) {
      this.visibleCustomers = this.customers.filter(function(itm){
        return itm.last_invoice_date < dt && parseFloat(itm.current_balance) > 0 && parseFloat(itm.current_balance) > amt;
      });
    }
    else if(dt) {
      this.visibleCustomers = this.customers.filter(function(itm){
        return itm.last_invoice_date < dt;
      });
    }
    else if(Number(amt)) {
      this.visibleCustomers = this.customers.filter(function(itm){
        return parseFloat(itm.current_balance) > 0 && parseFloat(itm.current_balance) > amt;
      });
    }
    else {
      this.visibleCustomers = this.customers
    }
  }

  idSort() {
    if(this._id_sort_desc) {
      this._id_sort_desc = false;
      return this.visibleCustomers.sort((a, b) => {
        // return <any>new Date(b.CREATE_TS) - <any>new Date(a.CREATE_TS);
        return <any>a.id - <any>b.id;
      });      
    }
    else {
      this._id_sort_desc = true;
      return this.visibleCustomers.sort((a, b) => {
        // return <any>new Date(b.CREATE_TS) - <any>new Date(a.CREATE_TS);
        return <any>b.id - <any>a.id;
      });      
    }
  }

  balanceSort() {
    if(this._balance_sort_desc) {
      this._balance_sort_desc = false;
      return this.visibleCustomers.sort((a, b) => {
        // return <any>new Date(b.CREATE_TS) - <any>new Date(a.CREATE_TS);
        return <any>a.current_balance - <any>b.current_balance;
      });      
    }
    else {
      this._balance_sort_desc = true;
      return this.visibleCustomers.sort((a, b) => {
        // return <any>new Date(b.CREATE_TS) - <any>new Date(a.CREATE_TS);
        return <any>b.current_balance - <any>a.current_balance;
      });      
    }
  }
  
  dateSort() {
    if(this._date_sort_desc) {
      this._date_sort_desc = false;
      return this.visibleCustomers.sort((a, b) => {
        return <any>new Date(b.last_invoice_date) - <any>new Date(a.last_invoice_date);
      });      
    }
    else {
      this._date_sort_desc = true;
      return this.visibleCustomers.sort((a, b) => {
        return <any>new Date(a.last_invoice_date) - <any>new Date(b.last_invoice_date);
      });      
    }
  }

  exportexcel(): void 
  {
      /* table id is passed over here */   
      let element = document.getElementById('excel-table'); 
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */
      XLSX.writeFile(wb, this.fileName);
    
  }

  get g(){
    return this.importForm.controls;
  }     

  onFileChange(event) {
    this.msg = 'File Processing'
    this.file= event.target.files[0];     
    let fileReader = new FileReader();    
    fileReader.readAsArrayBuffer(this.file);     
    fileReader.onload = (e) => {    
      this.arrayBuffer = fileReader.result;    
      var data = new Uint8Array(this.arrayBuffer);    
      var arr = new Array();    
      for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);    
      var bstr = arr.join("");    
      var workbook = XLSX.read(bstr, {type:"binary"});    
      var first_sheet_name = workbook.SheetNames[0];    
      var worksheet = workbook.Sheets[first_sheet_name];    
      // console.log(XLSX.utils.sheet_to_json(worksheet,{raw:true}));    
      var d: any = XLSX.utils.sheet_to_json(worksheet,{raw:true});
     
      var pList = [];
      if(d.length) {
        for (let index = 0; index < d.length; index++) {
          
          if(d[index].mobile && d[index].name) {
            var dt = ''
            if(d[index].last_invoice_datesers){
              dt = d[index].last_invoice_date;
            }
            var op = ''
            if(d[index].opening_balance){
              op = d[index].opening_balance
            }
            var em = ''
            if(d[index].email){
              em = d[index].email
            }
            var add = ''
            if(d[index].address){
              add = d[index].address
            }            
            var normal = ''
            if(d[index].normal){
              normal = d[index].normal
            }
            var normal_ex = ''
            if(d[index].normal_extra){
              normal_ex = d[index].normal_extra
            }
            var normal_min = ''
            if(d[index].normal_minimum){
              normal_min = d[index].normal_minimum
            }
            var dc = ''
            if(d[index].dc){
              dc = d[index].dc
            }
            var dc_ex = ''
            if(d[index].dc_extra){
              dc_ex = d[index].dc_extra
            }
            var dc_min = ''
            if(d[index].dc_minimum){
              dc_min = d[index].dc_minimum
            }            
            var wax = ''
            if(d[index].wax){
              wax = d[index].wax
            }
            var wax_ex = ''
            if(d[index].wax_extra){
              wax_ex = d[index].wax_extra
            }
            var wax_min = ''
            if(d[index].wax_minimum){
              wax_min = d[index].wax_minimum
            }            
            var yellow = ''
            if(d[index].yellow){
              yellow = d[index].yellow
            }
            var yellow_ex = ''
            if(d[index].yellow_extra){
              yellow_ex = d[index].yellow_extra
            }
            var yellow_min = ''
            if(d[index].yellow_minimum){
              yellow_min = d[index].yellow_minimum
            }
            var ltDt: any = ''
            if(d[index].last_invoice_date){
              var chkDt = d[index].last_invoice_date
              // console.log(chkDt)
              // console.log(typeof(chkDt))
              if(Number(chkDt)) {
                // console.log(this.ExcelDateToJSDate(chkDt))
                this.dt = this.ExcelDateToJSDate(chkDt)
                chkDt =this.datePipe.transform(this.dt, 'yyyy-MM-dd');
                // console.log(chkDt)
              }
              // else {
              //   ltDt = chkDt
              //   // chkDt =this.datePipe. transform(chkDt, 'yyyy-MM-dd');
              // }
              // if(this.isValidDate(chkDt)) {
              //   console.log(chkDt)
              //   ltDt = chkDt
              // }
              ltDt = chkDt
            }

            var a = {
              customer_name: d[index].name,
              address: add,
              mobile: d[index].mobile,
              email: em,
              opening_balance: op,
              current_balance: op,
              field_normal: normal,
              field_normal_extra_charge: normal_ex,
              field_normal_minimum_charge: normal_min,
              field_dc: dc,
              field_dc_extra_charge: dc_ex,
              field_dc_minimum_charge: dc_min,
              field_wax: wax,
              field_wax_extra_charge: wax_ex,
              field_wax_minimum_charge: wax_min,
              field_yellow: yellow,
              field_yellow_extra_charge: yellow_ex,
              field_yellow_minimum_charge: yellow_min,
              last_invoice_date: ltDt
            }

            let exsist = pList.indexOf(d[index].mobile)
            if(exsist > -1) {
              this.msg = 'Phone '+d[index].mobile+' already exsist.'
              this._data.showSuccess('warning', 'Phone '+d[index].mobile+' already exsist.');
              return;
            }
            else {
              pList.push(d[index].mobile);
            }
            
            this.filelist.push(a);
          }
          else {
            this.msg = 'Error! Phone or Customer Name blank.'
            this._data.showSuccess('warning', 'Error! Phone or Customer Name blank.');
            return;
          }
        }

      }    
      else {
        this.msg = 'Excel invalid'
      }
      this.submit( this.filelist)   
    } 
  }

  isValidDate(d) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d.getTime())) {  // d.valueOf() could also work
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  ExcelDateToJSDate(serial) {
    var utc_days  = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;                                        
    var date_info = new Date(utc_value * 1000);
 
    var fractional_day = serial - Math.floor(serial) + 0.0000001;
 
    var total_seconds = Math.floor(86400 * fractional_day);
 
    var seconds = total_seconds % 60;
 
    total_seconds -= seconds;
 
    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;
 
    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
 }

  submit(filelist){
    // console.log(filelist)
    // return;
    this.msg = 'Uploading to server';
    this._data.importCustomer(filelist, this.token)
    .subscribe(  
        result => {
          // console.log(result); 
          if(result) {
            if('status' in result && result['status']){ 
              if(result['status'] == "success") {
                window.location.reload();
                this._data.showSuccess('success', result['msg']);
              }
              if(result['status'] == "fail") {
                this._data.showSuccess('warning', result['msg']);
              }
            }
          }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Pipe({
  name: 'byuserfilter'
})
export class ByUserFilter implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) { }
  transform(value: any): SafeHtml {
    var design = ''; 
    if(value.length) {
      design += 'Created by: '+value[0].user.email
      if(value.length > 1) { 
        design += '<br> Last modified by: '+value[value.length - 1].user.email
      }
    }
    return this._sanitizer.bypassSecurityTrustHtml(design);
  }
}
