import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from '../../api/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as XLSX from 'xlsx'; 

import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

@Component({
  selector: 'app-print-invoice',
  templateUrl: './print-invoice.component.html',
  styleUrls: ['./print-invoice.component.css'],
  providers: [DatePipe]
})
export class PrintInvoiceComponent implements OnInit {

  token: any;
  _printableData: any
  _customer: any;
  _invoices: any;
  totalPaid: any = 0;
  totalN: any = 0;
  totalD: any = 0;
  totalW: any = 0;
  totalY: any = 0;
  totalAmount: any = 0;
  totalShipping: any = 0;
  totalDiscount: any = 0;
  totalPayment: any = 0;
  heading: string;
  _currentBalance: any
  myDate: any;
  fileName= 'invoice.xlsx'; 

  @ViewChild('pdfTable') pdfTable: ElementRef;

  constructor(private _data: DataService, private _router: Router, private _actrouter: ActivatedRoute, private datePipe: DatePipe) { 
    _data.authToken$.subscribe(data => {
      this.token = data;
      if(this.token == null) {
        this._router.navigate(['/login']);
      }   
      else {

        this.checkInvoice()
          
      }    
    });
  }

  ngOnInit(): void {
    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd h:m:s');
  }

  checkInvoice() {
    this._data.invoiceDataHeading$.subscribe(data => {
      console.log(data)
      if(data) {
        this.heading = data
      }
    });
    this._data.invoiceCustomer$.subscribe(data => {
      // console.log(data)
      if(data) {
        this._customer = data
        this._currentBalance = Math.round(this._customer ? this._customer.current_balance : 0)
      }
    });
    this._data.invoiceData$.subscribe(data => {
      // console.log(data)
      if(data && data.length) {
        this._invoices = data
        for (let index = 0; index < this._invoices.length; index++) {
          this.totalN += (this._invoices[index].field_normal_weight * 1)
          this.totalD += (this._invoices[index].field_dc_weight * 1)
          this.totalW += (this._invoices[index].field_wax_weight * 1)
          this.totalY += (this._invoices[index].field_yellow_weight * 1)
          this.totalPaid += (this._invoices[index].paid_amount * 1)           
          this.totalAmount += (this._invoices[index].gross_total * 1) 
          this.totalShipping += (this._invoices[index].shipping_charge * 1) 
          this.totalDiscount += (this._invoices[index].discount * 1) 
          this.totalPayment += (this._invoices[index].paid_amount * 1) 
        }
        
        this.totalN = parseFloat(this.totalN).toFixed(2);
        this.totalD = parseFloat(this.totalD).toFixed(2);
        this.totalW = parseFloat(this.totalW).toFixed(2);
        this.totalY = parseFloat(this.totalY).toFixed(2);
        this.totalAmount = parseFloat(this.totalAmount).toFixed(2); 
        this.totalShipping = parseFloat(this.totalShipping).toFixed(2); 
        this.totalDiscount = parseFloat(this.totalDiscount).toFixed(2); 
        this.totalPayment = parseFloat(this.totalPayment).toFixed(2); 

        this.totalPaid = (this.totalAmount * 1)+ (this.totalShipping *1) - (this.totalDiscount * 1) - (this.totalPayment * 1);
        this.totalPaid = parseFloat(this.totalPaid).toFixed(2);  
      }
    });
  }

  public downloadAsPDF() {
    const doc = new jsPDF("l", "mm", "a4");
    
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).open(); 
    
  }

  onPrint(){
    window.print();
  }

  
  exportexcel(): void 
  {
      /* table id is passed over here */   
      let element = document.getElementById('excel-table'); 
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */
      XLSX.writeFile(wb, this.fileName);
    
  }

}
