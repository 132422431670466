import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../api/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.css'],
  providers: [DatePipe]
})
export class CreateCustomerComponent implements OnInit {

  token: any;
  credentialsForm: FormGroup;
  submitted = false;
  myDate: any;
  id: any;
  constructor(private formBuilder: FormBuilder, private _data: DataService, private _router: Router, private _actrouter: ActivatedRoute, private datePipe: DatePipe) { 
    _data.authToken$.subscribe(data => {
      this.token = data;
      if(this.token == null) {
        this._router.navigate(['/login']);
      }      
    });
    
  }

  ngOnInit(): void {
    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.credentialsForm = this.formBuilder.group({
      customer_name: ['', Validators.required],
      address: [''],
      mobile: ['', Validators.required],
      date_added: [this.myDate, Validators.required],
      opening_balance: [''],
      field_normal: ['280'],
      field_normal_extra_charge: [''],
      field_normal_minimum_charge: ['70'],
      field_dc: ['425'],
      field_dc_extra_charge: [''],
      field_dc_minimum_charge: ['80'],
      field_wax: ['600'],
      field_wax_extra_charge: [''],
      field_wax_minimum_charge: ['100'],
      field_yellow: ['350'],
      field_yellow_extra_charge: [''],
      field_yellow_minimum_charge: ['70'],
      email: ['', Validators.email],
      last_invoice_date: ['']
    });
  }

  get f() { return this.credentialsForm.controls; }

  onSubmit() {
    this.submitted = true;
    // console.log(this.credentialsForm.value);
    // return;
    // stop here if form is invalid
    if (this.credentialsForm.invalid) {
        return;
    }
    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.loginForm.value, null, 4));
    this._data.createCustomer(this.credentialsForm.value, this.token, this.id)
    .subscribe(  
        result => {
          // console.log(result); 
          this.submitted = false;
          if(result) {
            if('status' in result && result['status']){ 
              if(result['status'] == "success") {
                this._data.showSuccess('success', result['msg']);
                if(this.id) {}
                else {
                  this._router.navigate(['/all-customer']);
                }
                  
              }
              if(result['status'] == "fail") {
                this._data.showSuccess('warning', result['msg']);
              }
            }
          }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }


}
