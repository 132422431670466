import { Component, OnInit } from '@angular/core';
import { DataService } from '../../api/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-business-by',
  templateUrl: './business-by.component.html',
  styleUrls: ['./business-by.component.css']
})
export class BusinessByComponent implements OnInit {

  token: any;
  submitted = false;
  id: any;
  dataArray: any;
  users: any
  credentialsForm: FormGroup;
  totalAmount: any;

  constructor(private formBuilder: FormBuilder, private _data: DataService, private _router: Router, private _actrouter: ActivatedRoute) { 
    _data.authToken$.subscribe(data => {
      this.token = data;
      if(this.token == null) {
        this._router.navigate(['/login']);
      }      
    });
    
  }

  ngOnInit(): void {
    this.onSubmit()
    this.credentialsForm = this.formBuilder.group({
      user_id: [''],
      date_from: [''],
      date_to: [''],
      payment_method: ['']
    })
    this.getData()
  }
  get f() { return this.credentialsForm.controls; }
  
  getData() {
    this.submitted = true;
    var param = '?user_id='+this.credentialsForm.value.user_id+'&payment_method='+this.credentialsForm.value.payment_method+'&date_from='+this.credentialsForm.value.date_from+'&date_to='+this.credentialsForm.value.date_to;
    this._data.fetchPaymentReceivedActivityLog(this.token, this.credentialsForm.value)
    .subscribe(  
        result => {

          console.log(result); 
          this.submitted = false;
          
          if(result) {
            if('status' in result && result['status']){ 
              if(result['status'] == "success") {
                if('invoices' in result) {
                  this.dataArray = result['invoices'];
                }
                if('totalAmount' in result) {
                  this.totalAmount = result['totalAmount'];
                }
              }
              if(result['status'] == "fail") {
                this._data.showSuccess('warning', result['msg']);
              }
            }
          }

          // if(result) {
          //   if('invoices' in result) {
          //     this.dataArray = result['invoices'];
          //   }
          //   if('totalAmount' in result) {
          //     this.totalAmount = result['totalAmount'];
          //   }  
          // }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }

  onSubmit() {
    this.submitted = true;
    
    this._data.fetchUsers(this.token)
    .subscribe(  
        result => {
          this.submitted = false;
          if(result) {
            if('status' in result && result['status']){ 
              if(result['status'] == "success") {
                this.users = result['result'];
                //console.log(this.users)
              }
              if(result['status'] == "fail") {
                this._data.showSuccess('warning', result['msg']);
              }
            }
          }
          
        },
        error => {
          this.submitted = false;
          this._data.showSuccess('error', error);
        }
    );
  }

  onResetSearch() {
    this.credentialsForm.get('user_id').setValue('');
    this.credentialsForm.get('date_from').setValue('');
    this.credentialsForm.get('date_to').setValue('');
    this.credentialsForm.get('payment_method').setValue('');
    this.getData()
  }

}
