import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CreateInvoiceComponent } from './components/create-invoice/create-invoice.component';
import { AllInvoiceComponent } from './components/all-invoice/all-invoice.component';
import { AllCustomerComponent } from './components/all-customer/all-customer.component';
import { CreateCustomerComponent } from './components/create-customer/create-customer.component';
import { ReportComponent } from './components/report/report.component';
import { ReminderComponent } from './components/reminder/reminder.component';
import { LoginComponent } from './components/login/login.component';
import { ChartsModule } from 'ng2-charts';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MakePaymentComponent } from './components/make-payment/make-payment.component';
import { UserComponent } from './components/user/user.component';
import { StatComponent } from './components/stat/stat.component';
import { EditedByComponent } from './components/edited-by/edited-by.component';
import { BusinessByComponent } from './components/business-by/business-by.component';
import { ByUserFilter } from './components/all-customer/all-customer.component';
import { ViewCustomerComponent } from './components/view-customer/view-customer.component';
import { EditCustomerComponent } from './components/edit-customer/edit-customer.component';
import { EditInvoiceComponent } from './components/edit-invoice/edit-invoice.component';
import { ViewInvoiceComponent } from './components/view-invoice/view-invoice.component';
import { PrintInvoiceComponent } from './components/print-invoice/print-invoice.component';
import { BusinessStatementComponent } from './components/business-statement/business-statement.component';
import { EditedInvoiceComponent } from './components/edited-invoice/edited-invoice.component';
import { PerticularInvoicePrintComponent } from './components/perticular-invoice-print/perticular-invoice-print.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    CreateInvoiceComponent,
    AllInvoiceComponent,
    AllCustomerComponent,
    CreateCustomerComponent,
    ReportComponent,
    ReminderComponent,
    LoginComponent,
    MakePaymentComponent,
    UserComponent,
    StatComponent,
    EditedByComponent,
    BusinessByComponent,
    ByUserFilter,
    ViewCustomerComponent,
    EditCustomerComponent,
    EditInvoiceComponent,
    ViewInvoiceComponent,
    PrintInvoiceComponent,
    BusinessStatementComponent,
    EditedInvoiceComponent,
    PerticularInvoicePrintComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ChartsModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [ByUserFilter],
  bootstrap: [AppComponent]
})
export class AppModule { }
