
<div class="wrapper">
  <div class="container-fluid">

      <!-- Page-Title -->
      <div class="row">
          <div class="col-sm-12">
              
              <h4 class="page-title">Business Statement</h4>
          </div>
      </div>
      <!-- end page title end breadcrumb -->

      <div class="row">
        <div class="col-sm-12">
            <div class="card-box"> 
                <form [formGroup]="credentialsForm">
                    <div class="row">
                        <div class="col-xl-3">
                            <div class="form-group">
                                <label> From Date</label>
                                <div class="input-group">
                                    <input type="date" formControlName="date_from" class="form-control">
                                </div>
                            </div>                            
                        </div>
                        <div class="col-xl-3">
                            <div class="form-group">
                                <label >To Date</label>
                                <div class="input-group">
                                    <input type="date" formControlName="date_to" class="form-control">
                                </div>
                            </div>                            
                        </div>
                        <div class="col-xl-3">
                            <div class="form-group">
                                <button class="btn btn-primary waves-effect waves-light" (click)="getData()" style="margin-top: 30px;">
                                    Submit
                                </button>
                                <button class="btn btn-warning waves-effect waves-light" (click)="onResetSearch()" style="margin-top: 30px;">
                                    Reset
                                </button>
                            </div>                            
                        </div>
                        <div class="col-xl-3">
                            <div class="form-group">
                                <button class="btn btn-success waves-effect waves-light pull-right"  style="margin-top: 30px;" (click)="exportexcel()">
                                    Export to excel
                                </button>
                            </div>
                            
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

      <div class="row" *ngIf="dataArray">
          <div class="col-lg-12">
              <div class="card-box">
                  <!-- <h4 class="m-t-0 header-title">Basic example</h4> -->
                  <table class="table" id="excel-table">
                      <thead>
                        <tr>
                            <th>Total ({{dataArray.invoices.length}})</th>
                            <th>{{dataArray.grand_total_invoice_count}}</th>
                            <th>{{dataArray.grand_total_normal_weight}}</th>
                            <th>{{dataArray.grand_total_dc_weight}}</th>
                            <th>{{dataArray.grand_total_wax_weight}}</th>
                            <th>{{dataArray.grand_total_yellow_weight}}</th>
                            <th>{{dataArray.grand_total_normal_amount}}</th>
                            <th>{{dataArray.grand_total_dc_amount}}</th>
                            <th>{{dataArray.grand_total_wax_amount}}</th>
                            <th>{{dataArray.grand_total_yellow_amount}}</th>
                            <th>{{dataArray.grand_total_invoice_amount}}</th>
                            <!-- <th>{{dataArray.grand_total_bill_amount}}</th> -->
                            <th>{{dataArray.grand_total_shipping}}</th>
                            <th> {{dataArray.grand_total_dicount}}</th>
                            <th>{{dataArray.grand_total_paid}}</th>
                        </tr>
                      </thead>
                      <thead>
                      <tr>
                          <th>Date</th>
                          <th>Invoice Quantity</th>
                          <th>Normal Weight</th>
                          <th>Dc Weight</th>
                          <th>Wax Weight</th>
                          <th>Yellow Weight</th>
                          <th>Normal Amount</th>
                          <th>Dc Amount</th>
                          <th>Wax Amount</th>
                          <th>Yellow Amount</th>
                          <th>Invoice Amount</th>
                          <!-- <th>Bill Amount</th> -->
                          <th>Shipping</th>
                          <th> Discount</th>
                          <th>Payment</th>
                      </tr>
                      </thead>
                      <tbody>
                            <tr *ngFor="let item of dataArray.invoices; let i = index">
                                <td scope="row">{{item.date_added | date:'dd/MM/yyyy'}}</td>
                                <td scope="row">{{item.invoiceCount}}</td>
                                <td scope="row">{{item.total_normal_weight}}</td>
                                <td scope="row">{{item.total_dc_weight}}</td>
                                <td scope="row">{{item.total_wax_weight}}</td>
                                <td scope="row">{{item.total_yellow_weight}}</td>
                                <td scope="row">{{item.total_normal_amount}}</td>
                                <td scope="row">{{item.total_dc_amount}}</td>
                                <td scope="row">{{item.total_wax_amount}}</td>
                                <td scope="row">{{item.total_yellow_amount}}</td>
                                <td scope="row">{{item.total_invoice_amount}}</td>
                                <!-- <td scope="row">{{item.total_bill_amount}}</td> -->
                                <td scope="row">{{item.total_shipping}}</td>
                                <td scope="row">{{item.total_dicount}}</td>
                                <td scope="row">{{item.total_paid}}</td>
                          </tr>
                      </tbody>
                  </table>
              </div>

          </div>
      </div>
  </div>
</div>